import { useMemo } from "react";
import { Account } from "../../api/interfaces/Account";
import AllServices from "./AllServices";
import { useGetPlans } from "../../hooks/useGetPlans";
import { Plan } from "../../api/interfaces/Plan";
import { Backdrop, CircularProgress } from "@mui/material";

const processData = (plansData: any) => {
  const serviceTypes = plansData?.data?.servicetype || [];
  const newArray = serviceTypes?.reduce(
    (result: any, { name: typeName, fields_template_json, Services }: any) => {
      const plans = Services.flatMap((service: any) =>
        service.Plans.map(({ name: planName, ...planInfo }: Partial<Plan>) => ({
          name: typeName,
          planName,
          ...planInfo,
          Contacts: service?.Provider?.Contacts,
          providerId: service?.Provider?.id,
          providerName: service?.Provider?.name,
          support_url: service?.support_url,
          subscription_redirect_url: service?.subscription_redirect_url,
          fields_icons: JSON.parse(fields_template_json),
        }))
      );
      result.push(...plans);
      return result;
    },
    []
  );
  return newArray;
};

interface AllServicesIndexProps {
  currentAccount: Account;
}

function randomize(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const AllServicesIndex = ({ currentAccount }: AllServicesIndexProps) => {
  const { data, isLoading } = useGetPlans(currentAccount.type, {
    refetchOnWindowFocus: false,
  });

  const plansList = useMemo(() => randomize(processData(data)), [data]);

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    );
  }
  return <AllServices plansList={plansList} />;
};

export default AllServicesIndex;
