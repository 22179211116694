import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import SubHeader from "../../components/SubHeader";
import Box from "../../components/Box";
import Warning from "../../components/Warning";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useLocation } from "react-router-dom";
import { useGetServicesWithPlans } from "../../hooks/useGetServicesWithPlans";
import sendErrorToast from "../../utils/sendErrorToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { Plan } from "../../api/interfaces/Plan";
import PlansTable from "./components/PlansTable";
import SelectContainer from "./components/SelectContainer";
import { ServiceInfo } from "../../api/interfaces/ServiceInfo";
import average from "../../utils/getAverageArrayNumbers";
import ProviderBox from "../../components/ProviderBox";
import CompareScreen from "./components/CompareScreen";
import PlansBreadCrumbs from "./components/PlansBreadCrumbs";
import getProperLabelValue from "../../utils/getProperLabelValue";
import useGetThemePath from "../../hooks/useGetThemePath";
import NavContainer from "../../components/NavContainer/NavContainer";

export const searchIconStyles = {
  marginRight: getREMFromPX(theme.spacing * 2),
};

export type MappedPlan = {
  plan: Plan;
  providerName: string;
  planName: string;
  providerInfo: string;
  serviceFields?: string;
  id: string;
  providerId: string;
  marketingUrl: string;
  email: string;
  phone: string;
} & { [key: string]: any };

export type Filter = {
  value: string;
  name: string;
};

export type FilterValueProperty = {
  name: string;
  type: string;
  default?: string;
  not_set?: string;
  filter: string;
  icon: string;
  placeholder: string;
};

export type Inputs = {
  [key: string]: Filter;
};

type MappedAverageRatingProvider = ServiceInfo & {
  averageRating: number;
};

const Plans = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const { id } = useParams();
  const { state: givenService } = useLocation();

  const service: any = givenService;

  const [inputs, setInputs] = useState<Inputs>({});
  const [selectedRows, setSelectedRows] = useState<MappedPlan[]>([]);
  const [isComparing, setIsComparing] = useState(false);
  const [results, setResults] = useState<MappedPlan[] | null>(null);

  const {
    selectedDeviceId,
    selectedAccount,
    isLoading: isContextLoading,
  } = useContext(AddressAndAccountContext) as AddressAndAccountContextType;

  const { isLoading: areServicesLoading, data } = useGetServicesWithPlans(
    id as string,
    selectedAccount,
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedAccount,
      onError: () =>
        sendErrorToast(
          "There was an error getting the Internet Services, please try again"
        ),
    }
  );

  const validServices = useMemo(() => {
    const filtered = data?.data.service?.filter(
      (service) => !!service.Provider
    );
    return filtered?.length ? filtered : undefined;
  }, [data]);

  const serviceType =
    validServices?.map((vs) => vs.Servicetype.name)[0] ?? "Service";

  const mappedServices = useMemo(
    () =>
      validServices
        ?.map((service) =>
          service.Plans.map((plan) => ({
            plan,
            id: plan.id,
            serviceFields: service.Servicetype.fields_template_json,
            rating: Number(plan.ratings_sum),
            providerName: service.Provider?.name as string,
            planName: plan.name,
            price: Number(plan.fields.Price),
            providerId: service.Provider?.id as string,
            providerInfo: service.Provider?.description as string,
            marketingUrl: service.marketing_url ?? ("-" as string),
            email: service.Provider?.Contacts?.[0]?.email ?? ("-" as string),
            phone: service.Provider?.Contacts?.[0]?.phone ?? ("-" as string),
          }))
        )
        .flat(),
    [validServices]
  );

  const mappedPrivateServices: any = useMemo(
    () =>
      service?.Plans?.map((plan: any) => ({
        plan,
        id: plan.id,
        serviceFields: service.Servicetype.fields_template_json,
        rating: Number(plan.ratings_sum),
        providerName: service.Provider?.name as string,
        planName: plan.name,
        price: Number(plan.fields.Price),
        providerId: service.Provider?.id as string,
        providerInfo: service.Provider?.description as string,
        marketingUrl: service.marketing_url ?? ("-" as string),
        email: service.Provider?.Contacts?.[0]?.email ?? ("-" as string),
        phone: service.Provider?.Contacts?.[0]?.phone ?? ("-" as string),
      })).flat(),
    [service]
  );

  const isLoading = isContextLoading || areServicesLoading;

  const handleSearchResults = useCallback(() => {
    if (!mappedServices) {
      return;
    }

    const filters = Object.values(inputs)
      .filter((input) => input.value)
      .map((input) => input.name);

    const results = mappedServices.filter((service) =>
      filters.every(
        (filterName) =>
          getProperLabelValue(
            filterName,
            service.plan.fields[filterName] as string
          ) === inputs[filterName].value
      )
    );

    setSelectedRows([]);
    setResults(results);
  }, [inputs, mappedServices]);

  const handleSearchPrivate = useCallback(() => {
    if (!mappedPrivateServices || !mappedPrivateServices.length) {
      return;
    }

    const filters = Object.values(inputs)
      .filter((input) => input.value)
      .map((input) => input.name);

    const results = mappedPrivateServices.filter((service: any) =>
      filters.every(
        (filterName) =>
          getProperLabelValue(
            filterName,
            service.plan.fields[filterName] as string
          ) === inputs[filterName].value
      )
    );

    setSelectedRows([]);
    setResults(results);
  }, [inputs, mappedPrivateServices]);

  useEffect(() => {
    handleSearchPrivate();
  }, [mappedPrivateServices, handleSearchPrivate]);

  const toggleIsComparing = useCallback(
    () => setIsComparing((oldIsComparing) => !oldIsComparing),
    []
  );

  useEffect(() => {
    if (validServices) {
      const inputs = (
        JSON.parse(
          validServices[0].Servicetype.fields_template_json
        ) as FilterValueProperty[]
      )
        .filter((property) => property.filter === "1")
        .reduce((acc: Inputs, currentValue) => {
          if (!acc[currentValue.name]) {
            acc[currentValue.name] = {
              value: "",
              name: currentValue.name,
            };
          }
          return acc;
        }, {});
      setInputs(inputs);
    }
  }, [validServices]);

  return (
    <NavContainer title="Plans">
      <Box sx={{ width: "100%" }}>
        {isComparing ? (
          <Stack spacing={getREMFromPX(theme.spacing * 6)}>
            <PlansBreadCrumbs
              isComparing={isComparing}
              setIsComparing={toggleIsComparing}
              serviceType={serviceType}
              noDivider
            />
            <CompareScreen MappedPlans={selectedRows} />
          </Stack>
        ) : (
          <Stack
            spacing={getREMFromPX(theme.spacing * 8)}
            marginLeft={getREMFromPX(theme.spacing)}
          >
            <PlansBreadCrumbs
              serviceType={serviceType}
              isComparing={isComparing}
              setIsComparing={toggleIsComparing}
            />
            <Stack
              spacing={getREMFromPX(theme.spacing * 8)}
              padding={`${getREMFromPX(theme.spacing * 6)} ${getREMFromPX(
                theme.spacing * 6
              )} 0 ${getREMFromPX(theme.spacing * 8)}`}
              mt={`0 !important`}
            >
              {!selectedDeviceId && !isLoading && (
                <Warning message="No device is associated with your account." />
              )}
              {!results && (
                <>
                  <SubHeader
                    icon="house-signal"
                    headerText="Available Providers"
                  />
                  <Stack
                    mt={`${getREMFromPX(theme.spacing * 6)} !important`}
                    flexDirection="row"
                    flexWrap="wrap"
                    gap={getREMFromPX(theme.spacing * 6)}
                  >
                    {validServices
                      ?.reduce((acc, service) => {
                        acc.push({
                          ...service,
                          averageRating: average(
                            service.Plans.map(
                              (plan) =>
                                Number(plan.ratings_sum) /
                                Number(plan.total_reviews)
                            )
                          ),
                        } as unknown as MappedAverageRatingProvider);
                        return acc;
                      }, [] as MappedAverageRatingProvider[])
                      .map((provider) => (
                        <ProviderBox
                          id={provider.Provider?.id as string}
                          key={provider.Provider?.id}
                          name={provider.Provider?.name as string}
                          rating={provider.averageRating}
                          tooltipDesc={provider.Provider?.description as string}
                          onClickPlans={() => {
                            setResults(
                              mappedServices?.filter(
                                (service) =>
                                  service.providerName ===
                                  provider.Provider?.name
                              ) as MappedPlan[]
                            );
                          }}
                        />
                      ))}
                  </Stack>
                </>
              )}
              <Box
                padding={`${getREMFromPX(theme.spacing * 6)}`}
                minHeight={getREMFromPX(theme.spacing * 38)}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                hasBoxShadow
              >
                {!results && validServices && (
                  <Typography
                    component="h1"
                    paddingBottom={getREMFromPX(theme.spacing * 4)}
                    fontWeight={theme.fonts.weights.mediumBold}
                    fontSize={getREMFromPX(theme.spacing * 4.5)}
                  >
                    <FontAwesomeIcon
                      style={searchIconStyles}
                      icon="magnifying-glass"
                      fontSize={getREMFromPX(theme.spacing * 4)}
                      fill={colors.icons.default.fillColor}
                    />
                    Search Plans
                  </Typography>
                )}
                {validServices ? (
                  <SelectContainer
                    inputs={inputs}
                    setInputs={setInputs}
                    validServices={validServices as ServiceInfo[]}
                    handleSearchResults={handleSearchResults}
                  />
                ) : (
                  data && (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      padding={getREMFromPX(theme.spacing * 8)}
                      bgcolor={theme[themePath].colors.textSecondary.secondary}
                    >
                      <Typography
                        color={theme[themePath].colors.textPrimary.secondary}
                        fontSize={getREMFromPX(theme.spacing * 4.5)}
                        fontWeight={theme.fonts.weights.mediumBold}
                      >
                        No services to show.
                      </Typography>
                      <Typography
                        marginTop={getREMFromPX(theme.spacing * 2)}
                        color={theme[themePath].colors.textPrimary.secondary}
                        fontSize={getREMFromPX(theme.spacing * 4)}
                      >
                        There are no services available for this account right
                        now.
                      </Typography>
                    </Stack>
                  )
                )}
                {results && (
                  <PlansTable
                    disableComparePlansButton={
                      selectedRows.length < 2 || selectedRows.length === 4
                    }
                    onClickComparePlans={toggleIsComparing}
                    controlledSelectedRows={selectedRows}
                    onSelectRow={setSelectedRows}
                    mappedPlans={results as MappedPlan[]}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        )}
        <Backdrop open={isLoading}>
          <CircularProgress data-testid="progressSpinner" color="inherit" />
        </Backdrop>
      </Box>
    </NavContainer>
  );
};
export default Plans;
