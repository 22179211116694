import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, IconButton } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import Link from "../../../../components/Link";
import NoResults from "../../../../components/NoResults";
import Rating from "../../../../components/Rating";
import SubHeader from "../../../../components/SubHeader";
import Table from "../../../../components/Table";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { FilterValueProperty, MappedPlan } from "../../Plans";
import {
  iconStyles,
  selectableStyles,
  tableContainerStyles,
  dividerStyles,
  linkStyles,
} from "./styles";
import PlanDialog from "../../../../components/PlanDialog/PlanDialog";
import getProperLabelValue from "../../../../utils/getProperLabelValue";
import Button from "../../../../components/Button";
import { useParams } from "react-router-dom";
import { SortConfig } from "../../../../hooks/useSortableData";
import { useGetConfig } from "../../../../hooks/useGetConfig";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import SubscribeToISP from "../../../SubscribeToISP/SubscribeToISP";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import useGetThemePath from "../../../../hooks/useGetThemePath";

const PlansTable = ({
  mappedPlans,
  disableComparePlansButton,
  onClickComparePlans,
  ...props
}: {
  mappedPlans: MappedPlan[];
  controlledSelectedRows: MappedPlan[];
  onSelectRow: Dispatch<SetStateAction<MappedPlan[]>>;
  disableComparePlansButton: boolean;
  onClickComparePlans: () => void;
}) => {
  const { id: serviceId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { data: config } = useGetConfig();
  const { setItemInStorage } = useLocalStorage();
  const showReviews =
    config?.data?.config?.enabled_features?.USER_REVIEWS?.enabled;

  const serviceTypeFields = useMemo(
    () =>
      mappedPlans.length > 0 && mappedPlans[0].serviceFields
        ? (JSON.parse(mappedPlans[0].serviceFields) as FilterValueProperty[])
        : { name: "" },
    [mappedPlans]
  );

  const headers = useMemo(
    () => [
      {
        name: "Compare",
        width: "2%",
        style: { paddingLeft: getREMFromPX(theme.spacing * 4) },
      },
      {
        name: "Provider",
        sortable: true,
        sortKey: "providerName",
        width: "2%",
      },
      ...Object.values(serviceTypeFields)
        .filter((field) => field.name && field.filter === "1")
        .map(({ name }) => ({
          name,
          width: "2%",
          sortKey: name,
          sortable: true,
        })),
      ...(showReviews ? [{ name: "Rating", width: "5%" }] : []),
      { name: "Info", width: "2%" },
      { name: "", width: "2%" },
    ],
    [serviceTypeFields, showReviews]
  );

  const [selectedPlan, setSelectedPlan] = useState<MappedPlan | null>(null);

  const customSortFunction = useCallback(
    (sortConfig: SortConfig) => {
      if (sortConfig?.key) {
        const getSortKey = (item: MappedPlan) => {
          return sortConfig.key === "providerName"
            ? item.providerName
            : item.plan.fields[sortConfig.key];
        };
        const mappedPlansCopy = [...mappedPlans];

        return mappedPlansCopy.sort((a, b) => {
          let ska = getSortKey(a);
          let skb = getSortKey(b);

          // convert any keys of type string which contain no letters into an integer for comparison
          // e.g. $1,549.99 and $0.00 will convert to 154999 and 000 respectively
          if (typeof ska === "string" && !ska.match(/[a-zA-Z]+/)) {
            ska = parseInt(ska.replace(/\D/g, ""), 10);
          }
          if (typeof skb === "string" && !skb.match(/[a-zA-Z]+/)) {
            skb = parseInt(skb.replace(/\D/g, ""), 10);
          }

          if (ska < skb) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (ska > skb) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return mappedPlans;
    },
    [mappedPlans]
  );

  const columns = useMemo(
    () => [
      { value: "providerName" },
      ...Object.values(serviceTypeFields)
        .filter((field) => field.name && field.filter === "1")
        .map(({ name }) => ({
          name,
          getValue: (col: MappedPlan) =>
            getProperLabelValue(name, col.plan.fields[name] as string),
        })),
      ...(showReviews
        ? [
            {
              render: (col: MappedPlan) => (
                <Rating
                  data-testid={`internet_services_box_test_id_${Number(
                    col.plan.ratings_sum
                  )}`}
                  readOnly
                  value={
                    col.plan.total_reviews
                      ? Number(col.plan.ratings_sum) /
                        Number(col.plan.total_reviews)
                      : 0
                  }
                  fontSize={getREMFromPX(theme.spacing * 6)}
                  planId={col.plan.id}
                  planName={col.plan.name}
                />
              ),
            },
          ]
        : []),
      {
        render: (col: MappedPlan) => {
          return (
            <>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedPlan(col);
                }}
              >
                <FontAwesomeIcon
                  icon="info"
                  style={iconStyles}
                  fill={colors.icons.default.fillColor}
                />
              </IconButton>
            </>
          );
        },
      },
      {
        render: (col: MappedPlan) => {
          return (
            <Button
              size="medium"
              text="Subscribe"
              mode="default"
              onClick={() => {
                setOpenModal(true);
                setItemInStorage("serviceId", serviceId);
                setItemInStorage("planId", col.plan.id);
              }}
            />
          );
        },
      },
    ],
    [serviceTypeFields, serviceId, showReviews, setItemInStorage]
  );

  const handleComparePlansOnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (disableComparePlansButton) {
      return;
    }
    onClickComparePlans();
  };

  return (
    <>
      <Divider style={dividerStyles} />
      <SubHeader icon="magnifying-glass" headerText="Results" />
      <div style={tableContainerStyles}>
        {mappedPlans.length > 0 ? (
          <>
            <Table
              customSortFunction={customSortFunction}
              selectableStyles={selectableStyles}
              headers={headers}
              isSelectable
              items={mappedPlans}
              columns={columns}
              {...props}
            />

            <Link
              style={linkStyles}
              disabled={disableComparePlansButton}
              size="large"
              to="*"
              onClick={handleComparePlansOnClick}
            >
              Compare Plans
            </Link>
          </>
        ) : (
          <NoResults />
        )}
      </div>
      <PlanDialog
        isOpen={!!selectedPlan}
        onClose={() => setSelectedPlan(null)}
        plan={selectedPlan}
      />
      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <SubscribeToISP />
      </CustomModal>
    </>
  );
};
export default PlansTable;
