import { useContext } from "react";
import { Box } from "@mui/material";
import CardWrapper from "../../../../components/CardWrapper";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NavContainer";
import CardWrapperFilled from "../../../../components/CardWrapper/CardWrapperFilled";
import { useGetSystemVariables } from "../../../../hooks/useGetSystemVariables";
import sendErrorToast from "../../../../utils/sendErrorToast";
import { BackStyle } from "../../Styles";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { EmailStyle, Message } from "./Styles";
import { useGetConfig } from "../../../../hooks/useGetConfig";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { Language, Email } from "@mui/icons-material";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import { useSubscriberConfig } from "../../../../context/SubscriberPortalConfigurations";

const Faq = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const { provider } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const contacts =
    provider &&
    provider.data &&
    provider.data.provider &&
    provider.data.provider.Contacts;
  let email =
    provider &&
    provider.data &&
    provider.data.provider &&
    provider.data.provider.Contacts &&
    provider.data.provider.Contacts[0].email;
  if (Array.isArray(contacts)) {
    const aux = contacts.filter((contact) => contact.type === "SUPPORT");
    if (aux.length > 0) {
      email = aux[0].email;
    }
  }
  const { data: config } = useGetConfig();
  const { faqs } = useSubscriberConfig();

  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const { data } = useGetSystemVariables({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "There was an error getting the recover username link, please try again"
      ),
  });

  const tickerURL = data?.data?.system?.support_ticket_url;

  const backAction = (
    <BackStyle aria-label="Back to Help" to={routes.help.path}>
      Back to Help
    </BackStyle>
  );

  return (
    <NavContainer title="Help">
      <CardWrapper
        cardTitleSxProps={{
          gap: 2,
          flexDirection: {
            sm: "row",
            xs: "column-reverse",
          },
          alignItems: {
            sm: "start",
          },
        }}
        title="Frequently Asked Questions"
        actions={backAction}
      >
        <Box sx={{ marginTop: "64px" }}>
          {faqs.map((item, index) => {
            const question = item.question;
            const answer = item.answer;

            if (!showBilling) {
              if (
                question ===
                "How do I change my payment method or update my credit card?"
              ) {
                return null;
              }
            }
            return (
              <Accordion key={index} defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon fill={colors.icons.default.fillColor} />
                  }
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  {question}
                </AccordionSummary>
                <AccordionDetails>{answer}</AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
        <CardWrapperFilled
          title="Need further assistance?"
          sx={{
            marginTop: "3.9rem",
            zIndex: 2,
            borderRadius: "8px",
            fontFamily: "Inter",
          }}
        >
          <Message
            sx={{
              marginTop: "1rem",
            }}
          >
            Our customer service is available to assist you with any further
            needs or inquiries you may have.
          </Message>
          <Message>
            Having trouble with your service? Please contact your service
            provider.
          </Message>
          {tickerURL ? (
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
              to={tickerURL}
              referrerPolicy="no-referrer"
              target="_blank"
            >
              <Language
                sx={{
                  width: "25px",
                  height: "25px",
                  color: "#000",
                }}
              />
              <EmailStyle>Click Here for Help</EmailStyle>
            </Link>
          ) : null}
          {email ? (
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "18px",
              }}
              to={`mailto:${email}`}
            >
              <Email
                sx={{
                  width: "25px",
                  height: "25px",
                  color: "#000",
                }}
              />
              <EmailStyle>{email}</EmailStyle>
            </Link>
          ) : null}
        </CardWrapperFilled>
      </CardWrapper>
    </NavContainer>
  );
};
export default Faq;
