import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardWrapper from "../../../../components/CardWrapper";
import { routes } from "../../../../App";
import { VenoenDeviceBack, AaeonDeviceBack } from "../../../../assets/device";
import { Grid, SxProps } from "@mui/material";
import { ContinueIcon, Description, ImageContainer } from "../../Styles";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";

const DeviceGuide = ({ styles }: { styles: SxProps }) => {
  const navigate = useNavigate();
  const { currentDevice } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const arrowAction = (
    <Link
      to={routes.helpDevice.path}
      aria-label="Goto Device Help Info page"
      data-cy="help-device-arrow"
    >
      <ContinueIcon fill={colors.icons.default.fillColor} />
    </Link>
  );

  const DeviceGraphic =
    currentDevice?.Product?.Manufacturer?.name === "Aaeon"
      ? AaeonDeviceBack
      : VenoenDeviceBack;

  return (
    <CardWrapper title="Device Guide" actions={arrowAction} sx={styles}>
      <Grid
        container
        spacing={2}
        onClick={() => navigate(routes.helpDevice.path)}
        sx={{
          flexDirection: "row",
          flexWrap: "nowrap",
        }}
      >
        <Grid
          item
          sm={6}
          md={6}
          display="flex"
          alignItems="center"
          height="auto"
        >
          <Description>
            Want to learn more <br /> about your device?
          </Description>
        </Grid>
        <Grid item sm={6} md={6}>
          <ImageContainer>
            <DeviceGraphic
              ports={null}
              showCircle={true}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                marginBottom: "1.84rem",
              }}
            />
          </ImageContainer>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};
export default DeviceGuide;
