import {
  Backdrop,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Button from "../../../../components/Button";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type, Step2Type, Step3Type } from "../../AccountSettings";
import {
  headerStyle,
  dividerStyle,
  getSubHeaderStyle,
  iconStyle,
} from "./Styles";
import SecurityQuestionSelection from "../../../../components/SecurityQuestionSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Step3Props {
  changeStep: (args: Step3Type, submit: boolean) => void;
  submitting?: boolean;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
    step3: Step3Type;
  };
  goBackOneStep: () => void;
}

const Step3 = ({
  changeStep,
  previousValues,
  goBackOneStep,
  submitting = false,
}: Step3Props) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const [isValid, setIsValid] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  const isLoading = isLoadingQuestions;

  const disableButton = !isValid || submitting;

  const onSubmit = (questions: Step3Type) => {
    changeStep({ stepName: "step3", ...questions }, true);
  };

  return (
    <>
      <Stack spacing={getREMFromPX(theme.spacing * 2)}>
        <Typography sx={headerStyle} component="h1">
          Account Security
        </Typography>
        <Typography sx={getSubHeaderStyle(themePath)}>
          Maintain your account’s security:
        </Typography>
      </Stack>
      <Stack mt={getREMFromPX(theme.spacing * 2)}>
        <Typography
          fontWeight={theme.fonts.weights.normal}
          fontSize={getREMFromPX(theme.spacing * 4)}
          color={theme[themePath].colors.textPrimary.secondary}
        >
          Please select two security questions below.
        </Typography>
        <Typography
          fontWeight={theme.fonts.weights.normal}
          fontSize={getREMFromPX(theme.spacing * 4)}
          color={theme[themePath].colors.textPrimary.secondary}
        >
          These questions help us verify your identity should you not have
          access to your account.
        </Typography>
      </Stack>
      <SecurityQuestionSelection
        onSubmit={onSubmit}
        previousValues={previousValues.step3}
        setIsValid={setIsValid}
        setIsLoadingQuestions={setIsLoadingQuestions}
      >
        <>
          <Divider sx={dividerStyle} />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={getREMFromPX(theme.spacing * 6)}
          >
            <Button
              startIcon={
                <FontAwesomeIcon
                  icon="angle-left"
                  style={iconStyle}
                  fill={colors.icons.default.fillColor}
                />
              }
              onClick={goBackOneStep}
              variant="text"
              text="Back"
              size="medium"
            />
            <Button
              data-testid="continueButton"
              text="Continue"
              type="submit"
              size="medium"
              isLoading={submitting}
              disabled={disableButton}
            />
          </Stack>
        </>
      </SecurityQuestionSelection>
      <Backdrop open={isLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </>
  );
};
export default Step3;
