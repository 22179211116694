const VenoenDeviceBack = ({
  ports,
  style,
  showCircle,
}: {
  ports: any[] | null | undefined;
  style?: React.CSSProperties;
  showCircle?: boolean;
}) => {
  const lanPorts = ports?.filter(
    (portinterface) =>
      (portinterface?.display === true ||
        portinterface?.data?.portinterface?.display === true) &&
      (portinterface?.type === "LAN" ||
        portinterface?.data?.portinterface?.type === "LAN")
  );
  const lanOnePorts = lanPorts?.filter(
    (portinterface) =>
      portinterface?.display_name === "LAN 1" ||
      portinterface?.data?.portinterface?.display_name === "LAN 1"
  );

  const lanOnePort = lanOnePorts ? lanOnePorts[0] : null;
  const displayLanOne =
    lanOnePort?.data?.portinterface?.live?.link_state === "up";

  const lanTwoPorts = lanPorts?.filter(
    (portinterface) =>
      portinterface?.display_name === "LAN 2" ||
      portinterface?.data?.portinterface?.display_name === "LAN 2"
  );

  const lanTwoPort = lanTwoPorts ? lanTwoPorts[0] : null;
  const displayLanTwo =
    lanTwoPort?.data?.portinterface?.live?.link_state === "up";

  const wanPorts = ports?.filter(
    (portinterface) =>
      (portinterface?.display === true ||
        portinterface?.data?.portinterface?.display === true) &&
      (portinterface?.type === "WAN" ||
        portinterface?.data?.portinterface?.type === "WAN")
  );

  const wanPort = wanPorts ? wanPorts[0] : null;

  const wanDisplay =
    wanPort?.data?.portinterface?.live?.link_state === "up" ? "block" : "none";

  const popoverEnter = (popUp: string) => {
    let popUpitem = document.getElementById(popUp + "_popup");
    let highlightItem = document.getElementById(popUp + "_highlight");

    if (popUpitem) {
      popUpitem.style.display = "block";
    }

    if (highlightItem) {
      highlightItem.style.display = "block";
    }
  };

  const popoverLeave = (popUp: string) => {
    let popUpitem = document.getElementById(popUp + "_popup");
    let highlightItem = document.getElementById(popUp + "_highlight");

    if (popUpitem) {
      popUpitem.style.display = "none";
    }

    if (highlightItem) {
      highlightItem.style.display = "none";
    }
  };

  return (
    <svg
      width="100%"
      height="217"
      viewBox={showCircle ? "-100 -100 618 434" : "0 0 518 217"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      {showCircle && <circle cx="160" cy="95" r="250" fill="#D7F1DA" />}
      <g filter="url(#filter0_d_5810_18226)">
        <path
          d="M55.4715 139.269L235.057 180.691C243.687 182.682 252.645 182.8 261.325 181.038L441.669 144.429C445.397 143.672 448.077 140.394 448.077 136.589L448.077 54.5001L444.221 57.8128L259.32 89.6071C251.937 90.8768 244.384 90.7976 237.029 89.3735L52.2479 53.599L49.2695 50L49.2695 131.474C49.2695 135.2 51.8413 138.432 55.4715 139.269Z"
          fill="url(#paint0_linear_5810_18226)"
        />
      </g>
      <path
        d="M194 113V138.507L217.531 143.517V117.761L194 113Z"
        fill="#B0B0B0"
      />
      <path
        d="M213.747 139.489V120.509L197.253 117.111V136.012L213.747 139.489Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.506107"
      />
      <ellipse
        cx="70.4114"
        cy="96.0636"
        rx="6.0999"
        ry="8.1332"
        transform="rotate(-9.93291 70.4114 96.0636)"
        fill="#D9D9D9"
      />
      <path
        d="M85.4871 102.42L85.4871 96.2081L95.0867 98.2386L104.756 100.284L104.756 106.478L85.4871 102.42Z"
        fill="#D1D1D1"
        stroke="#E7E6E6"
        strokeWidth="0.337405"
      />
      <path
        d="M147.287 109.416L143.746 109.91L144.735 117.327L148.69 116.338C150.174 114.36 149.759 109.91 147.287 109.416Z"
        fill="#717474"
      />
      <ellipse
        cx="144.664"
        cy="113.597"
        rx="2.38574"
        ry="3.89152"
        transform="rotate(-4.19759 144.664 113.597)"
        fill="#D9D9D9"
      />
      <ellipse
        cx="144.609"
        cy="113.62"
        rx="1.87887"
        ry="3.36644"
        transform="rotate(-4.19759 144.609 113.62)"
        fill="#A4A0A0"
      />
      <path
        d="M171 125.193L175.762 124.335C176.806 124.147 177.521 123.177 177.393 122.125L176.173 112.128C176.07 111.279 175.437 110.589 174.6 110.413L158.081 106.934C157.584 106.829 157.072 106.82 156.572 106.907L152 107.7L150.5 109L171 125.193Z"
        fill="#717473"
      />
      <path
        d="M151.159 120.641L169.773 124.725C171.436 125.089 172.968 123.723 172.796 122.03L171.969 113.883C171.859 112.804 171.066 111.919 170.006 111.691L169.901 112.18L170.006 111.691L153.303 108.11C151.886 107.806 150.508 108.767 150.304 110.203L149.219 117.847C149.035 119.142 149.881 120.36 151.159 120.641Z"
        fill="#6F86C2"
        stroke="#D9D9D9"
      />
      <path
        d="M181.64 116.523L178.1 117.018L179.088 124.434L183.044 123.446C184.527 121.468 184.113 117.018 181.64 116.523Z"
        fill="#717474"
      />
      <ellipse
        cx="179.018"
        cy="120.704"
        rx="2.38574"
        ry="3.89152"
        transform="rotate(-4.19759 179.018 120.704)"
        fill="#D9D9D9"
      />
      <ellipse
        cx="178.963"
        cy="120.728"
        rx="1.87887"
        ry="3.36644"
        transform="rotate(-4.19759 178.963 120.728)"
        fill="#A4A0A0"
      />
      <path
        d="M113.471 108.374L113.471 102.234L132.652 106.203L132.652 112.405L113.471 108.374Z"
        fill="#4485D1"
        stroke="#C4C4C4"
        strokeWidth="0.337405"
      />
      <path
        d="M444.671 57.4672L259.058 89.1057C251.71 90.3583 244.195 90.2753 236.876 88.8608L52.1419 53.1586C48.8318 52.5189 48.9308 47.7485 52.2645 47.2465L240.346 18.9286C244.432 18.3134 248.589 18.3515 252.663 19.0413L444.667 51.552C447.998 52.1159 448 56.8996 444.671 57.4672Z"
        fill="#C6C6C6"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
      <path
        d="M444.671 56.4672L259.058 88.1057C251.71 89.3583 244.195 89.2753 236.876 87.8608L52.1419 52.1586C48.8318 51.5189 48.9308 46.7485 52.2645 46.2465L240.346 17.9286C244.432 17.3134 248.589 17.3515 252.663 18.0413L444.667 50.552C447.998 51.1159 448 55.8996 444.671 56.4672Z"
        fill="#777777"
      />
      <path
        d="M55.202 138.269L241.76 181.299C244.893 182.022 247.884 179.642 247.884 176.427V90.5273L51.9784 52.599L49 49L49 130.474C49 134.2 51.5717 137.432 55.202 138.269Z"
        fill="url(#paint1_linear_5810_18226)"
      />
      <path
        d="M92.3619 95.4643C92.593 96.8256 91.8975 98.0789 90.8085 98.2638C89.7195 98.4486 88.6494 97.4949 88.4183 96.1337C88.1873 94.7725 88.8828 93.5191 89.9718 93.3343C91.0608 93.1494 92.1309 94.1031 92.3619 95.4643Z"
        fill="#84E88E"
      />
      <path
        d="M92.3697 102.562C92.6067 103.922 91.9166 105.178 90.8285 105.368C89.7403 105.557 88.666 104.608 88.4291 103.248C88.1921 101.888 88.8821 100.631 89.9703 100.442C91.0585 100.252 92.1327 101.201 92.3697 102.562Z"
        fill="#EC0000"
      />
      <path
        d="M123.362 102.464C123.593 103.826 122.897 105.079 121.808 105.264C120.719 105.449 119.649 104.495 119.418 103.134C119.187 101.772 119.883 100.519 120.972 100.334C122.061 100.149 123.131 101.103 123.362 102.464Z"
        fill="#84E88E"
      />
      <path
        d="M123.37 109.562C123.607 110.922 122.917 112.178 121.828 112.368C120.74 112.557 119.666 111.608 119.429 110.248C119.192 108.888 119.882 107.631 120.97 107.442C122.059 107.252 123.133 108.201 123.37 109.562Z"
        fill="#EC0000"
      />
      <path
        d="M67.1687 100.324V86.207L83.8313 89.6414V103.857L67.1687 100.324Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.337405"
      />
      <path
        d="M98.1687 107.105V92.8066L114.831 96.241V110.647L98.1687 107.105Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.337405"
      />
      <path
        d="M205.347 119.468C205.566 120.831 204.86 122.079 203.769 122.254C202.679 122.43 201.617 121.467 201.397 120.104C201.178 118.741 201.884 117.493 202.975 117.318C204.065 117.142 205.127 118.105 205.347 119.468Z"
        fill="#84E88E"
      />
      <path
        d="M205.347 126.54C205.566 127.903 204.86 129.151 203.769 129.326C202.679 129.502 201.617 128.539 201.397 127.176C201.178 125.813 201.884 124.566 202.975 124.39C204.065 124.214 205.127 125.177 205.347 126.54Z"
        fill="#EC0000"
      />
      <path
        d="M220.958 125.976C221.482 129.18 219.879 131.994 217.519 132.381C215.16 132.767 212.743 130.61 212.218 127.407C211.694 124.204 213.298 121.389 215.657 121.003C218.016 120.617 220.434 122.773 220.958 125.976Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.5"
      />
      <path
        d="M444.67 57.4672L259.057 89.1057C251.709 90.3583 244.194 90.2753 236.875 88.8608L52.1409 53.1586C48.8309 52.5189 48.9298 47.7485 52.2635 47.2465L240.345 18.9286C244.431 18.3134 248.588 18.3515 252.662 19.0413L444.666 51.552C447.997 52.1159 447.999 56.8996 444.67 57.4672Z"
        fill="#EEEEEE"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
      <path
        d="M444.67 56.4672L259.057 88.1057C251.709 89.3583 244.194 89.2753 236.875 87.8608L52.1409 52.1586C48.8309 51.5189 48.9298 46.7485 52.2635 46.2465L240.345 17.9286C244.431 17.3134 248.588 17.3515 252.662 18.0413L444.666 50.552C447.997 51.1159 447.999 55.8996 444.67 56.4672Z"
        fill="#777777"
      />
      <path
        d="M139.923 96.4066L133 95V100.616L139.923 102.038V99.2224V96.4066Z"
        fill="#B0B0B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.729 125.673L133 120.626V100.616L139.923 102.038V99.2224V96.4066L149.423 98.3368V103.99L156.729 105.491V125.673Z"
        fill="#B0B0B0"
      />
      <path
        d="M156.729 105.491V99.8213L149.423 98.3368V103.99L156.729 105.491Z"
        fill="#B0B0B0"
      />
      <path
        d="M160 126.36V100.481L183.729 105.271L183.729 131.415L160 126.36Z"
        fill="#B0B0B0"
      />
      <path
        d="M137.253 115.577V99.7131L152.482 102.815V118.788L137.253 115.577Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.506107"
      />
      <path
        d="M163.331 121.106V105.018L178.56 108.141V124.318L163.331 121.106Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.506107"
      />
      <path
        d="M156 124.98V120.481L149.5 119.086V123.574L156 124.98Z"
        fill="#FFB800"
      />
      <path
        d="M134 120.221V115.76L140 117.047V121.519L134 120.221Z"
        fill="#84E88E"
      />
      <path
        d="M183 130.72V126.221L176.5 124.826V129.314L183 130.72Z"
        fill="#FFB800"
      />
      <path
        d="M161 121.5V125.961L167 127.259V122.788L161 121.5Z"
        fill="#84E88E"
      />

      {displayLanOne && (
        <g>
          <g
            id="LAN_1"
            className="LAN_1"
            onMouseEnter={() => popoverEnter("LAN_1")}
          >
            <path
              fill="#47A7E7"
              d="M86.6146 114.499L84.1151 115.749C74.7548 119.368 78.7446 117.716 69.6113 121.866L72.5982 122.186L73.1201 124.572L75.0532 125.392L93.2846 117.352C92.5428 114.298 89.6754 111.777 86.6146 114.499Z"
            />
            <path
              fill="#2482C1"
              d="M87.583 124.335C90.0513 123.282 91.6137 122.622 91.6137 122.622C93.367 121.46 93.7574 119.298 93.2848 117.352L75.0534 125.391L74.1642 127.106L74.6116 128.895L72.7432 131.11C72.7432 131.11 81.2251 127.047 87.583 124.335Z"
            />
            <path
              fill="#2696E0"
              d="M91.6126 122.622C96.1176 119.637 91.6247 110.043 86.6137 114.499L84.1143 115.749C84.5132 113.55 85.9888 112.624 85.9888 112.624C86.7764 112.137 87.5302 111.838 88.2423 111.697C90.7964 111.189 92.8139 112.713 93.9288 114.892C95.7803 118.509 95.1428 123.933 90.3407 124.859C90.3407 124.859 88.6257 124.934 87.5818 124.335C90.0502 123.282 91.6126 122.622 91.6126 122.622Z"
            />
            <path
              fill="#51B0EF"
              d="M102.684 105.752C106.754 104.278 116.431 108.876 117.055 109.501L93.9297 114.892C92.8148 112.714 90.7973 111.19 88.2432 111.697L102.684 105.752Z"
            />
            <path
              fill="#2482C1"
              d="M117.581 123.607L90.3408 124.859C95.143 123.933 95.7805 118.509 93.929 114.891L117.055 109.5C117.679 110.125 118.648 118.846 117.581 123.607Z"
            />
            <path
              fill="#2482C1"
              d="M136.426 122.622C141.392 126.736 150.166 125.128 150.508 124.328C150.851 123.528 151.422 120.123 151.422 120.123L136.426 122.622Z"
            />
            <path
              fill="#47A7E7"
              d="M102.684 105.751L111.208 101.08C116.725 100.492 130.306 102.889 130.802 104.502L117.055 109.501C116.431 108.876 106.754 104.278 102.684 105.751Z"
            />
            <path
              fill="#2696E0"
              d="M111.208 101.08C111.208 101.08 134.289 96.7803 136.345 97.0088C138.701 97.0088 152.216 99.6627 154.353 100.494L130.802 104.502C130.307 102.889 116.725 100.492 111.208 101.08Z"
            />
            <path
              fill="#2075AD"
              d="M130.789 123.839C129.304 124.182 117.581 123.608 117.581 123.608C118.648 118.847 117.679 110.126 117.055 109.501L130.801 104.502C131.131 105.574 130.907 117.054 130.789 123.839Z"
            />
            <path
              fill="#195C88"
              d="M154.352 100.494C155.152 102.11 155.346 118.538 154.546 119.469L151.421 120.123L136.425 122.622C134.257 123.471 132.242 123.678 131.295 123.776L131.295 123.776C131.049 123.801 130.875 123.819 130.789 123.839C130.907 117.054 131.131 105.573 130.801 104.501L154.352 100.494Z"
            />
          </g>
          <mask
            id="mask0_3877_17956"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="69"
            y="96"
            width="87"
            height="35"
          >
            <g transform="translate(0, 7)">
              <path
                fill="#47A7E7"
                d="M86.6146 107.499L84.1151 108.749C74.7548 112.368 78.7446 110.716 69.6113 114.866L72.5982 115.186L73.1201 117.572L75.0532 118.392L93.2846 110.352C92.5428 107.298 89.6754 104.777 86.6146 107.499Z"
              />
              <path
                fill="#2482C1"
                d="M87.582 117.335C90.0503 116.282 91.6127 115.622 91.6127 115.622C93.366 114.46 93.7564 112.298 93.2838 110.352L75.0525 118.391L74.1632 120.106L74.6106 121.895L72.7422 124.11C72.7422 124.11 81.2241 120.047 87.582 117.335Z"
              />
              <path
                fill="#2696E0"
                d="M91.6135 115.622C96.1185 112.637 91.6257 103.043 86.6147 107.499L84.1152 108.749C84.5142 106.55 85.9898 105.624 85.9898 105.624C86.7774 105.137 87.5312 104.838 88.2433 104.697C90.7974 104.189 92.8149 105.713 93.9298 107.892C95.7813 111.509 95.1438 116.933 90.3416 117.859C90.3416 117.859 88.6267 117.934 87.5828 117.335C90.0511 116.282 91.6135 115.622 91.6135 115.622Z"
              />
              <path
                fill="#51B0EF"
                d="M102.683 98.7516C106.753 97.2777 116.43 101.876 117.054 102.501L93.9287 107.892C92.8138 105.714 90.7963 104.19 88.2422 104.697L102.683 98.7516Z"
              />
              <path
                fill="#2482C1"
                d="M117.582 116.607L90.3418 117.859C95.1439 116.933 95.7814 111.509 93.93 107.891L117.056 102.5C117.68 103.125 118.649 111.846 117.582 116.607Z"
              />
              <path
                fill="#2482C1"
                d="M136.424 115.622C141.39 119.736 150.164 118.128 150.507 117.328C150.849 116.528 151.42 113.123 151.42 113.123L136.424 115.622Z"
              />
              <path
                fill="#47A7E7"
                d="M102.684 98.7514L111.208 94.08C116.725 93.4924 130.306 95.8895 130.802 97.5017L117.055 102.501C116.431 101.876 106.754 97.2775 102.684 98.7514Z"
              />
              <path
                fill="#2696E0"
                d="M111.207 94.0799C111.207 94.0799 134.288 89.7803 136.344 90.0088C138.7 90.0088 152.215 92.6627 154.352 93.4942L130.801 97.5016C130.306 95.8894 116.724 93.4923 111.207 94.0799Z"
              />
              <path
                fill="#2075AD"
                d="M130.789 116.839C129.304 117.182 117.581 116.608 117.581 116.608C118.648 111.847 117.679 103.126 117.055 102.501L130.801 97.502C131.131 98.5735 130.907 110.054 130.789 116.839Z"
              />
              <path
                fill="#195C88"
                d="M154.352 93.4941C155.152 95.1101 155.346 111.538 154.546 112.469L151.421 113.123L136.425 115.622C134.257 116.471 132.242 116.678 131.295 116.776L131.295 116.776C131.049 116.801 130.875 116.819 130.789 116.839C130.907 110.054 131.131 98.573 130.801 97.5015L154.352 93.4941Z"
              />
            </g>
          </mask>
          <g
            id="LAN_1_highlight"
            onMouseLeave={() => popoverLeave("LAN_1")}
            style={{ display: "none" }}
            mask="url(#mask0_3877_17956)"
          >
            <g style={{ mixBlendMode: "hue" }} opacity="0.65">
              <rect
                x="55.9961"
                y="89.002"
                width="99.8533"
                height="40.9325"
                fill="#41CE3E"
              />
            </g>
          </g>
          <g
            style={{ display: "none" }}
            id="LAN_1_popup"
            filter="url(#filter1_d_3877_17956)"
            transform="translate(0, 7)"
          >
            <path
              d="M145.319 83.4248L161.729 63.8843H128.91L145.319 83.4248Z"
              fill="white"
            />
            <rect
              x="79"
              y="8.58008"
              width="135.007"
              height="57.3169"
              rx="18.9484"
              fill="white"
            />
            <path
              d="M100.878 45.2383V28.5609H103.26V43.1655H112.29V45.2383H100.878ZM112.498 45.2383L120.051 28.5609H122.409L129.986 45.2383H127.484L120.742 29.8951H121.695L114.952 45.2383H112.498ZM115.715 41.0689L116.358 39.163H125.745L126.436 41.0689H115.715ZM132.473 45.2383V28.5609H134.427L145.458 42.2602H144.433V28.5609H146.816V45.2383H144.862L133.831 31.539H134.856V45.2383H132.473ZM159.659 45.2383V29.5139L160.684 30.6337H155.895V28.5609H162.018V45.2383H159.659Z"
              fill="#253331"
            />
            <ellipse
              cx="186.768"
              cy="37.0028"
              rx="8.28992"
              ry="8.28992"
              fill="#EC0000"
            />
            <ellipse
              cx="186.768"
              cy="37.0028"
              rx="8.28992"
              ry="8.28992"
              fill="#84E88E"
            />
          </g>
        </g>
      )}
      {displayLanTwo && (
        <g transform="translate(27, 6)">
          <g
            id="LAN_2"
            className="LAN_2"
            onMouseEnter={() => popoverEnter("LAN_2")}
          >
            <path
              fill="#47A7E7"
              d="M86.6146 114.499L84.1151 115.749C74.7548 119.368 78.7446 117.716 69.6113 121.866L72.5982 122.186L73.1201 124.572L75.0532 125.392L93.2846 117.352C92.5428 114.298 89.6754 111.777 86.6146 114.499Z"
            />
            <path
              fill="#2482C1"
              d="M87.583 124.335C90.0513 123.282 91.6137 122.622 91.6137 122.622C93.367 121.46 93.7574 119.298 93.2848 117.352L75.0534 125.391L74.1642 127.106L74.6116 128.895L72.7432 131.11C72.7432 131.11 81.2251 127.047 87.583 124.335Z"
            />
            <path
              fill="#2696E0"
              d="M91.6126 122.622C96.1176 119.637 91.6247 110.043 86.6137 114.499L84.1143 115.749C84.5132 113.55 85.9888 112.624 85.9888 112.624C86.7764 112.137 87.5302 111.838 88.2423 111.697C90.7964 111.189 92.8139 112.713 93.9288 114.892C95.7803 118.509 95.1428 123.933 90.3407 124.859C90.3407 124.859 88.6257 124.934 87.5818 124.335C90.0502 123.282 91.6126 122.622 91.6126 122.622Z"
            />
            <path
              fill="#51B0EF"
              d="M102.684 105.752C106.754 104.278 116.431 108.876 117.055 109.501L93.9297 114.892C92.8148 112.714 90.7973 111.19 88.2432 111.697L102.684 105.752Z"
            />
            <path
              fill="#2482C1"
              d="M117.581 123.607L90.3408 124.859C95.143 123.933 95.7805 118.509 93.929 114.891L117.055 109.5C117.679 110.125 118.648 118.846 117.581 123.607Z"
            />
            <path
              fill="#2482C1"
              d="M136.426 122.622C141.392 126.736 150.166 125.128 150.508 124.328C150.851 123.528 151.422 120.123 151.422 120.123L136.426 122.622Z"
            />
            <path
              fill="#47A7E7"
              d="M102.684 105.751L111.208 101.08C116.725 100.492 130.306 102.889 130.802 104.502L117.055 109.501C116.431 108.876 106.754 104.278 102.684 105.751Z"
            />
            <path
              fill="#2696E0"
              d="M111.208 101.08C111.208 101.08 134.289 96.7803 136.345 97.0088C138.701 97.0088 152.216 99.6627 154.353 100.494L130.802 104.502C130.307 102.889 116.725 100.492 111.208 101.08Z"
            />
            <path
              fill="#2075AD"
              d="M130.789 123.839C129.304 124.182 117.581 123.608 117.581 123.608C118.648 118.847 117.679 110.126 117.055 109.501L130.801 104.502C131.131 105.574 130.907 117.054 130.789 123.839Z"
            />
            <path
              fill="#195C88"
              d="M154.352 100.494C155.152 102.11 155.346 118.538 154.546 119.469L151.421 120.123L136.425 122.622C134.257 123.471 132.242 123.678 131.295 123.776L131.295 123.776C131.049 123.801 130.875 123.819 130.789 123.839C130.907 117.054 131.131 105.573 130.801 104.501L154.352 100.494Z"
            />
          </g>
          <mask
            id="mask0_3877_17956"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="69"
            y="96"
            width="87"
            height="35"
          >
            <g transform="translate(0, 7)">
              <path
                fill="#47A7E7"
                d="M86.6146 107.499L84.1151 108.749C74.7548 112.368 78.7446 110.716 69.6113 114.866L72.5982 115.186L73.1201 117.572L75.0532 118.392L93.2846 110.352C92.5428 107.298 89.6754 104.777 86.6146 107.499Z"
              />
              <path
                fill="#2482C1"
                d="M87.582 117.335C90.0503 116.282 91.6127 115.622 91.6127 115.622C93.366 114.46 93.7564 112.298 93.2838 110.352L75.0525 118.391L74.1632 120.106L74.6106 121.895L72.7422 124.11C72.7422 124.11 81.2241 120.047 87.582 117.335Z"
              />
              <path
                fill="#2696E0"
                d="M91.6135 115.622C96.1185 112.637 91.6257 103.043 86.6147 107.499L84.1152 108.749C84.5142 106.55 85.9898 105.624 85.9898 105.624C86.7774 105.137 87.5312 104.838 88.2433 104.697C90.7974 104.189 92.8149 105.713 93.9298 107.892C95.7813 111.509 95.1438 116.933 90.3416 117.859C90.3416 117.859 88.6267 117.934 87.5828 117.335C90.0511 116.282 91.6135 115.622 91.6135 115.622Z"
              />
              <path
                fill="#51B0EF"
                d="M102.683 98.7516C106.753 97.2777 116.43 101.876 117.054 102.501L93.9287 107.892C92.8138 105.714 90.7963 104.19 88.2422 104.697L102.683 98.7516Z"
              />
              <path
                fill="#2482C1"
                d="M117.582 116.607L90.3418 117.859C95.1439 116.933 95.7814 111.509 93.93 107.891L117.056 102.5C117.68 103.125 118.649 111.846 117.582 116.607Z"
              />
              <path
                fill="#2482C1"
                d="M136.424 115.622C141.39 119.736 150.164 118.128 150.507 117.328C150.849 116.528 151.42 113.123 151.42 113.123L136.424 115.622Z"
              />
              <path
                fill="#47A7E7"
                d="M102.684 98.7514L111.208 94.08C116.725 93.4924 130.306 95.8895 130.802 97.5017L117.055 102.501C116.431 101.876 106.754 97.2775 102.684 98.7514Z"
              />
              <path
                fill="#2696E0"
                d="M111.207 94.0799C111.207 94.0799 134.288 89.7803 136.344 90.0088C138.7 90.0088 152.215 92.6627 154.352 93.4942L130.801 97.5016C130.306 95.8894 116.724 93.4923 111.207 94.0799Z"
              />
              <path
                fill="#2075AD"
                d="M130.789 116.839C129.304 117.182 117.581 116.608 117.581 116.608C118.648 111.847 117.679 103.126 117.055 102.501L130.801 97.502C131.131 98.5735 130.907 110.054 130.789 116.839Z"
              />
              <path
                fill="#195C88"
                d="M154.352 93.4941C155.152 95.1101 155.346 111.538 154.546 112.469L151.421 113.123L136.425 115.622C134.257 116.471 132.242 116.678 131.295 116.776L131.295 116.776C131.049 116.801 130.875 116.819 130.789 116.839C130.907 110.054 131.131 98.573 130.801 97.5015L154.352 93.4941Z"
              />
            </g>
          </mask>
          <g
            id="LAN_2_highlight"
            onMouseLeave={() => popoverLeave("LAN_2")}
            style={{ display: "none" }}
            mask="url(#mask0_3877_17956)"
          >
            <g style={{ mixBlendMode: "hue" }} opacity="0.65">
              <rect
                x="55.9961"
                y="89.002"
                width="99.8533"
                height="40.9325"
                fill="#41CE3E"
              />
            </g>
          </g>
          <g
            style={{ display: "none" }}
            id="LAN_2_popup"
            filter="url(#filter2_d_3877_17956)"
            transform="translate(-22, 4)"
          >
            <path
              d="M166.319 87.4248L182.729 67.8843H149.91L166.319 87.4248Z"
              fill="white"
            />
            <rect
              x="98.8164"
              y="12.5801"
              width="137.376"
              height="57.3169"
              rx="18.9484"
              fill="white"
            />
            <path
              d="M120.763 49.2383V32.5609H123.145V47.1655H132.175V49.2383H120.763ZM132.383 49.2383L139.936 32.5609H142.294L149.871 49.2383H147.369L140.627 33.8951H141.58L134.837 49.2383H132.383ZM135.6 45.0689L136.243 43.163H145.63L146.321 45.0689H135.6ZM152.358 49.2383V32.5609H154.312L165.343 46.2602H164.319V32.5609H166.701V49.2383H164.747L153.716 35.539H154.741V49.2383H152.358ZM176.471 49.2383V47.6182L183.261 41.0664C183.865 40.4946 184.309 39.9943 184.595 39.5654C184.897 39.1207 185.096 38.7157 185.191 38.3503C185.302 37.9691 185.358 37.6038 185.358 37.2544C185.358 36.3967 185.056 35.7217 184.452 35.2293C183.849 34.7369 182.967 34.4907 181.808 34.4907C180.918 34.4907 180.116 34.6416 179.402 34.9434C178.687 35.2293 178.067 35.682 177.543 36.3014L175.923 34.8957C176.558 34.0857 177.408 33.4662 178.472 33.0374C179.552 32.5927 180.736 32.3703 182.022 32.3703C183.182 32.3703 184.19 32.5609 185.048 32.9421C185.906 33.3074 186.565 33.8395 187.025 34.5384C187.502 35.2372 187.74 36.0632 187.74 37.0161C187.74 37.5562 187.669 38.0883 187.526 38.6124C187.383 39.1366 187.113 39.6925 186.716 40.2801C186.319 40.8678 185.747 41.527 185 42.2576L178.949 48.0947L178.377 47.1655H188.455V49.2383H176.471Z"
              fill="#253331"
            />
            <ellipse
              cx="208.954"
              cy="41.0028"
              rx="8.28992"
              ry="8.28992"
              fill="#EC0000"
            />
            <ellipse
              cx="208.954"
              cy="41.0028"
              rx="8.28992"
              ry="8.28992"
              fill="#84E88E"
            />
          </g>
        </g>
      )}

      <defs>
        <filter
          id="filter1_d_3877_17956"
          x="69"
          y="0.580078"
          width="155.008"
          height="94.8447"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_17956"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_17956"
            result="shape"
          />
        </filter>
        <filter
          id="filter0_d_5810_18226"
          x="40.8395"
          y="50"
          width="432.548"
          height="166.017"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="8.44" dy="16.87" />
          <feGaussianBlur stdDeviation="8.435" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.494118 0 0 0 0 0.509804 0 0 0 0 0.505882 0 0 0 0.39 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5810_18226"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5810_18226"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5810_18226"
          x1="453.577"
          y1="119.5"
          x2="60.5773"
          y2="118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E6E70" />
          <stop offset="0.461144" stopColor="#5E5E61" />
          <stop offset="0.520833" stopColor="#525254" />
          <stop offset="0.614763" stopColor="#5E5E5E" />
          <stop offset="1" stopColor="#545456" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5810_18226"
          x1="453.308"
          y1="118.5"
          x2="60.3076"
          y2="117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0A0A0" />
          <stop offset="0.461144" stopColor="#999898" />
          <stop offset="0.520833" stopColor="#525254" />
          <stop offset="0.614763" stopColor="#5E5E5E" />
          <stop offset="1" stopColor="#545456" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default VenoenDeviceBack;
