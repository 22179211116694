import NavContainer from "../../components/NavContainer";
import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import AllServices from "../../components/AllServices";
import MyServices from "../../components/MyServices";
import history from "../../utils/history";
import { useSearchParams } from "react-router-dom";
import { routes } from "../../App";
import { TabOptionButton } from "../../components/NavContainer/NavContainer";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

const getTabOptions = (all: boolean = true) => [
  {
    label: "All Services",
    isActive: all,
  },
  {
    label: "My Services",
    isActive: !all,
  },
];

const Services = () => {
  const { currentDevice, currentAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const tabOptions = getTabOptions(!tab || tab === "All Services");
  const [tabs, setTabs] = useState(tabOptions);
  const [showLoading, setShowLoading] = useState(true);
  const handleSelectTab = (idx: number) => {
    const changedTabs = tabs.map((val, i) => ({
      ...val,
      isActive: i === idx,
    }));

    setTabs(changedTabs);
    history.push({
      pathname: routes.services.path,
      search: `?tab=${tabOptions[idx].label}`,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 2000);
  }, []);

  return (
    <NavContainer
      title="Services"
      options={tabs}
      eventHandler={handleSelectTab}
    >
      <Box
        display={{
          md: "none",
          xs: "flex",
        }}
        gap={1.5}
        alignItems="center"
        justifyContent={"space-between"}
        mb="16px"
        width="100%"
      >
        {tabs.map((opt: any, idx) => (
          <TabOptionButton
            key={idx}
            idx={idx}
            option={opt}
            eventHandler={handleSelectTab}
          />
        ))}
      </Box>
      <Card
        sx={{
          height: "100%",
          borderRadius: "8px",
          boxShadow: `0 4px 25px ${colors.cardwrapper.shadow}`,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          padding: {
            sm: "20px 25px 20px 25px",
            xs: "10px",
          },
        }}
      >
        {currentAccount && currentDevice ? (
          <>
            {tabs.at(0)?.isActive ? (
              <AllServices currentAccount={currentAccount} />
            ) : (
              <MyServices
                currentAccount={currentAccount}
                currentDevice={currentDevice}
              />
            )}
          </>
        ) : showLoading ? (
          <Backdrop open={true}>
            <CircularProgress data-testid="progressSpinner" color="inherit" />
          </Backdrop>
        ) : (
          !currentDevice && (
            <Typography>
              It appears that you don't have a device on your account
            </Typography>
          )
        )}
      </Card>
    </NavContainer>
  );
};

export default Services;
