import { Breadcrumbs, Divider, Stack, Typography } from "@mui/material";
import { theme } from "../../theme";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "../Link";
import useGetThemePath from "../../hooks/useGetThemePath";
import { dividerStyle, getIconStyle } from "./styles";
import getREMFromPX from "../../utils/getREMFromPX";
import React from "react";

const StyledBreadCrumbs = ({
  breadCrumbs,
  noDivider,
}: {
  breadCrumbs: {
    name: string;
    pathname: string;
    onClick?: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  }[];
  noDivider?: boolean;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  return (
    <Stack
      spacing={theme.spacing * 2}
      padding={`${getREMFromPX(theme.spacing * 4)} ${getREMFromPX(
        theme.spacing * 8
      )}`}
    >
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            data-testid="navigate_icon"
            style={getIconStyle(themePath)}
            fill={colors.icons.default.fillColor}
          />
        }
        aria-label="breadcrumb"
      >
        {breadCrumbs.map((bc, index) => {
          const last = index === breadCrumbs.length - 1;
          return last ? (
            <Typography
              color={theme[themePath].colors.textPrimary.primary}
              fontWeight={theme.fonts.weights.bolder}
              key={bc.name}
            >
              {bc.name}
            </Typography>
          ) : (
            <Link
              onClick={bc.onClick}
              size="large"
              to={bc.pathname}
              key={bc.name}
            >
              {bc.name}
            </Link>
          );
        })}
      </Breadcrumbs>
      {!noDivider && <Divider style={dividerStyle} data-testid="bc_divider" />}
    </Stack>
  );
};
export default StyledBreadCrumbs;
