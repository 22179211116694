import { Stack, Divider, Typography } from "@mui/material";
import useGetThemePath from "../../../../../../hooks/useGetThemePath";
import { theme } from "../../../../../../theme";
import getREMFromPX from "../../../../../../utils/getREMFromPX";
import { dividerStyles } from "./Styles";
import * as Styles from "../CommonStyles";

const Headers = () => {
  const themePath = useGetThemePath();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={getREMFromPX(theme.spacing * 4)}
      border={`1px solid ${theme[themePath].colors.border.primary}`}
      borderLeft="0"
      borderRight="0"
      padding={`${getREMFromPX(theme.spacing * 2)} 0`}
    >
      <Typography {...Styles.firstCol} />
      <Divider style={dividerStyles} flexItem orientation="vertical" />
      <Typography
        fontSize={getREMFromPX(theme.spacing * 3)}
        color={theme[themePath].colors.textPrimary.secondary}
        {...Styles.portCol}
      >
        Port
      </Typography>
      <Divider style={dividerStyles} flexItem orientation="vertical" />
      <Typography
        fontSize={getREMFromPX(theme.spacing * 3)}
        color={theme[themePath].colors.textPrimary.secondary}
        {...Styles.activeServiceCol}
      >
        Active Service in Port
      </Typography>
      <Divider style={dividerStyles} flexItem orientation="vertical" />
      <Typography
        fontSize={getREMFromPX(theme.spacing * 3)}
        color={theme[themePath].colors.textPrimary.secondary}
        {...Styles.linkStateCol}
      >
        Link State
      </Typography>
    </Stack>
  );
};

export default Headers;
