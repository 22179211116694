import { useState } from "react";

type UsePaginationProps<T> = {
  data: T[];
  limit: number;
};

export default function usePagination<T>({
  data = [],
  limit,
}: UsePaginationProps<T>) {
  const [page, setPage] = useState(1);

  const lastPage = Math.ceil(data.length / limit);
  const startIndex = (page - 1) * limit;
  const endIndex = page * limit;
  const paginatedResults = data.slice(startIndex, endIndex);

  return {
    lastPage,
    page,
    paginatedResults,
    setPage,
  };
}
