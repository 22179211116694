import { Typography, TypographyProps } from "@mui/material";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import useGetThemePath from "../../hooks/useGetThemePath";

export const ErrorMessage = ({ children, ...props }: TypographyProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  return (
    <Typography
      sx={{
        fontFamily: "Inter",
        fontSize: getREMFromPX(11),
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        marginTop: "8px",
        color: colors.notification.error.primary,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default ErrorMessage;
