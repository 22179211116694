import { Box, Popper as MuiPopper } from "@mui/material";
import { useRef, useState } from "react";

import { styled } from "@mui/material/styles";

const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== "arrow",
})(({ theme }) => ({
  zIndex: 999999,
  "& > div": {
    position: "relative",
  },
  '&[data-popper-placement*="bottom"]': {
    "& > div": {
      marginTop: 2,
    },
    "& .MuiPopper-arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme?.palette.background.paper} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    "& > div": {
      marginBottom: 10,
    },
    "& .MuiPopper-arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme?.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    "& > div": {
      marginLeft: 2,
    },
    "& .MuiPopper-arrow": {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme?.palette.background.paper} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    "& > div": {
      marginRight: 2,
    },
    "& .MuiPopper-arrow": {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme?.palette.background.paper}`,
      },
    },
  },
}));

const Arrow = styled("div")({
  position: "absolute",
  fontSize: 12,
  width: "3em",
  height: "3em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: 5,
    height: 5,
    borderStyle: "solid",
  },
});

type HoverPopoverProps = {
  triggerComponent: React.ReactNode;
  popoverContent: JSX.Element;
};

export default function HoverPopover({
  triggerComponent,
  popoverContent,
}: HoverPopoverProps) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [arrowRef, setArrowRef] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const id = open ? "hover-popover" : undefined;

  return (
    <Box onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      <Box
        ref={anchorRef}
        aria-describedby={id}
        component="button"
        sx={{
          border: "none",
        }}
      >
        {triggerComponent}
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        placement="top-start"
        disablePortal={true}
        modifiers={[
          {
            name: "flip",
            enabled: false,
            options: {
              altBoundary: false,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <Box component="div">
          <Arrow ref={setArrowRef as any} className="MuiPopper-arrow" />
          {popoverContent}
        </Box>
      </Popper>
    </Box>
  );
}
