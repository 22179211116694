import { useState } from "react";
import { Box, ClickAwayListener, SxProps, Theme } from "@mui/material";

import ListSelect from "../../components/ListSelect/ListSelect";
import SelectButton from "../../components/SelectButton/SelectButton";
import FiltersMenu, { menuOptions } from "./FiltersMenu";
import SearchIcon from "@mui/icons-material/Search";
import { SortFilterLabel } from "./interfaces";
import { theme } from "../../theme";
import { getThemePath } from "../../hooks/useGetThemePath";
import { StyledFilterInput } from "./TableFilters";

interface FiltersMobileProps {
  options: any[];
  handleChangeActiveTab: (v: number) => void;
  setSortFilter: (v: SortFilterLabel | null) => void;
  sortFilter: SortFilterLabel | null;
  handleInputChange?: (val: string) => void;
  handleChangeTypeOfView: (idx: number) => void;
  isMyTabsActive: boolean;
  tabOptions: any[];
}

const themePath = getThemePath();
const colors = theme[themePath].colors;

const FiltersMobile = ({
  options,
  handleChangeActiveTab,
  handleChangeTypeOfView,
  sortFilter,
  setSortFilter,
  tabOptions,
  handleInputChange = () => {},
  isMyTabsActive,
}: FiltersMobileProps) => {
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const [sortByOptions, setSortByOptions] = useState(menuOptions);
  const [accessCode, setAccessCode] = useState("");

  return (
    <>
      {isMyTabsActive && (
        <Box
          display="flex"
          flexDirection={"column"}
          gap={1}
          marginBottom="16px"
        >
          <ListSelect
            options={tabOptions}
            handleChangeActiveTab={handleChangeActiveTab}
          />

          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 1,
                right: 8,
                color: "#c4c4c4",
                borderLeft: "1px solid #c4c4c4",
                display: "flex",
                alignItems: "center",
                height: 30,
                padding: 0.4,
              }}
            >
              <SearchIcon
                sx={{
                  color: "#c4c4c4",
                }}
                fill={colors.icons.default.fillColor}
              />
            </Box>
            <StyledFilterInput
              onChange={(ev) => handleInputChange(ev.target.value)}
              data-cy="services-search-input"
              placeholder="Search"
              sx={{
                width: "100%",
              }}
            />
          </Box>

          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 1,
                right: 8,
                color: "#c4c4c4",
                borderLeft: "1px solid #c4c4c4",
                display: "flex",
                alignItems: "center",
                height: 30,
                padding: 0.4,
              }}
            >
              <SearchIcon
                sx={{
                  color: "#c4c4c4",
                }}
                fill={colors.icons.default.fillColor}
              />
            </Box>
            <StyledFilterInput
              placeholder="Private Code"
              id="privateAccess"
              onChange={(e) => setAccessCode(e.target.value)}
              value={accessCode}
              data-cy="services-input-private-code"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <ClickAwayListener onClickAway={() => setShowFiltersMenu(false)}>
              <Box height="30px" width="133px">
                <SelectButton
                  content="Sort"
                  mode="default"
                  onClick={() => setShowFiltersMenu(!showFiltersMenu)}
                  dataCy="services-select-sortby"
                />
                {!!showFiltersMenu && (
                  <FiltersMenu
                    setSortFilter={(arg) => {
                      setShowFiltersMenu(false);
                      setSortFilter(arg);
                    }}
                    sortFilter={sortFilter}
                    sortByOptions={sortByOptions}
                    setSortByOptions={(opts) => setSortByOptions(opts)}
                  />
                )}
              </Box>
            </ClickAwayListener>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #c4c4c4",
                borderRadius: 20,
                height: 32,
              }}
            >
              {options.map(({ active, label }, idx) => {
                let optionalStyles: SxProps<Theme> = {};
                if (idx === 0) {
                  optionalStyles = {
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                  };
                }

                if (idx === options.length - 1) {
                  optionalStyles = {
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                  };
                }
                return (
                  <Box
                    sx={{
                      backgroundColor: active ? colors.form.label.primary : "",
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Inter",
                      alignItems: "center",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: !active
                          ? colors.activeElementBackground
                          : undefined,
                      },
                      height: "100%",
                      width: "90px",
                      fontSize: "0.875rem",
                      ...optionalStyles,
                    }}
                    key={idx}
                    onClick={() => handleChangeTypeOfView(idx)}
                  >
                    {label}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FiltersMobile;
