import React, { useContext, useState, useMemo, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import Label from "../Label";
import { useForm } from "react-hook-form";
import Input from "../Input";
import { endpoints } from "../../api/constants/endpoints";
import { useUpdateAccount } from "../../hooks/useUpdateAccount";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import { useGetBillingStatus } from "../../hooks/useGetBillingStatus";
import sendErrorToast from "../../utils/sendErrorToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import fetcher from "../../utils/fetchWrapper";

export interface AchInfo {
  stepName?: string;
  routingNum?: string;
  accountNum?: string;
  accountType?: string;
  accountHolderType?: string;
  amount1?: string;
  amount2?: string;
}

interface WarrantyInfo {
  paymentMethod: string;
  maintenanceSel: boolean;
  insuranceSel: boolean;
}

interface AchProps {
  children?: React.ReactElement[];
  onSubmit: Function;
  onSuccess?: Function;
  setIsValid: (isValid: boolean) => void;
  setIsLoading?: (isLoading: boolean) => void;
  previousValues: AchInfo;
  disableForm?: boolean;
  accountId?: string;
  warranty?: WarrantyInfo;
}

const Microdeposit = ({
  previousValues,
  children,
  onSubmit,
  setIsValid,
  onSuccess,
  disableForm,
  warranty,
  ...props
}: AchProps) => {
  const { userInfo, setUserInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const [errorMessage, setErrorMessage] = useState("");

  const userId = userInfo?.data?.user?.id ?? "";
  const accountId = useMemo(
    () => props.accountId ?? userInfo?.data?.user?.Accounts?.[0]?.id ?? "",
    [userInfo, props.accountId]
  );

  const [verificationRequired, setVerificationRequired] = useState(false);

  const { mutateAsync: updateUser } = useUpdateUser(userId, {
    onError: () => sendErrorToast("There was an error activating the user."),
  });

  const { mutateAsync: updateAccount } = useUpdateAccount(accountId, {
    onError: () => sendErrorToast("There was an error activating the account."),
  });

  const { data: billing } = useGetBillingStatus(accountId);

  useEffect(() => {
    if (billing?.data?.billing?.billing_status.ach_verification_required) {
      setVerificationRequired(true);
    }
  }, [billing]);

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<AchInfo>({
    mode: "onChange",
    defaultValues: {
      ...previousValues,
    },
  });

  const amount1 = watch("amount1");
  const amount2 = watch("amount2");

  const onPaymentSuccess = async () => {
    // User and Account need to be set active because payment was accepted
    const userData = await updateUser({
      status: "ACTIVE",
    });
    await updateAccount({
      status: "ACTIVE",
    });

    setUserInfo({
      ...userInfo,
      ...userData,
      data: {
        ...userInfo?.data,
        ...userData?.data,
        user: {
          ...userInfo?.data?.user,
          ...userData?.data?.user,
        },
      },
    });

    // sendSuccessToast("Payment Method Verified");
    onSuccess?.();
    onSubmit({});
  };

  const submitVerification = async () => {
    props.setIsLoading?.(true);
    const body = {
      amounts: [amount1, amount2],
    };

    const res = await fetcher(`${endpoints.account}/${accountId}/achverify`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (response?.data?.account?.subscription_created) {
      onPaymentSuccess();
    } else {
      sendErrorToast(response?.message);
      setErrorMessage(
        "Sorry, the below information is incorrect. To verify your information, we have made two small deposits into your bank account. Please enter the amounts of these deposits in the order they were made."
      );
    }

    props.setIsLoading?.(false);
  };

  const onSubmitHandler = async () => {
    submitVerification();
  };

  const disable = false;

  // This will notify parent component if this form is valid or not.
  setIsValid(isValid && !disable);
  return (
    <Stack>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack
          spacing={getREMFromPX(theme.spacing * 3)}
          mt={getREMFromPX(theme.spacing * 3)}
        >
          <Typography fontSize="24px" fontWeight="600" mb="15px">
            Verify Your Payment
          </Typography>
          <Typography
            color={errorMessage ? "red" : "#C80000"}
            fontSize={getREMFromPX(16)}
            fontWeight="600"
          >
            {errorMessage
              ? errorMessage
              : "Please enter the two small deposits made to your bank account to verify your information."}
          </Typography>
          <Label style={{ marginTop: "30px" }} htmlFor="amount1">
            First Microdeposit Amount
          </Label>
          <Input
            data-testid="amount1"
            placeholder=""
            {...register("amount1", {
              required: verificationRequired,
              pattern: { value: /^\d{1,2}$/, message: "Invalid amount" },
            })}
            error={!!errors.amount1 && !!amount1?.length}
          />
          <Label htmlFor="amount2">Second Microdeposit Amount</Label>
          <Input
            data-testid="amount2"
            placeholder=""
            {...register("amount2", {
              required: verificationRequired,
              pattern: { value: /^\d{1,2}$/, message: "invalid amount" },
            })}
            error={!!errors.amount2 && !!amount2?.length}
          />
        </Stack>
        {children}
      </form>
    </Stack>
  );
};

export default Microdeposit;
