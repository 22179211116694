import { Box, Typography, TableRow, TableCell } from "@mui/material";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Subscription } from "../../../../api/interfaces/Subscription";
import useViewport from "../../../../hooks/useViewport";

const MyDevicePortsRow = ({ port }: { port: any }) => {
  const { isMobile } = useViewport();

  const portActiveColor =
    port?.data?.portinterface.live?.link_state === "up" ? "#84e88e" : "#d9d9d9";
  const portName = port?.display_name;

  if (Object.keys(port).length < 1) {
    return null;
  }

  const cyRow = `ports-table-row-${port.id}`;

  if (port?.Subscriptions?.length === 0 || port?.Subscriptions === undefined) {
    return (
      <TableRow data-cy={cyRow}>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "70px",
              background: "#fff",
              border: "1px solid #EFEFEF",
              borderRadius: "4px",
              padding: "4px 0px",
            }}
          >
            {portName}
          </Box>
        </TableCell>
        {isMobile ? (
          <TableCell />
        ) : (
          <>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </>
        )}
      </TableRow>
    );
  }
  const linkState = port?.data?.portinterface.live?.link_state;
  const badgeStyles =
    linkState === "up"
      ? {
          color: "#027A48",
          background: "#ECFDF3",
        }
      : {
          color: "#ff1a1a",
          background: "#ffd8d8",
        };

  return (
    <TableRow data-cy={cyRow}>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "70px",
            background: "#fff",
            border: "1px solid #EFEFEF",
            borderRadius: "4px",
            padding: "4px 0px",
          }}
        >
          {portName}
          <Box
            sx={{
              color: portActiveColor,
              background: portActiveColor,
              width: "10px",
              height: "10px",
              borderRadius: "10px",
              border: "1px solid " + portActiveColor,
              marginLeft: "4px",
            }}
          >
            &nbsp;
          </Box>
        </Box>
      </TableCell>

      {isMobile ? (
        <>
          <TableCell>
            <Typography fontSize={getREMFromPX(16)}>
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Provider:{" "}
              </Typography>
              {port?.Subscriptions?.map((Subscription: Subscription) => (
                <span key={Subscription.id}>
                  {Subscription?.Serviceplan?.Service?.Provider?.name || ""}
                </span>
              ))}
            </Typography>
            <Typography fontSize={getREMFromPX(16)}>
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Plan:{" "}
              </Typography>
              {port?.Subscriptions?.map((Subscription: any) => (
                <span key={Subscription.id}>
                  {Subscription?.Serviceplan?.name || ""}
                </span>
              ))}
            </Typography>
            <Typography fontSize={getREMFromPX(16)}>
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Type:{" "}
              </Typography>
              {port?.Subscriptions?.map((Subscription: any) => (
                <span key={Subscription.id}>
                  {Subscription?.Serviceplan?.Service?.Servicetype.name || ""}
                </span>
              ))}
            </Typography>
            <Typography
              sx={{
                padding: "2px 8px 2px 8px",
                borderRadius: "20px",
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
                marginTop: "4px",
                ...badgeStyles,
              }}
              fontSize={getREMFromPX(10)}
            >
              {linkState === "up" ? "Connected" : "Disconnected"}
            </Typography>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>
            <Typography fontSize={getREMFromPX(16)}>
              {port?.Subscriptions?.map((Subscription: Subscription) => (
                <div key={Subscription.id}>
                  {Subscription?.Serviceplan?.Service?.Provider?.name || ""}
                </div>
              ))}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={getREMFromPX(16)}>
              {port?.Subscriptions?.map((Subscription: any) => (
                <div key={Subscription.id}>
                  {Subscription?.Serviceplan?.name || ""}
                </div>
              ))}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={getREMFromPX(16)}>
              {port?.Subscriptions?.map((Subscription: any) => (
                <div key={Subscription.id}>
                  {Subscription?.Serviceplan?.Service?.Servicetype.name || ""}
                </div>
              ))}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              sx={{
                padding: "2px 8px 2px 8px",
                borderRadius: "20px",
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
                ...badgeStyles,
              }}
              fontSize={getREMFromPX(10)}
            >
              {linkState === "up" ? "Connected" : "Disconnected"}
            </Typography>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default MyDevicePortsRow;
