import {
  Backdrop,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useEffect, useContext, useMemo, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../../components/Button";
import ErrorMessage from "../../../../components/ErrorMessage";
import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import { useValidateUser } from "../../../../hooks/useValidateUser";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type } from "../../AccountSettings";
import {
  headerStyle,
  dividerStyle,
  buttonStyle,
  getSubHeaderStyle,
  iconStyle,
  inputAdornmentStyle,
  iconStyleAdornment,
  iconStylePad,
} from "./Styles";
import sendErrorToast from "../../../../utils/sendErrorToast";
import isValidEmail from "../../../../utils/isValidEmail";
import isValidPassword from "../../../../utils/isValidPassword";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";

interface Step1Props {
  changeStep: (args: Step1Type) => void;
  previousValues: {
    step1: Step1Type;
  };
  goBackOneStep: () => void;
}

const Step1 = ({ changeStep, previousValues, goBackOneStep }: Step1Props) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const [showPassword, setShowPassword] = useState(false);
  const {
    watch,
    register,
    reset,
    setError,
    formState: { isValid, errors },
    getValues,
  } = useForm<Step1Type>({
    mode: "onChange",
    defaultValues: useMemo(
      () => ({
        ...previousValues.step1,
      }),
      [previousValues]
    ),
  });

  useEffect(() => {
    reset({ ...previousValues.step1 });
  }, [previousValues, reset]);

  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const stepName = watch("stepName")?.trim();
  const password = watch("password").trim();
  const confirmPassword = watch("confirmPassword").trim();
  const userName = watch("userName").trim();

  const {
    refetch: validateUserName,
    data: userIsValid,
    isLoading: userNameLoading,
  } = useValidateUser(userName, {
    cacheTime: 0,
    onError: () =>
      sendErrorToast(
        "There was a problem validating the Username, please try again"
      ),
    enabled: false,
  });

  const disableButton =
    !isValid || !!Object.values(errors).length || userNameLoading;
  const disableFields = userNameLoading;

  const nextStep = useCallback(() => {
    const selectedOptions = { stepName, userName, password, confirmPassword };
    changeStep(selectedOptions);
  }, [userName, password, confirmPassword, changeStep, stepName]);

  useEffect(() => {
    if (userIsValid?.data.user.available === false && !userInfo) {
      setError("userName", {
        message: "That username is already taken. Please choose a new one",
      });
    } else if (userIsValid?.data.user.available === true) {
      nextStep();
    }
  }, [nextStep, userInfo, changeStep, setError, userIsValid]);

  const onSubmit = () => {
    if (userName === userInfo?.data?.user?.username) {
      nextStep();
    } else {
      validateUserName();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordsMatch = !confirmPassword || confirmPassword === password;

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={getREMFromPX(theme.spacing * 2)}>
        <Typography sx={headerStyle} component="h1">
          {userInfo ? "Update" : "Create"} Account
        </Typography>
      </Stack>
      <Typography
        sx={getSubHeaderStyle(themePath)}
        mt={getREMFromPX(theme.spacing * 8)}
      >
        {userInfo ? "Update" : "Choose"} your Username and Password
      </Typography>
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        mt={getREMFromPX(theme.spacing * 6)}
      >
        <Label htmlFor="userName">Username</Label>
        <Input
          placeholder="Eg: example@example.com"
          autoComplete="username"
          disabled={disableFields}
          error={!!errors.userName && !!userName.length}
          id="userName"
          {...register("userName", {
            required: true,
            validate: (value) => isValidEmail(value),
          })}
        />
      </Stack>
      <Typography
        mt={getREMFromPX(theme.spacing * 3)}
        fontSize={getREMFromPX(theme.spacing * 2.5)}
      >
        Username must be an email and less than 80 characters.
      </Typography>
      {errors.userName && userName && (
        <ErrorMessage fontSize={getREMFromPX(theme.spacing * 2.5)}>
          {errors.userName?.message ||
            "Invalid Username. Please enter a new one."}
        </ErrorMessage>
      )}
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        mt={getREMFromPX(theme.spacing * 4)}
      >
        <Label htmlFor="password">Password</Label>
        <Input
          placeholder="Eg: Example1234!"
          autoComplete="new-password"
          disabled={disableFields}
          error={(!!errors.password && !!password.length) || !passwordsMatch}
          softError={!!errors.password || !password.length || !passwordsMatch}
          type={showPassword ? "text" : "password"}
          id="password"
          style={inputAdornmentStyle}
          endAdornment={
            <InputAdornment position="end" style={iconStyleAdornment}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                style={iconStylePad}
                data-cy="togglePassVisibility"
              >
                {showPassword ? (
                  <FontAwesomeIcon
                    icon="eye-slash"
                    style={iconStyle}
                    fill={colors.icons.default.fillColor}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="eye"
                    style={iconStyle}
                    fill={colors.icons.default.fillColor}
                  />
                )}
              </IconButton>
            </InputAdornment>
          }
          {...register("password", {
            required: true,
            validate: (value) => isValidPassword(value),
          })}
        />
      </Stack>
      <Typography
        mt={getREMFromPX(theme.spacing * 3)}
        fontSize={getREMFromPX(theme.spacing * 2.5)}
      >
        Password must contain at least 8 characters and less than 16, at least 1
        uppercase and 1 lowercase letter, 1 number and 1 special character.
      </Typography>
      {errors.password && password && (
        <ErrorMessage fontSize={getREMFromPX(theme.spacing * 2.5)}>
          Invalid Password. Please enter a new one.
        </ErrorMessage>
      )}
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        mt={getREMFromPX(theme.spacing * 2)}
      >
        <Label htmlFor="confirmPassword">Confirm Password</Label>
        <Input
          autoComplete="new-password"
          disabled={disableFields}
          type={showPassword ? "text" : "password"}
          placeholder="Confirm your new password"
          error={
            (!!errors.confirmPassword && !!confirmPassword.length) ||
            !passwordsMatch
          }
          softError={
            !!errors.confirmPassword ||
            !confirmPassword.length ||
            !passwordsMatch
          }
          id="confirmPassword"
          {...register("confirmPassword", {
            required: true,
            validate: (value) => value === getValues().password,
          })}
        />
      </Stack>
      <Divider sx={dividerStyle} />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        <Button
          startIcon={
            <FontAwesomeIcon
              icon="angle-left"
              style={iconStyle}
              fill={colors.icons.default.fillColor}
            />
          }
          onClick={goBackOneStep}
          variant="text"
          text="Back"
          size="medium"
        />
        <Button
          data-testid="submit_button"
          style={buttonStyle}
          onClick={onSubmit}
          text="Continue"
          type="submit"
          size="medium"
          disabled={disableButton}
        />
      </Stack>
      <Backdrop open={userNameLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </form>
  );
};
export default Step1;
