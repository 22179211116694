import { useState, useEffect, useContext } from "react";
import {
  BillingContext,
  BillingType,
  errorCodes,
  ErrorType,
} from "../components/BillingProvider";

const useSetPaymentErrorCode = (form: any, error?: string) => {
  const { errors } = useContext(BillingContext) as BillingType;
  const [errorMessage, setErrorMessage] = useState("");

  const cardEndingNumber = form.getValues("cardNumber")?.split(" ")?.at(3);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  const commonAchMessage =
    "There was an error processing your payment. Sometimes this happens. You can continue the account verification process by using a credit card method. You can always go back and change your payment method later.";

  useEffect(() => {
    const setError = (error: ErrorType) => {
      switch (error?.code) {
        // ACH Errors
        case errorCodes.ACCOUNT_NOT_FOUND:
          form.setError("accountNum", {
            message:
              "We couldn’t recognize this account. Please enter a valid account number.",
          });
          setErrorMessage(commonAchMessage);
          break;
        case errorCodes.CLOSED_ACCOUNT:
          form.setError("accountNum", {
            message:
              "This account is closed. Please enter a valid account number.",
          });
          setErrorMessage(commonAchMessage);
          break;
        case errorCodes.DEBIT_NOT_AUTHORIZED:
          setErrorMessage(
            "This account is not authorized for ACH transactions. Please contact your bank or utilize credit card payments in the meantime."
          );
          break;
        case errorCodes.INSUFFICIENT_FUNDS:
          setErrorMessage(
            "There was an error processing your payment due to insufficient funds. You can continue the account verification process by using a credit card method. You can always go back and change your payment method later."
          );
          break;
        case errorCodes.MICRODEPOSIT_FAILED:
        case errorCodes.INVALID_CURRENCY:
          setErrorMessage(commonAchMessage);
          break;

        // Credit Card Errors
        case errorCodes.CC_EXPIRED:
          form.setError("expirationDate", {
            message:
              "This date is invalid. Verify expiration date and submit again.",
          });
          break;
        case errorCodes.CC_INVALID:
          form.setError("cardNumber", {
            message: "This does not appear to be a valid credit card.",
          });
          break;
        case errorCodes.INCORRECT_BILLING_CODE:
          form.setError("zip", {
            message:
              "This zipcode is invalid for this card. Verify zipcode and submit again.",
          });
          break;
        case errorCodes.CVC_INVALID:
          form.setError("cvc", { message: "This security code is invalid." });
          break;
        case errorCodes.CC_DECLINED:
          setErrorMessage(
            "Your card was declined. Please contact your bank, or enter a different card."
          );
          break;
        case errorCodes.CARD_DECLINED:
          setErrorMessage(
            "Your card was declined. Please contact your bank, or enter a different card."
          );
          break;
        default:
          console.log(error);
          return null;
      }
    };

    errors?.forEach((err) => setError(err));
  }, [form, errors]);

  if (cardEndingNumber) {
    return `There was an issue with your payment ending in [${cardEndingNumber}]. Please update your payment method and confirm. Please note that we are only updating your payment information. Your card will be automatically charged as scheduled.`;
  }

  return errorMessage;
};

export default useSetPaymentErrorCode;
