import React from "react";
import { CircularProgress } from "@mui/material";
import { useGetEstimatedFees } from "../../../../hooks/useGetEstimatedFees";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { Typography } from "@mui/material";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import Button from "../../../../components/Button";
import useViewport from "../../../../hooks/useViewport";
import useGetThemePath from "../../../../hooks/useGetThemePath";

const MyPlanIndex = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { currentAccount } = React.useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { isMobile } = useViewport(1220);

  const { data: estimateData, isLoading: isLoadingEstimate } =
    useGetEstimatedFees(currentAccount?.id, {
      refetchOnWindowFocus: false,
    });

  if (isLoadingEstimate || !estimateData) {
    return (
      <CircularProgress
        sx={{
          margin: "auto",
        }}
      />
    );
  }

  const subscriptions = estimateData?.data?.subscription;

  if (subscriptions && subscriptions.length > 0) {
    return (
      <>
        <Typography
          sx={{
            fontSize: getREMFromPX(36),
            fontWeight: 600,
            lineHeight: getREMFromPX(40),
            fontFamily: "Inter",
            color: colors.textPrimary.cardText,
            marginTop: "1.5rem",
            maxWidth: isMobile ? undefined : "450px",
          }}
        >
          You&apos;re currently subscribed to {subscriptions?.length} services
        </Typography>
        <Typography
          sx={{
            fontSize: getREMFromPX(16),
            fontFamily: "Inter",
            color: colors.textPrimary.cardText,
            marginTop: "24px",
          }}
        >
          Note: Subscriptions accessed through our platform are charged
          separately by the respective providers.
        </Typography>
        <Button
          text="My Services"
          mode="default"
          sx={{
            maxWidth: "300px",
            marginTop: "32px",
            border: "1px solid transparent",
          }}
          href="/services?tab=My%20Services"
        />
      </>
    );
  }

  return (
    <>
      <Typography
        sx={{
          fontSize: getREMFromPX(36),
          fontWeight: 600,
          lineHeight: getREMFromPX(40),
          fontFamily: "Inter",
          color: colors.textPrimary.cardText,
          maxWidth: isMobile ? undefined : "450px",
          marginTop: "3rem",
        }}
      >
        You&apos;re not currently subscribed to any services
      </Typography>
      <Button
        text="View Services"
        mode="default"
        href="/services"
        sx={{
          maxWidth: "300px",
          marginTop: "3.5rem",
          border: "1px solid transparent",
        }}
      />
    </>
  );
};

export default MyPlanIndex;
