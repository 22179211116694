import _ from "lodash";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Port } from "../../api/interfaces/Port";
import MyServicesRow from "./MyServicesRow";
import { Account } from "../../api/interfaces/Account";
import useViewport from "../../hooks/useViewport";

type MyServicesTableProps = {
  isUserRatingEnabled: boolean;
  currentSubscriptions: Account[];
};

export default function MyServicesTable({
  currentSubscriptions,
  isUserRatingEnabled,
}: MyServicesTableProps) {
  const { isMobile } = useViewport();

  function renderHeader() {
    if (isMobile) {
      return (
        <TableHead>
          <TableRow>
            <TableCell>Plan</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
      );
    }

    return (
      <TableHead>
        <TableRow>
          <TableCell>Port</TableCell>
          <TableCell align="center">Provider</TableCell>
          <TableCell>Plan</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Price</TableCell>
          {isUserRatingEnabled ? <TableCell>My Review</TableCell> : null}

          <TableCell
            sx={{
              paddingLeft: 6,
            }}
          >
            Action
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
  return (
    <Box
      mt={{
        md: 4,
        xs: 0,
      }}
      mb={1}
    >
      <Table>
        {renderHeader()}
        <TableBody>
          {!_.isEmpty(currentSubscriptions)
            ? currentSubscriptions?.map((sub: any, index: number) => {
                return (
                  <MyServicesRow
                    deviceId={sub?.Portinterface?.device_id}
                    port={{
                      ...(sub.Portinterface as Port),
                      providerId: sub?.Serviceplan?.Service?.provider_id,
                      servicePlan: sub?.Serviceplan,
                    }}
                    unsubscribeId={sub.id}
                    key={sub.id}
                    currentIndex={index}
                    dataCy={`my-services-row-${index}`}
                    isUserRatingEnabled={isUserRatingEnabled}
                  />
                );
              })
            : null}
        </TableBody>
      </Table>
    </Box>
  );
}
