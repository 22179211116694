import NavContainer from "../../components/NavContainer/NavContainer";
import { Box } from "@mui/material";
import Notifications from "./Notifications";
import UserDetails from "./UserDetails/UserDetails";
import SecurityDetails from "./SecurityDetails";
import PaymentsInfoCard from "../Billing/components/PaymentsInfoCard";
import DeviceInfo from "./DeviceInfo";
import { useContext } from "react";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { useGetConfig } from "../../hooks/useGetConfig";

const UserProfile = () => {
  const { currentDevice } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  return (
    <NavContainer title="Account Settings">
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: "flex-start",
          gap: {
            md: 4,
            xs: 1,
          },
        }}
      >
        <Box
          display="flex"
          flex={1}
          gap={{
            md: 4,
            xs: 1,
          }}
          flexDirection="column"
          width="100%"
        >
          <UserDetails />
          <SecurityDetails />
        </Box>
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          width="100%"
          gap={{
            md: 4,
            xs: 1,
          }}
        >
          <Notifications />
          {currentDevice ? <DeviceInfo device={currentDevice} /> : null}
          {showBilling ? <PaymentsInfoCard title="Payment Info" /> : null}
        </Box>
      </Box>
    </NavContainer>
  );
};

export default UserProfile;
