import { Box, Typography } from "@mui/material";
import AccountSecurity from "../CreateAccount/AccountSecurity";
import { useContext, useEffect } from "react";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import Loader from "../../components/Loader";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import sendErrorToast from "../../utils/sendErrorToast";
import getREMFromPX from "../../utils/getREMFromPX";
import LikeIcon from "../../assets/like.png";

export default function SecurityQuestionsPage() {
  const {
    userInfo,
    isLoading: isLoadingUser,
    setUserInfo,
  } = useContext(AddressAndAccountContext) as AddressAndAccountContextType;

  const user = userInfo?.data?.user;
  const userId = user?.id ?? "";

  const {
    mutateAsync: updateUser,
    isLoading,
    isSuccess,
  } = useUpdateUser(userId, {
    onError: () => sendErrorToast("There was an error activating the user."),
  });

  async function submitSecurityQuestions(values: any) {
    const {
      question1Answer,
      question1Option,
      question2Answer,
      question2Option,
    } = values;

    const body = {
      security_answer1: question1Answer,
      security_answer2: question2Answer,
      security_question1: question1Option,
      security_question2: question2Option,
    };
    try {
      const userInfo = await updateUser(body);
      setUserInfo(userInfo);

      if (userInfo?.data?.user?.answered_security_questions) {
        setTimeout(() => {
          window.location.replace("/dashboard");
        }, 1500);
      }
    } catch (error) {
      console.log({ error });
      sendErrorToast("There was an error activating the user.");
    }
  }

  useEffect(() => {
    if (userInfo?.data?.user?.answered_security_questions) {
      setTimeout(() => {
        window.location.replace("/dashboard");
      }, 1500);
    }
  }, [userInfo]);

  if (isLoadingUser) {
    return (
      <Box
        sx={{
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Loader />
      </Box>
    );
  }

  if (isSuccess) {
    return (
      <Box sx={{ paddingTop: "10rem", px: 2, textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: getREMFromPX(36),
            fontWeight: "bold",
          }}
        >
          You're All Set!
        </Typography>
        <Box sx={{ margin: "2rem 0" }}>
          <img src={LikeIcon} alt="Like icon" />
        </Box>
        <Typography sx={{ fontSize: getREMFromPX(24), marginBottom: "3rem" }}>
          You are now being directed to your <br /> dashboard
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <AccountSecurity
        handleNextStep={submitSecurityQuestions}
        previousValues={{
          question1Answer: "",
          question1Option: "",
          question2Answer: "",
          question2Option: "",
        }}
        isLoading={isLoading}
      />
    </Box>
  );
}
