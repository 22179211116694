import { getThemePath } from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

const themePath = getThemePath();
const colors = theme[themePath].colors;

export const LabelStyles = `
font-family: Inter;
font-size: ${getREMFromPX(16)};
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
margin-bottom: 8px;
color: ${colors.form.primary};
`;
