import { theme, ThemePaths } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const getSelectStyles = (themePath: ThemePaths = "default") => ({
  "&.MuiInputBase-root": {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fonts.weights.normal,
    fontSize: getREMFromPX(theme.spacing * 4),
    color: theme[themePath].colors.form.primary,
    height: getREMFromPX(theme.spacing * 14),
    borderRadius: "15px",
  },
  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme[themePath].colors.primary[500]}`,
    },
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme[themePath].colors.primary[500]}`,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme[themePath].colors.border.primary}`,
  },
  "& .MuiSvgIcon-root": {
    color: theme[themePath].colors.textPrimary.primary,
  },
});

export const getPlaceholderStyle = (themePath: ThemePaths = "default") => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontSize: getREMFromPX(16),
  fontWeight: "500",
  color: theme[themePath].colors.form.label.primary,
});
