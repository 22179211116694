import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";
import { getThemePath } from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

const themePath = getThemePath();
const colors = theme[themePath].colors;

const StyledButton = styled(Button)<any>(({ selected }) => ({
  padding: "0.5rem 0.75rem",
  fontFamily: "Inter",
  fontSize: "0.75rem",
  fontWeight: 500,
  border: "1px solid",
  borderColor: selected
    ? colors.table.pagination.border.primary
    : colors.table.pagination.border.secondary,
  color: selected ? "white" : colors.table.pagination.primary,
  backgroundColor: selected ? colors.table.pagination.bgPrimary : "transparent",
  borderRadius: "0px",
  minWidth: "40px",
  "&:hover": {
    backgroundColor: selected
      ? colors.table.pagination.bgPrimary
      : colors.table.pagination.bgHover,
    color: colors.table.pagination.primary,
  },
  "&:disabled": {
    color: selected ? "white" : colors.table.primary,
  },
  "&:active": {
    backgroundColor: colors.table.pagination.bgActive,
    color: colors.table.pagination.primary,
    borderColor: colors.table.pagination.border.secondary,
  },
  borderRight: 0,
}));

const PageButton: React.FC<
  ButtonProps & {
    selected?: boolean;
    dataCy?: string;
  }
> = ({ children, disabled, selected = false, onClick, dataCy, ...props }) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      selected={selected}
      data-cy={dataCy}
      aria-current={selected}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default PageButton;
