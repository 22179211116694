import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import SubHeader from "../SubHeader";
import CloseIcon from "@mui/icons-material/Close";
import { getIconStyles } from "../Alert/Styles";
import useGetThemePath from "../../hooks/useGetThemePath";
import { logoStyles } from "../ProviderBox/styles";
import { MappedPlan } from "../../routes/Plans/Plans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dividerStyle, iconStyle } from "./styles";
import useViewport from "../../hooks/useViewport";

interface ProviderDialogProps {
  isOpen: boolean;
  onClose: () => void;
  plan: MappedPlan | null;
}

const PlanDialog = ({ isOpen, onClose, plan }: ProviderDialogProps) => {
  const themePath = useGetThemePath();
  const { isMobile } = useViewport();
  if (!plan) {
    return <></>;
  }

  const colWidth = isMobile ? "100%" : undefined;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box
        padding={getREMFromPX(theme.spacing * 6)}
        // width={getREMFromPX(theme.spacing * 150)}
        width={isMobile ? "100%" : getREMFromPX(theme.spacing * 150)}
        data-testid="dialogWrapper"
      >
        <Stack direction="column" justifyContent="space-between">
          <Stack direction="row" justifyContent="space-between" width="100%">
            <SubHeader icon="house-signal" headerText={plan.providerName} />
            <IconButton
              data-testId="closeButton"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onClose}
            >
              <CloseIcon
                style={getIconStyles(themePath)}
                fill={theme[themePath].colors.icons.default.fillColor}
              />
            </IconButton>
          </Stack>
          <Stack
            maxWidth={getREMFromPX(theme.spacing * 44)}
            marginTop={getREMFromPX(theme.spacing * 6)}
          >
            <img
              style={logoStyles}
              alt={plan.providerName}
              src={`${process.env.PUBLIC_URL}/assets/provider/${plan.providerId}.png`}
            />
          </Stack>
          <Typography
            marginTop={getREMFromPX(theme.spacing * 6)}
            fontWeight={theme.fonts.weights.bolder}
            fontSize={getREMFromPX(theme.spacing * 4)}
            color={theme[themePath].colors.textPrimary.primary}
          >
            {plan.plan.name}
          </Typography>
          <Typography
            marginTop={getREMFromPX(theme.spacing * 2)}
            marginBottom={getREMFromPX(theme.spacing * 6)}
            fontWeight={theme.fonts.weights.normal}
            fontSize={getREMFromPX(theme.spacing * 4)}
            color={theme[themePath].colors.textPrimary.secondary}
          >
            {plan.plan.description}
          </Typography>
          <Divider />
          <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
            <Stack
              direction="column"
              width={colWidth}
              marginTop={getREMFromPX(theme.spacing * 4)}
            >
              <FontAwesomeIcon
                icon="globe"
                fontSize={getREMFromPX(theme.spacing * 6)}
                style={iconStyle}
                fill={theme[themePath].colors.icons.default.fillColor}
              />
              <Typography
                margin={`${getREMFromPX(theme.spacing * 2)} auto 0 auto`}
                fontWeight={theme.fonts.weights.normal}
                fontSize={getREMFromPX(theme.spacing * 3)}
                lineHeight={getREMFromPX(theme.spacing * 6)}
                color={theme[themePath].colors.textPrimary.secondary}
              >
                Website
              </Typography>
              <Typography
                margin={`0 auto`}
                fontWeight={theme.fonts.weights.bolder}
                fontSize={getREMFromPX(theme.spacing * 4)}
                color={theme[themePath].colors.textPrimary.secondary}
              >
                {plan.marketingUrl
                  .replace(/https:\/\/|http:\/\//, "")
                  .replace("www.", "")}
              </Typography>
            </Stack>
            {!isMobile && <Divider orientation="vertical" sx={dividerStyle} />}
            <Stack
              direction="column"
              width={colWidth}
              marginTop={getREMFromPX(theme.spacing * 4)}
            >
              <FontAwesomeIcon
                icon="envelope"
                fontSize={getREMFromPX(theme.spacing * 6)}
                style={iconStyle}
                fill={theme[themePath].colors.icons.default.fillColor}
              />
              <Typography
                margin={`${getREMFromPX(theme.spacing * 2)} auto 0 auto`}
                fontWeight={theme.fonts.weights.normal}
                fontSize={getREMFromPX(theme.spacing * 3)}
                lineHeight={getREMFromPX(theme.spacing * 6)}
                color={theme[themePath].colors.textPrimary.secondary}
              >
                Email
              </Typography>
              <Typography
                margin={`0 auto`}
                fontWeight={theme.fonts.weights.bolder}
                fontSize={getREMFromPX(theme.spacing * 4)}
                color={theme[themePath].colors.textPrimary.secondary}
              >
                {plan.email}
              </Typography>
            </Stack>
            <Divider
              orientation={isMobile ? "horizontal" : "vertical"}
              sx={dividerStyle}
            />
            <Stack
              direction="column"
              width={colWidth}
              marginTop={getREMFromPX(theme.spacing * 4)}
            >
              <FontAwesomeIcon
                icon="phone"
                fontSize={getREMFromPX(theme.spacing * 6)}
                style={iconStyle}
                fill={theme[themePath].colors.icons.default.fillColor}
              />
              <Typography
                margin={`${getREMFromPX(theme.spacing * 2)} auto 0 auto`}
                fontWeight={theme.fonts.weights.normal}
                fontSize={getREMFromPX(theme.spacing * 3)}
                lineHeight={getREMFromPX(theme.spacing * 6)}
                color={theme[themePath].colors.textPrimary.secondary}
              >
                Phone
              </Typography>
              <Typography
                margin={`0 auto`}
                fontWeight={theme.fonts.weights.bolder}
                fontSize={getREMFromPX(theme.spacing * 4)}
                color={theme[themePath].colors.textPrimary.secondary}
              >
                {plan.phone}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default PlanDialog;
