import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

export interface GetConfigResponse {
  data: {
    config: {
      enabled_features: EnabledFeatures;
    };
  };
  duration: number;
  messages: any[];
}
export interface EnabledFeatures {
  ACH_PAYMENT: {
    enabled: boolean;
  };
  BILLING: {
    enabled: boolean;
  };
  CREATE_ACCOUNT: {
    enabled: boolean;
  };
  PAUSE_SERVICE: {
    enabled: boolean;
  };
  USER_REVIEWS: {
    enabled: boolean;
  };
  VBG_INSURANCE: {
    enabled: boolean;
  };
}

const getConfig = async () => {
  const response = await fetcher(endpoints.getEnabledFeatures);

  if (!response.ok) {
    throw new Error();
  }

  return await response.json();
};

export const useGetConfig = (
  options?: UseQueryOptions<any, Error, any, Array<string>>
): UseQueryResult<GetConfigResponse, Error> =>
  useQuery(["config"], getConfig, options);
