import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getHeaderStyles, iconStyles } from "./styles";
import { Stack } from "@mui/material";
import { SortConfig } from "../../../../hooks/useSortableData";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";

const TableHeader = ({
  width,
  sortable,
  onClick,
  name,
  sortConfig,
  sortKey,
  style = {},
  ...props
}: {
  name: string;
  sortKey?: string;
  width?: string | number;
  sortable?: boolean;
  sortConfig: SortConfig;
  onClick?: (name: string) => void;
  style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLTableCellElement>) => {
  const handleOnClick = () => onClick?.(name);
  const composedStyles = {
    ...style,
    ...getHeaderStyles(width, sortable),
  };
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const getSortIcon = () => {
    if (sortConfig?.key !== sortKey) {
      return (
        <FontAwesomeIcon
          icon="sort"
          style={iconStyles}
          fill={colors.icons.default.fillColor}
        />
      );
    }
    if (sortConfig?.key === sortKey) {
      return sortConfig?.direction === "ascending" ? (
        <FontAwesomeIcon
          icon="sort-up"
          style={iconStyles}
          fill={colors.icons.default.fillColor}
        />
      ) : (
        <FontAwesomeIcon
          icon="sort-down"
          style={iconStyles}
          fill={colors.icons.default.fillColor}
        />
      );
    }
  };
  return (
    <th
      style={composedStyles}
      onClick={(sortable && handleOnClick) || undefined}
      {...props}
    >
      {sortable ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {name}
          {getSortIcon()}
        </Stack>
      ) : (
        name
      )}
    </th>
  );
};
export default TableHeader;
