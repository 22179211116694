import React from "react";
import CardWrapper from "../../components/CardWrapper";
import { useGetOperatorFees } from "../../hooks/useGetOperatorFees";
import { useGetEstimatedFees } from "../../hooks/useGetEstimatedFees";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { BillingContext, BillingType } from "../../components/BillingProvider";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { Box, Typography, Grid } from "@mui/material";
import formatNumber from "../../utils/formatNumber";
import PaymentMethod from "../../components/PaymentMethod";
import useViewport from "../../hooks/useViewport";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Link } from "react-router-dom";
import { routes } from "../../App";
import { ForwardArrowIcon } from "../../assets/icons";
import numberToMonthMap, {
  NumberToMonthMapKey,
} from "../../utils/numberToMonthMap";

const TotalBill = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const currentDate = new Date();
  currentDate.setDate(1);
  currentDate.setMonth(currentDate.getMonth() + 1);
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const { currentAccount } = React.useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { paymentMethod } = React.useContext(BillingContext) as BillingType;

  const { isMobile } = useViewport();

  const { data: operatorData } = useGetOperatorFees(currentAccount?.id, {
    refetchOnWindowFocus: false,
  });

  const { data: estimateData } = useGetEstimatedFees(currentAccount?.id, {
    refetchOnWindowFocus: false,
  });

  const operatorFees = operatorData?.data?.billing?.subscription?.items?.reduce(
    (sum, item) => {
      return sum + item.price.amount;
    },
    0
  );

  const estimatedFees = estimateData?.data?.subscription?.reduce(
    (sum, item) => {
      return sum + parseFloat(item.Serviceplan.fields.Price);
    },
    0
  );

  const total = (operatorFees || 0) + (estimatedFees || 0);

  const arrowAction = (
    <Link
      to={routes.billing.path}
      aria-label="Payment Info"
      data-cy="paymentInfo"
    >
      <ForwardArrowIcon fill={colors.icons.default.fillColor} />
    </Link>
  );

  const is_autopay_enabled =
    operatorData?.data?.billing?.subscription?.collection_method !==
    "charge_automatically";

  return (
    <Grid item xl={12}>
      <CardWrapper title="Payment Info" actions={arrowAction}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: getREMFromPX(16),
            fontWeight: "600",
            fontStyle: "normal",
            mt: 1.5,
            letterSpacing: 0,
            textAlign: "left",
            marginBottom: is_autopay_enabled ? "6px" : "20px",
            color: colors.textPrimary.heading,
            marginTop: is_autopay_enabled ? "20px" : "0px",
          }}
        >
          {is_autopay_enabled
            ? `Your payment of ${formatNumber(total, {
                prefix: "$",
                decimals: 2,
              })}
            is due on
            ${
              numberToMonthMap[currentDate.getMonth() as NumberToMonthMapKey]
            } 1,
            2024.`
            : null}
        </Typography>
        <PaymentMethod {...paymentMethod} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: {
              md: "16px",
              sm: 0,
            },
          }}
        >
          <AutorenewIcon
            sx={{
              color: colors.icons.default.fillColor,
              marginRight: isMobile ? "10px" : "11px",
            }}
            fill={colors.icons.default.fillColor}
          />
          <Typography
            sx={{
              color: colors.textPrimary.heading,
              fontFamily: "Inter",
              fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(16),
              fontWeight: 600,
            }}
          >
            Autopay on {formattedDate}
          </Typography>
        </Box>
      </CardWrapper>
    </Grid>
  );
};
export default TotalBill;
