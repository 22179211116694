import { Link, useNavigate } from "react-router-dom";
import CardWrapper from "../../../../components/CardWrapper";
import { FaqIcon } from "../../../../assets/icons";
import { routes } from "../../../../App";
import { Grid, SxProps } from "@mui/material";
import { ContinueIcon, Description, ImageContainer } from "../../Styles";
import { theme } from "../../../../theme";
import useGetThemePath from "../../../../hooks/useGetThemePath";

const Faq = ({ styles }: { styles: SxProps }) => {
  const navigate = useNavigate();
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const arrowAction = (
    <Link
      to={routes.helpFaq.path}
      aria-label="Goto FAQ Info page"
      data-cy="help-faq-arrow"
    >
      <ContinueIcon fill={colors.icons.default.fillColor} />
    </Link>
  );

  return (
    <CardWrapper
      title="Frequently Asked Questions"
      actions={arrowAction}
      sx={styles}
    >
      <Grid
        sx={{
          flexDirection: "row",
          flexWrap: "nowrap",
        }}
        container
        spacing={2}
        onClick={() => navigate(routes.helpFaq.path)}
      >
        <Grid
          item
          sm={6}
          md={6}
          display="flex"
          alignItems="center"
          height="auto"
        >
          <Description>
            Have some <br /> questions?
          </Description>
        </Grid>
        <Grid item sm={6} md={6}>
          <ImageContainer>
            <FaqIcon
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                marginBottom: "1.84rem",
              }}
              fill={colors.icons.default.fillColor}
            />
          </ImageContainer>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};
export default Faq;
