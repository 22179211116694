import { Stack, Typography } from "@mui/material";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import useGetThemePath from "../../hooks/useGetThemePath";

const ValidationMessage = ({
  valid,
  message,
}: {
  valid: boolean;
  message: string;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const check = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0306 5.53032L7.0306 13.5303C6.96092 13.6002 6.87813 13.6557 6.78696 13.6936C6.6958 13.7314 6.59806 13.7509 6.49935 13.7509C6.40064 13.7509 6.3029 13.7314 6.21173 13.6936C6.12057 13.6557 6.03778 13.6002 5.9681 13.5303L2.4681 10.0303C2.39833 9.96056 2.34299 9.87774 2.30524 9.78658C2.26748 9.69543 2.24805 9.59774 2.24805 9.49907C2.24805 9.40041 2.26748 9.30272 2.30524 9.21156C2.34299 9.12041 2.39833 9.03759 2.4681 8.96782C2.53786 8.89806 2.62069 8.84272 2.71184 8.80496C2.80299 8.76721 2.90069 8.74777 2.99935 8.74777C3.09801 8.74777 3.19571 8.76721 3.28686 8.80496C3.37801 8.84272 3.46083 8.89806 3.5306 8.96782L6.49997 11.9372L13.9693 4.46907C14.1102 4.32818 14.3013 4.24902 14.5006 4.24902C14.6999 4.24902 14.891 4.32818 15.0318 4.46907C15.1727 4.60997 15.2519 4.80107 15.2519 5.00032C15.2519 5.19958 15.1727 5.39068 15.0318 5.53157L15.0306 5.53032Z"
        fill={colors.textPrimary.headingAlt}
      />
    </svg>
  );
  const x = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0 }}
    >
      <path
        d="M12.7001 4.30682C12.6385 4.24501 12.5652 4.19598 12.4846 4.16253C12.4039 4.12907 12.3175 4.11185 12.2301 4.11185C12.1428 4.11185 12.0564 4.12907 11.9757 4.16253C11.8951 4.19598 11.8218 4.24501 11.7601 4.30682L8.50015 7.56015L5.24015 4.30015C5.17843 4.23843 5.10515 4.18947 5.02451 4.15606C4.94387 4.12266 4.85744 4.10547 4.77015 4.10547C4.68286 4.10547 4.59643 4.12266 4.51579 4.15606C4.43514 4.18947 4.36187 4.23843 4.30015 4.30015C4.23843 4.36187 4.18947 4.43514 4.15606 4.51579C4.12266 4.59643 4.10547 4.68286 4.10547 4.77015C4.10547 4.85744 4.12266 4.94387 4.15606 5.02451C4.18947 5.10515 4.23843 5.17843 4.30015 5.24015L7.56015 8.50015L4.30015 11.7601C4.23843 11.8219 4.18947 11.8951 4.15606 11.9758C4.12266 12.0564 4.10547 12.1429 4.10547 12.2301C4.10547 12.3174 4.12266 12.4039 4.15606 12.4845C4.18947 12.5652 4.23843 12.6384 4.30015 12.7001C4.36187 12.7619 4.43514 12.8108 4.51579 12.8442C4.59643 12.8776 4.68286 12.8948 4.77015 12.8948C4.85744 12.8948 4.94387 12.8776 5.02451 12.8442C5.10515 12.8108 5.17843 12.7619 5.24015 12.7001L8.50015 9.44015L11.7601 12.7001C11.8219 12.7619 11.8951 12.8108 11.9758 12.8442C12.0564 12.8776 12.1429 12.8948 12.2301 12.8948C12.3174 12.8948 12.4039 12.8776 12.4845 12.8442C12.5652 12.8108 12.6384 12.7619 12.7001 12.7001C12.7619 12.6384 12.8108 12.5652 12.8442 12.4845C12.8776 12.4039 12.8948 12.3174 12.8948 12.2301C12.8948 12.1429 12.8776 12.0564 12.8442 11.9758C12.8108 11.8951 12.7619 11.8219 12.7001 11.7601L9.44015 8.50015L12.7001 5.24015C12.9535 4.98682 12.9535 4.56015 12.7001 4.30682Z"
        fill={colors.notification.error.primary}
      />
    </svg>
  );
  return (
    <Stack direction="row" alignItems="center" mt="5px" flex={1}>
      {valid ? check : x}
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: getREMFromPX(11),
          fontWeight: valid ? "500" : "400",
          fontStyle: "normal",
          textAlign: "left",
          color: valid ? colors.form.primary : colors.form.primary,
          margin: "2px 0 0 6px",
        }}
      >
        {message}
      </Typography>
    </Stack>
  );
};

export default ValidationMessage;
