import { TypesOptionsFields } from "../../routes/Services/utils/intefaces/TypesOptionsFIelds";
import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { theme } from "../../theme";
import { getThemePath } from "../../hooks/useGetThemePath";

const colors = theme[getThemePath()].colors;

export const typesOptions: Array<TypesOptionsFields> = [
  {
    label: "Tile",
    icon: <AppsIcon fill={colors.icons.default.fillColor} />,
    active: true,
  },
  {
    label: "Table",
    icon: <FormatListBulletedIcon fill={colors.icons.default.fillColor} />,
    active: false,
  },
];
