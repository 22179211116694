import { styled, SxProps } from "@mui/material";
import { ForwardArrowIcon } from "../../assets/icons";
import { theme } from "../../theme";
import { Link } from "react-router-dom";
import { getThemePath } from "../../hooks/useGetThemePath";

const themePath = getThemePath();

export const BackStyle = styled(Link)`
  color: #434343;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;

  padding: 4px 12px 4px 12px;

  &:hover {
    opacity: 80;
  }
`;

export const Description = styled("div")`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  height: auto; /* Set the desired height for the container */

  color: var(--dark-green-font-color, #253331);
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 32px;
  min-width: 100px;

  @media (max-width: 500px) {
    font-size: 1rem;
    /* margin-top: 1.5rem; */
    /* margin-top: 52px; */
  }
`;

export const ImageContainer = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  align-self: flex-end;
  margin-top: 2.5rem;
  filter: grayscale(1);
`;

export const ContinueIcon = styled(ForwardArrowIcon)`
  pointer-events: auto;
  cursor: pointer;
  z-index: 9;
`;

export const SubDescription = styled("p")`
  width: 100%;
  margin: 12px 0px 20px 0px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-green-font-color, #253331);

  @media (max-width: 500px) {
    font-size: 0.65rem;
    font-weight: 500;
    margin: 12px 0px 10px 0px;
  }
`;

export const SupportInfo = styled("p")`
  margin: 6px 0 16px 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-green-font-color, #253331);

  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    margin: 5px 0 8px 0px;
  }
`;

export const iconStyles: SxProps = {
  width: {
    sm: "35px",
    xs: "25px",
  },
  height: {
    sm: "35px",
    xs: "25px",
  },
  margin: {
    sm: "0 12px 0px 0",
    xs: "0 4px 0px 0",
  },
  color: "#55857e",
};

export const IconText = styled("a")`
  vertical-align: 10px;
  margin: 3.3px;
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #1d19e0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const ServiceProviderMultiSubHeader = styled("span")`
  flex-grow: 0;
  margin: 26px 0 0 0;
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-green-font-color, #253331);
`;

export const ServiceProviderMultDesc = styled("span")`
  flex-grow: 0;
  margin: 16px 0 25px 5px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`;

export const ButtonService = styled("button")`
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0px 0 0px;
  padding: 16px 50px;
  border-radius: 15px;
  background-color: #416661;
  flex-grow: 0;
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  border: none;
  cursor: pointer;
  width: 294px;

  @media (max-width: 420px) {
    width: 100%;
  }
`;

export const HelpCenterCardStyles: SxProps = {
  cursor: "pointer",
  borderRadius: "8px",
  "&:hover": {
    background: theme[themePath].colors.hover.primary,
    boxShadow: theme[themePath].boxShadows.boxShadowBR,
  },
  padding: "0px !important",
};
