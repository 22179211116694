import { useContext } from "react";
import { Typography } from "@mui/material";
import defaultImg from "../../assets/EPLogoColor.svg";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";

const CityIcon = ({ ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const { provider } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const handleError = (e: any) => {
    if (!e.target.src.endsWith(defaultImg)) {
      e.target.src = defaultImg;
    }
  };

  const imageAltText = provider
    ? `${provider?.data?.provider?.name} (${
        provider?.data?.provider?.Contacts?.at(0)?.Location?.city
      })`
    : "city_logo";

  return (
    <Typography
      variant="h1"
      sx={{
        marginBottom: {
          md: "1rem",
          xs: 0,
        },
        height: {
          md: "74px",
          xs: "50px",
        },
        width: {
          md: "120px",
          xs: "100px",
        },
        display: "flex",
      }}
    >
      <img
        {...props}
        src={`${process.env.PUBLIC_URL}/assets/provider/${process.env.REACT_APP_GUID}.png`}
        alt={imageAltText}
        onError={handleError}
        style={{
          objectFit: "contain",
          height: "100%",
          width: "100%",
        }}
      />
    </Typography>
  );
};

export default CityIcon;
