import { Box, Card, Typography } from "@mui/material";
import Button from "../../components/Button";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import { useContext } from "react";
import {
  AuthContext,
  AuthenticationContext,
} from "../../components/AuthProvider";
import useSupportEmail from "../../hooks/useSupportEmail";

export default function ConnectDevice() {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { logOut } = useContext(AuthContext) as AuthenticationContext;
  const supportEmail = useSupportEmail();

  function handleGoBackToLoginScreen() {
    logOut();
  }

  return (
    <Box
      sx={{
        mx: "auto",
        width: "fit-content",
        marginTop: "250px",
      }}
    >
      <Card
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          flexDirection: "column",
          height: "auto",
          maxWidth: "536px",
          background: colors.white,
          padding: "32px 32px 32px 32px",
          borderRadius: "8px",
          boxShadow: "0px 4.77px 11.92px 0px #00000040",
          gap: 3,
          mx: 1.5,
        }}
      >
        <Typography
          sx={{
            color: colors.dark,
            fontWeight: 600,
            fontSize: "20px",
            fontFamily: "Inter",
          }}
        >
          Connect a Device
        </Typography>
        <Typography
          sx={{
            color: colors.dark,
            fontWeight: 400,
            fontSize: "18px",
            fontFamily: "Inter",
          }}
        >
          Your account isn't currently connected to a device. To get help
          connecting a device, please reach out to your operator at{" "}
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
              fontFamily: "Inter",
            }}
          >
            {supportEmail}.
          </Typography>
        </Typography>
        <Button
          sx={{
            px: "80px",
            mx: "auto",
            mt: 2,
          }}
          text="Back to Login"
          mode="secondary"
          onClick={handleGoBackToLoginScreen}
        />
      </Card>
    </Box>
  );
}
