import { theme, ThemePaths } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export enum ButtonSize {
  small = "small",
  medium = "medium",
  large = "large",
}

const paddings = {
  small: getREMFromPX(theme.spacing * 2),
  medium: getREMFromPX(theme.spacing * 4),
  large: `${getREMFromPX(theme.spacing * 4)} ${getREMFromPX(
    theme.spacing * 6
  )}`,
};

const fontSizes = {
  small: getREMFromPX(theme.spacing * 3),
  medium: getREMFromPX(theme.spacing * 3.5),
  large: getREMFromPX(theme.spacing * 4),
};

export const getSpanStyles = (size: keyof typeof ButtonSize) => ({
  lineHeight: fontSizes[size],
});

const getButtonBackground = (themePath: ThemePaths, variant?: string) => {
  let style;
  switch (variant) {
    case "text":
      style = "transparent";
      break;
    case "outlined":
      style = "transparent";
      break;
    case "secondary":
      style = theme[themePath].colors.secondary[500];
      break;
    default:
      style = theme[themePath].colors.primary[500];
  }
  return style;
};

const getButtonColor = (themePath: ThemePaths, variant?: string) => {
  let style;
  switch (variant) {
    case "text":
      style = theme[themePath].colors.primary[500];
      break;
    case "outlined":
      style = theme[themePath].colors.primary[500];
      break;
    case "secondary":
      style = theme[themePath].colors.white;
      break;
    default:
      style = theme[themePath].colors.white;
  }
  return style;
};

export const getButtonStyles = (
  size: keyof typeof ButtonSize,
  variant?: string,
  themePath: ThemePaths = "default",
  color?: string
) => ({
  "&:hover": {
    color:
      variant === "outlined"
        ? color === "secondary"
          ? theme[themePath].colors.secondary[500]
          : theme[themePath].colors.primary[500]
        : theme[themePath].colors.white,
    backgroundColor:
      variant === "outlined"
        ? color === "secondary"
          ? theme[themePath].colors.secondary[50]
          : theme[themePath].colors.primary[50]
        : color === "secondary"
        ? theme[themePath].colors.secondary[700]
        : theme[themePath].colors.primary[700],
    border:
      variant === "outlined"
        ? `1px solid ${theme[themePath].colors.border.primary}`
        : "none",
  },
  "&:disabled": {
    backgroundColor:
      variant === "text"
        ? "transparent"
        : theme[themePath].colors.border.primary,
    color: theme[themePath].colors.textSecondary.disabled,
    "& span": {
      color: `${theme[themePath].colors.textPrimary.disabled} !important`,
    },
  },
  fontSize: fontSizes[size],
  padding: paddings[size],
  backgroundColor: getButtonBackground(
    themePath,
    color === "secondary" ? "secondary" : variant
  ),
  color: getButtonColor(
    themePath,
    color === "secondary" ? "secondary" : variant
  ),
  fontWeight:
    size === ButtonSize.small
      ? theme.fonts.weights.mediumBold
      : theme.fonts.weights.bolder,
  textTransform: "none",
  borderRadius: "8px",
  border:
    variant === "outlined"
      ? `1px solid ${theme[themePath].colors.border.primary}`
      : "none",
});
