import React, { useCallback, useEffect, useRef, useState } from "react";
import Checkbox from "../Checkbox";
import useSortableData, { SortConfig } from "../../hooks/useSortableData";
import TableHeader from "./components/TableHeader";
import { v4 as uuidv4 } from "uuid";
import TableRow from "./components/TableRow";
import {
  expandedRowStyles,
  tableCellStyles,
  tableRowHeaderStyles,
  tableStyles,
} from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

type Header = {
  width?: string | number;
  name: string;
  sortable?: boolean;
  sortKey?: string;
  style?: React.CSSProperties;
};
type ColumnValue = string | number | string[] | number[];

type Column = {
  value?: string;
  getValue?: (column: any) => ColumnValue;
  render?: (column: any) => React.ReactElement;
  style?: React.CSSProperties;
};

export interface TableProps {
  headers: Header[];
  items: any[];
  columns: Column[];
  isSelectable?: boolean;
  selectableStyles?: React.CSSProperties;
  onSelectRow?: (column: any[]) => void;
  disableSelection?: boolean;
  controlledSelectedRows?: any[];
  expandedComponent?: (item: any) => React.ReactElement;
  dataTestId?: string;
  customSortFunction?: (config: SortConfig) => any[];
}

const Table = ({
  dataTestId,
  headers,
  columns,
  items: originalItems,
  isSelectable,
  selectableStyles,
  onSelectRow,
  disableSelection,
  controlledSelectedRows,
  expandedComponent,
  customSortFunction,
}: TableProps) => {
  const [rowMap, setRowMap] = useState<{ [key: string]: boolean }>({});
  const [expandedRowMap, setExpandedRowMap] = useState<{
    [key: string]: boolean;
  }>({});
  const { items, requestSort, sortConfig } = useSortableData(
    originalItems,
    customSortFunction
  );
  const [selectedRows, setSelectedRows] = useState<any[]>(
    controlledSelectedRows || []
  );
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const toggleExpandedRow = useCallback(
    (item: any) => {
      if (expandedComponent) {
        if (!expandedRowMap[item.id]) {
          return setExpandedRowMap((oldRowMap) => ({
            ...oldRowMap,
            [item.id]: true,
          }));
        }
        setExpandedRowMap((oldRowMap) => ({
          ...oldRowMap,
          [item.id]: false,
        }));
      }
    },
    [expandedComponent, expandedRowMap]
  );

  const onSelectRowRef = useRef(onSelectRow);

  useEffect(() => {
    onSelectRowRef.current?.(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    if (controlledSelectedRows) {
      const rowMap = controlledSelectedRows.reduce((acc, obj) => {
        let key = obj["id"];
        acc[key] = true;
        return acc;
      }, {});
      setRowMap(rowMap);
      setSelectedRows(controlledSelectedRows);
    }
  }, [controlledSelectedRows]);

  return (
    <table style={tableStyles} data-testid={dataTestId}>
      <thead>
        <tr style={tableRowHeaderStyles}>
          {!!expandedComponent && <td />}
          {headers.map((header) => (
            <TableHeader
              {...header}
              key={header.name}
              sortConfig={sortConfig}
              onClick={
                header.sortable
                  ? () => requestSort(header.sortKey as string)
                  : undefined
              }
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <React.Fragment key={item.id}>
            <TableRow
              isExpanded={expandedRowMap[item.id]}
              headersLength={headers.length}
              selected={rowMap[item.id]}
              expandedComponent={expandedComponent?.(item)}
              onClick={
                expandedComponent ? () => toggleExpandedRow(item) : undefined
              }
            >
              <>
                {expandedComponent && (
                  <td style={expandedRowStyles}>
                    <IconButton
                      size="small"
                      onClick={() => toggleExpandedRow(item)}
                      aria-label="toggle_expanded_row"
                    >
                      <FontAwesomeIcon
                        icon={
                          expandedRowMap[item.id]
                            ? "chevron-down"
                            : "chevron-up"
                        }
                        fill={colors.icons.default.fillColor}
                      />
                    </IconButton>
                  </td>
                )}
                {isSelectable && (
                  <td
                    style={selectableStyles}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Checkbox
                      checked={rowMap[item.id] || false}
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                          borderRadius: "50px",
                        },
                      }}
                      disabled={disableSelection}
                      onChange={() => {
                        if (!rowMap[item.id] && onSelectRow) {
                          setSelectedRows((rows) => [...rows, item]);
                        } else if (onSelectRow) {
                          setSelectedRows((rows) =>
                            rows.filter((row) => row.id !== item.id)
                          );
                        }
                        if (!controlledSelectedRows) {
                          setRowMap({
                            ...rowMap,
                            [item.id]: !rowMap[item.id],
                          });
                        }
                      }}
                    />
                  </td>
                )}
                {/* TODO: Find a better API for columns */}
                {columns.map((column) => {
                  const cellStyles = column.style
                    ? { ...tableCellStyles, ...column.style }
                    : tableCellStyles;
                  return (
                    <React.Fragment key={uuidv4()}>
                      {column.render ? (
                        <td style={cellStyles}>{column.render(item)}</td>
                      ) : (
                        <td style={cellStyles}>
                          {column.getValue
                            ? column.getValue(item)
                            : items[index][column.value as string | number]}
                        </td>
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            </TableRow>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};
export default Table;
