import React from "react";
import BackButton from "./BackButton";
import PageButton from "./PageButton";
import NextButton from "./NextButton";
import { Box, Typography } from "@mui/material";

interface PaginationProps {
  limit: number;
  totalEntries: number;
  currentPage: number;
  lastPage: number;
  onSelect: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  lastPage,
  limit,
  totalEntries,
  onSelect,
}) => {
  const pageCount = lastPage > 4 ? 4 : lastPage;
  const guessFirstPage = currentPage - Math.floor((pageCount - 1) / 2);
  const firstPageDisplayed = Math.min(
    lastPage - pageCount + 1,
    Math.max(guessFirstPage, 1)
  );

  const slots = Array.from({ length: pageCount }, (_, key) => key);
  slots.pop();

  if (totalEntries < 1) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      fontFamily="Inter"
      mt={4}
      gap={2}
      sx={{ spaceX: 0 }}
    >
      <Typography
        variant="body2"
        fontWeight="normal"
        fontFamily="Inter"
        fontSize={12}
      >
        Showing{" "}
        {lastPage > 1
          ? `${(currentPage - 1) * limit + 1} to ${
              (currentPage - 1) * limit + limit > totalEntries
                ? totalEntries
                : (currentPage - 1) * limit + limit
            }`
          : `${totalEntries}`}{" "}
        of {totalEntries} entries
      </Typography>
      <Box>
        <BackButton
          disabled={currentPage === 1}
          onClick={() => onSelect(currentPage - 1)}
        >
          Previous
        </BackButton>
        {slots.map((slot) => {
          const key = slot + firstPageDisplayed;
          return (
            <PageButton
              key={`s-${key}`}
              selected={key === currentPage}
              onClick={() => onSelect(key)}
              data-cy={`page${slot}Button`}
              disabled={key === currentPage}
              aria-label={
                key === currentPage
                  ? `Current Page, Page ${key}`
                  : `Go To, Page ${key}`
              }
            >
              {key}
            </PageButton>
          );
        })}
        <PageButton
          key={`s-${lastPage}`}
          selected={lastPage === currentPage}
          onClick={() => onSelect(lastPage)}
          data-cy={`page${lastPage}Button`}
          disabled={lastPage === currentPage}
          aria-label={
            lastPage === currentPage
              ? `Current Page, Page ${lastPage}`
              : `Go To, Page ${lastPage}`
          }
        >
          {lastPage}
        </PageButton>
        <NextButton
          disabled={currentPage === lastPage}
          onClick={() => onSelect(currentPage + 1)}
        >
          Next
        </NextButton>
      </Box>
    </Box>
  );
};

export default Pagination;
