import { theme, ThemePaths } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export enum LinkSize {
  small = "small",
  medium = "medium",
  large = "large",
}

const fontSizes = {
  small: getREMFromPX(theme.spacing * 3),
  medium: getREMFromPX(theme.spacing * 3.5),
  large: getREMFromPX(theme.spacing * 4),
};

const fontWeights = {
  small: theme.fonts.weights.mediumBold,
  medium: theme.fonts.weights.bolder,
  large: theme.fonts.weights.bolder,
};

export const getExternalLinkStyle = (
  size: keyof typeof LinkSize,
  themePath: ThemePaths = "default"
) => `
  &:hover {
    color: ${theme[themePath].colors.links.hoverColor};
  }
  font-size: ${fontSizes[size]};
  color: ${theme[themePath].colors.links.externalLink};
  font-weight: ${fontWeights[size]};
  cursor: pointer;
  `;
