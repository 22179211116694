import { theme, ThemePaths } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const getIconStyle = (themePath: ThemePaths = "default") => ({
  color: theme[themePath].colors.primary[500],
});

export const dividerStyle = {
  marginTop: getREMFromPX(theme.spacing * 4),
};
