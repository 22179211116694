import { ReactNode } from "react";
import {
  Box,
  Typography,
  Stack,
  Card,
  CardContent,
  SxProps,
} from "@mui/material";
import { theme } from "../../theme";
import { TypesOfStatus } from "../PaymentMethodCard/PaymentMethodCard";
import getREMFromPX from "../../utils/getREMFromPX";
import useGetThemePath from "../../hooks/useGetThemePath";

type CardWrapperProps = {
  title?: string;
  status?: TypesOfStatus;
  isPaymentMethodCard?: boolean;
  actions?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  sx?: SxProps;
  cardTitleSxProps?: SxProps;
  hasOverflow?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const CardWrapper = ({
  title,
  status,
  actions,
  children,
  footer,
  isPaymentMethodCard = false,
  hasOverflow = false,
  ...rest
}: CardWrapperProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const getBackgroundColor = () => {
    switch (status) {
      case "Overdue":
        return !!isPaymentMethodCard
          ? colors.notification.error.accent
          : colors.white;
      case "Pending":
        return !!isPaymentMethodCard
          ? colors.notification.warning.secondaryAccent
          : colors.white;
      default:
        return colors.white;
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        bgcolor: getBackgroundColor(),
        borderRadius: "8px",
        boxShadow: `0 4px 25px ${colors.cardwrapper.shadow}`,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        ...rest.sx,
      }}
    >
      <CardContent
        sx={{
          padding: {
            sm: "20px 25px 20px 25px",
            xs: "24px",
          },
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowX: hasOverflow ? "auto" : "",
          ":last-child": {
            paddingBottom: "16px",
          },
        }}
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          sx={{
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
            flexWrap: "wrap",
            ...rest.cardTitleSxProps,
          }}
        >
          <Typography
            fontFamily="Inter"
            fontSize={getREMFromPX(20)}
            fontWeight="600"
            fontStyle="normal"
            letterSpacing={0}
            textAlign="left"
            variant="h3"
            color={colors.textPrimary.heading}
          >
            {title}
          </Typography>
          <Stack flexDirection="row" alignItems="flex-end">
            {actions}
          </Stack>
        </Stack>
        {children}
      </CardContent>
      {footer && (
        <Box
          sx={{
            backgroundColor:
              status === "Paid"
                ? colors.cardwrapper.footer.background.default
                : colors.cardwrapper.footer.background.secondary,
            paddingBottom: "13px",
            marginTop: "12px",
          }}
        >
          {footer}
        </Box>
      )}
    </Card>
  );
};

export default CardWrapper;
