import { theme, ThemePaths } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const getHeaderStyles = (themePath: ThemePaths = "default") => `
height: ${getREMFromPX(theme.spacing * 8)};
background-color:${theme[themePath].colors.white};
display: flex;
align-items: center;
`;

export const headerLogoImageStyles = {
  width: getREMFromPX(theme.spacing * 20),
  height: "auto",
};
