import React, { useState } from "react";
import { IPaymentHistoryProps, IPaymentHistoryRowData } from "./interfaces";
import StatusBadge from "./components/StatusBadge";
import {
  Box,
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import { endpoints } from "../../api/constants/endpoints";
import fetcher from "../../utils/fetchWrapper";
import { theme } from "../../theme";
import useGetThemePath from "../../hooks/useGetThemePath";
import Pagination from "../Pagination";
import usePagination from "../Pagination/usePagination";
import useViewport from "../../hooks/useViewport";

const DownloadArea = styled("div")`
  width: fit-content;
`;

const DownloadButton = styled("a")`
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d19e0;
  background: none;
  border: none;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
`;

const paymentHistoryPaginationLimit = 8;

export const PaymentHistory: React.FC<IPaymentHistoryProps> = ({ data }) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { isMobile } = useViewport(862);

  const { selectedAccountId } = React.useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [isLoading, setIsLoading] = useState(false);
  const { lastPage, page, paginatedResults, setPage } =
    usePagination<IPaymentHistoryRowData>({
      data,
      limit: paymentHistoryPaginationLimit,
    });
  const fetchPDF = async (invoice_id: string) => {
    setIsLoading(true);
    const url = `${endpoints.account}/${selectedAccountId}/billing/receiptPDF?invoice_id=${invoice_id}`;

    try {
      const response = await fetcher(url);
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF file.");
      }

      const pdfBlob = await response.blob();
      const pdfObjectUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfObjectUrl, "_blank");

      // Clean up the object URL after a delay to ensure the PDF is opened
      // Revoke the created object URL to free up memory resources and to avoid memory leaks.
      setTimeout(() => URL.revokeObjectURL(pdfObjectUrl), 10000);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!data) {
    return (
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40vh",
        }}
      >
        <CircularProgress
          size="2rem"
          sx={{ color: "#488B5B", margin: "auto" }}
        />
      </Box>
    );
  }

  return (
    <>
      <Table
        sx={{
          marginTop: "24px",
        }}
        data-cy="payment-history-table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ textAlign: "left" }}>Description</TableCell>
            {!isMobile ? (
              <>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
              </>
            ) : null}
            <TableCell>Receipt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedResults.map((row, index) => (
            <TableRow key={index} data-cy={`payment-history-row-${index}`}>
              <TableCell
                sx={{
                  textAlign: "left",
                  overflow: "hidden",
                  whiteSpace: "wrap",
                  maxWidth: "120px",
                }}
                data-cy={`payment-history-row-${index}-description`}
              >
                {row.invoice}
              </TableCell>

              {!isMobile ? (
                <>
                  <TableCell data-cy={`payment-history-row-${index}-amount`}>
                    ${row.amount.toFixed(2)}
                  </TableCell>
                  <TableCell data-cy={`payment-history-row-${index}-date`}>
                    {row.date}
                  </TableCell>
                  <TableCell data-cy={`payment-history-row-${index}-status`}>
                    <StatusBadge
                      status={row.status}
                      tooltip={`${row.status} : ${row?.date?.split(" ")[0]}`}
                    />
                  </TableCell>
                </>
              ) : null}
              <TableCell data-cy={`payment-history-row-${index}-download`}>
                <DownloadArea>
                  <DownloadButton
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchPDF(row.id);
                    }}
                    data-cy={`payment-history-row-${index}-view`}
                    aria-label={`Download receipt for ${row.invoice}`}
                  >
                    View Receipt
                  </DownloadButton>
                </DownloadArea>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={page}
        limit={paymentHistoryPaginationLimit}
        totalEntries={data.length}
        lastPage={lastPage}
        onSelect={(page) => {
          setPage(page);
        }}
      />
      <Modal open={isLoading} onClose={() => {}}>
        <Box
          width={499}
          height={224}
          borderRadius="10px"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: theme[themePath].colors.white,
            padding: "9px 3px 7px 2px",
          }}
        >
          <Typography
            fontSize="1.125rem"
            fontWeight={600}
            color={colors.textPrimary.heading}
            textAlign="center"
          >
            <CircularProgress
              size="4rem"
              style={{
                color: theme[themePath].colors.textPrimary.headingAlt,
                margin: "1.5rem",
              }}
            />
            <br />
            Your PDF is generating.
            <br />
            Please disable your pop-up blocker.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentHistory;
