import { theme, ThemePaths } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const getIconStyles = (themePath: ThemePaths = "default") => ({
  color: theme[themePath].colors.textPrimary.disabled,
  marginRight: getREMFromPX(theme.spacing * 2),
});

export const rightArrowIconStyles = {
  fontSize: getREMFromPX(theme.spacing * 4),
};
