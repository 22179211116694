import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";
import Button from "../Button";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { rightArrowIconStyles, getIconStyles } from "./styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useViewport from "../../hooks/useViewport";

const SubHeader = ({
  icon,
  headerText,
  viewAll,
  viewAllOnClick,
  subHeaderFontSize,
  subHeaderFontWeight,
}: {
  icon?: IconProp;
  headerText: string;
  viewAll?: boolean;
  viewAllOnClick?: () => void;
  subHeaderFontSize?: string | number;
  subHeaderFontWeight?: string | number;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const { isMobile } = useViewport();

  return (
    <Stack flexDirection="row" mt={isMobile ? "1rem !important" : "auto"}>
      <Stack
        width="100%"
        flexDirection="row"
        alignItems="center"
        rowGap={getREMFromPX(theme.spacing * 2)}
        justifyContent="space-between"
      >
        <Typography
          component="h2"
          fontWeight={subHeaderFontWeight || theme.fonts.weights.mediumBold}
          color={theme[themePath].colors.textPrimary.primary}
          fontSize={subHeaderFontSize || getREMFromPX(theme.spacing * 5)}
        >
          {icon && (
            <FontAwesomeIcon
              style={getIconStyles(themePath)}
              icon={icon}
              fill={colors.icons.default.fillColor}
            />
          )}
          {headerText}
        </Typography>
        {viewAll && (
          <Button
            size="medium"
            text="View All"
            variant="text"
            onClick={viewAllOnClick}
            endIcon={
              <FontAwesomeIcon
                style={rightArrowIconStyles}
                fontSize={getREMFromPX(theme.spacing * 4)}
                icon="chevron-right"
                fill={colors.icons.default.fillColor}
              />
            }
          />
        )}
      </Stack>
    </Stack>
  );
};
export default SubHeader;
