import {
  Button,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from "@mui/material";
import { ButtonSize, getSpanStyles } from "./Styles";
import useViewport from "../../hooks/useViewport";
import React from "react";
import getREMFromPX from "../../utils/getREMFromPX";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

export interface ButtonProps extends MuiButtonProps {
  text: string | React.ReactNode;
  isLoading?: boolean;
  mode?: "default" | "secondary" | "black" | "disabled" | "link" | "outline";
  dataCy?: string;
}

export function getCommonButtonStyles(isMobile: boolean) {
  return {
    display: "flex",
    padding: "20px",
    borderRadius: "15px",
    fontWeight: 500,
    fontSize: getREMFromPX(20),
    lineHeight: "24px",
    fontFamily: "Inter",
  };
}

const StyledButton = ({
  isLoading = false,
  mode = "default",
  ...props
}: ButtonProps) => {
  const { isMobile } = useViewport();

  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const commonHoverStyle = {
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
  };

  const disabledStyle = !!props.disabled
    ? {
        backgroundColor: colors.buttons.disabled.ButtonBg,
        color: colors.buttons.disabled.BtText,
        "&:hover": {
          backgroundColor: colors.buttons.disabled.HvButtonBg,
          ...commonHoverStyle,
        },
        "&active": {
          backgroundColor: colors.buttons.disabled.ActiveButtonBg,
        },
      }
    : {};

  const getModeStyle = () => {
    switch (mode) {
      case "secondary":
        return {
          backgroundColor: colors.buttons.primary.ButtonBg,
          color: colors.buttons.primary.BtText,
          "&:hover": {
            backgroundColor: colors.buttons.primary.HvButtonBg,
            ...commonHoverStyle,
          },
          "&active": {
            backgroundColor: colors.buttons.primary.ActiveButtonBg,
          },
        };
      case "outline":
        return {
          backgroundColor: "#fff",
          border: `1px solid ${colors.buttons.outline.border}`,
          fontSize: 14,
          fontWeight: 500,
          color: colors.form.primary,
          borderRadius: "4px",
          padding: "6px 16px",
        };
      case "black":
        return {
          backgroundColor: colors.form.primary,
          color: colors.white,
          "&:hover": {
            backgroundColor: colors.form.primary,
            ...commonHoverStyle,
          },
          "&active": {
            backgroundColor: "#5c6f6c",
          },
        };
      case "disabled":
        return {
          backgroundColor: colors.buttons.disabled.ButtonBg,
          color: colors.buttons.disabled.BtText,
          "&:hover": {
            backgroundColor: colors.buttons.disabled.HvButtonBg,
            ...commonHoverStyle,
          },
          "&active": {
            backgroundColor: colors.buttons.disabled.ActiveButtonBg,
          },
        };
      case "link":
        return {
          backgroundColor: "transparent",
          color: colors.links.primary,
          textDecoration: "underline",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&active": {
            backgroundColor: "transparent",
          },
        };
      default:
        return {
          backgroundColor: colors.buttons.primary.ButtonBg,
          color: colors.buttons.primary.BtText,
          textDecoration: "none",
          "&:hover": {
            backgroundColor: colors.buttons.primary.HvButtonBg,
            ...commonHoverStyle,
          },
          "&active": {
            backgroundColor: colors.buttons.primary.ActiveButtonBg,
          },
          "&:focus": {
            border: `2px solid ${colors.buttons.primary.borderColor}`,
          },
        };
    }
  };

  return (
    <Button
      {...props}
      sx={{
        ...getCommonButtonStyles(isMobile),
        textTransform: "none",
        ...getModeStyle(),
        "&:disabled": {
          color: colors.buttons.disabled.BtText,
          border: "none",
        },
        ...disabledStyle,
        ...props.sx,
      }}
      data-cy={props.dataCy}
    >
      {isLoading ? (
        <CircularProgress
          data-testid="progressSpinner"
          color="inherit"
          size={20}
        />
      ) : (
        <>
          <div style={getSpanStyles(props.size || ButtonSize.medium)}>
            {props.text}
          </div>
        </>
      )}
    </Button>
  );
};

export default StyledButton;
