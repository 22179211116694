import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import useGetThemePath from "../../hooks/useGetThemePath";
import { ChevronLeft } from "@mui/icons-material";

interface ListSelectProps {
  label?: string;
  options: Array<{ label: string; isActive: boolean }>;
  handleChangeActiveTab: (v: number) => void;
}

const ListSelect = ({
  label,
  options,
  handleChangeActiveTab,
}: ListSelectProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<string>("All Services");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option: string, index: number) => {
    setSelectedOption(option);
    handleChangeActiveTab(index);

    handleClose();
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: `1px solid ${colors.border.secondary}`,
          height: "32px",
          borderRadius: "4px",
          justifyContent: "center",
          gap: 1,
        }}
        onClick={handleClick}
      >
        <Typography
          fontSize={getREMFromPX(20)}
          sx={{
            fontWeight: "600",
            fontFamily: "Inter",
            color: colors.textPrimary.heading,
          }}
        >
          {label || selectedOption}
        </Typography>
        <ChevronLeft
          sx={{
            rotate: "-90deg",
          }}
          fill={theme[themePath].colors.icons.default.fillColor}
        />
      </Box>
      <Menu
        MenuListProps={{
          sx: {
            width: "calc(100vw - 48px)",
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(({ label }, idx) => (
          <MenuItem
            sx={{
              fontSize: getREMFromPX(20),
              fontFamily: "Inter",
              color: colors.form.primary,
              justifyContent: "center",
              fontWeight: "400",
              height: getREMFromPX(40),
            }}
            selected={selectedOption === label}
            onClick={() => handleOptionSelect(label, idx)}
            key={label}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ListSelect;
