import { Box, Stack, Typography } from "@mui/material";
import useViewport from "../../../hooks/useViewport";
import getREMFromPX from "../../../utils/getREMFromPX";
import EntryPointLink from "../../../components/EntryPointLink/EntryPointLink";
import NewButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";

const CompletedWorkflow = () => {
  const { width, isMobile } = useViewport(600);
  const navigate = useNavigate();

  return (
    <form onSubmit={() => {}}>
      <Stack
        flex={1}
        maxWidth={isMobile ? `${width}px` : "640px"}
        alignItems="center"
        mt={isMobile ? "0px" : "65px"}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: isMobile ? getREMFromPX(30) : getREMFromPX(36),
            fontWeight: 600,
            lineHeight: "44px",
            letterSpacing: "0em",
            marginBottom: isMobile ? "39.6px" : "1.5rem",
            width: isMobile ? "100%" : "567px",
            textAlign: "center",
            paddingTop: isMobile ? "" : "9.4rem",
          }}
        >
          {isMobile ? "Password Reset!" : "Your Password has been reset!"}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(16),
            fontWeight: 500,
            lineHeight: isMobile ? 1.5 : 1.56,
            marginBottom: isMobile ? "56px" : "76px",
            textAlign: "center",
          }}
        >
          Please head to the login page to access your account.
        </Typography>

        <Box
          sx={{
            width: "100%",
            marginTop: isMobile ? "16px" : "100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <NewButton
            text="Login"
            mode="default"
            onClick={() => navigate("/")}
            sx={{
              width: "100%",
              maxWidth: "400px",
              marginBottom: "63px",
              borderRadius: `${isMobile ? "9.3px" : "15px"}`,
              fontWeight: "bold",
            }}
          />
        </Box>
        <div style={{ marginTop: isMobile ? "189px" : "48px" }}>
          <EntryPointLink />
        </div>
      </Stack>
    </form>
  );
};

export default CompletedWorkflow;
