import { ReactNode } from "react";
import { BoxProps, Card, CardContent, styled } from "@mui/material";

const StyledTileCard = styled(Card)`
  display: flex;
  align-items: start;
  justify-content: center;
  align-items: start;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: none;
  border: 1px solid #dbdbdb;
  width: 100%;
  max-width: 300px;
`;

const StyledTileContent = styled(CardContent)`
  width: 100%;
  padding: 0px;
  padding-bottom: 0px !important;
`;

type TileWrapperProps = {
  children?: ReactNode;
} & BoxProps;

const TileWrapper = ({ children }: TileWrapperProps) => {
  return (
    <StyledTileCard>
      <StyledTileContent>{children}</StyledTileContent>
    </StyledTileCard>
  );
};

export default TileWrapper;
