import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";
import { InvoiceData } from "../components/PaymentHistory/interfaces";

interface PaymentHistoryData {
  data: {
    billing: {
      invoices: InvoiceData[];
    };
  };
  duration: number;
  messages: [];
}

export const getPaymentHistory = async (accountId: string) => {
  if (!accountId) {
    return null;
  }
  const response = await fetcher(
    `${endpoints.account}/${accountId}/billing/invoices`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetPaymentHistory = (
  accountId: string,
  options?: UseQueryOptions<
    PaymentHistoryData,
    Error,
    PaymentHistoryData,
    Array<string>
  >,
  // When useGetPaymentHistory and useGetPaymentMethod get called at the same time, we get duplicated Stripe customers.
  // The following is a patch to prevent that from happening.
  // Eventually useGetPaymentHistory should only get called when the PaymentHistory card is getting rendered.
  shouldMakeAPICall: boolean = true
): UseQueryResult<PaymentHistoryData, Error> => {
  const queryResult = useQuery({
    queryKey: ["paymentHistory", accountId],
    queryFn: () => getPaymentHistory(accountId),
    enabled: shouldMakeAPICall,
    ...options,
  });
  return queryResult;
};
