import React from "react";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { getThemePath } from "../../hooks/useGetThemePath";

const themePath = getThemePath();
const colors = theme[themePath].colors;

export const tableStyles = {
  borderCollapse: "collapse",
  width: "100%",
} as React.CSSProperties;

export const tableRowHeaderStyles = {
  background: colors.table.evenCell,
  height: getREMFromPX(theme.spacing * 8),
  padding: `${getREMFromPX(theme.spacing * 2)} 0`,
};

export const expandedRowStyles = {
  textAlign: "center",
} as React.CSSProperties;

export const tableCellStyles = {
  whiteSpace: "nowrap",
  wordBreak: "break-all",
  padding: `${getREMFromPX(theme.spacing * 4)}  ${getREMFromPX(
    theme.spacing * 4
  )} ${getREMFromPX(theme.spacing * 4)} 0`,
} as React.CSSProperties;
