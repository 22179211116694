import { Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import PaymentMethod from "../../components/PaymentMethod";
import formatExpirationDate from "../../utils/formatExpirationDate";
import getREMFromPX from "../../utils/getREMFromPX";
import useViewport from "../../hooks/useViewport";
import useGetThemePath from "../../hooks/useGetThemePath";

export type TypesOfStatus = "Paid" | "Overdue" | "Pending";

interface PaymentMethodProps {
  paymentMethod: any;
  status?: TypesOfStatus;
}

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const PaymentMethodCard = ({ paymentMethod, status }: PaymentMethodProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const currentDate = new Date();
  const { isMobile } = useViewport();
  currentDate.setDate(1);
  currentDate.setMonth(currentDate.getMonth() + 1);
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  return (
    <>
      <Box
        sx={{
          marginTop: {
            md: "48px",
            xs: "16px",
          },
          display: "flex",
          alignItems: isMobile ? "start" : "center",
          flexDirection: isMobile ? "column" : "row",
          gap: getREMFromPX(25),
          marginBottom: status === "Paid" ? "23px" : "0px",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <Box
          sx={{
            minWidth: isMobile ? "200px" : "280px",
          }}
        >
          <PaymentMethod {...paymentMethod} />
        </Box>
        <Box>
          <Typography
            sx={{
              color: colors.dark,
              fontFamily: "Inter",
              fontSize: getREMFromPX(16),
              fontWeight: 500,
            }}
          >
            {paymentMethod?.brand
              ? capitalizeFirstLetter(paymentMethod.brand)
              : "Account"}{" "}
            ending in {paymentMethod?.last4}
          </Typography>
          <Typography
            sx={{
              color: colors.dark,
              fontFamily: "Inter",
              fontSize: getREMFromPX(16),
              fontWeight: 400,
              marginTop: "8px",
              fontStyle: "italic",
            }}
          >
            {/* {paymentMethod?.exp_month &&
              paymentMethod?.exp_year &&
              `Expiry ${formatExpirationDate(
                paymentMethod.exp_month,
                paymentMethod.exp_year
              )}`} */}
            Card to be charged automatically on the 1st of each month.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "22px",
            }}
          >
            <AutorenewIcon
              sx={{
                color: colors.links.teal,
                marginRight: isMobile ? "7px" : "8px",
              }}
              fill={colors.icons.default.fillColor}
            />
            <Typography
              sx={{
                color: colors.textPrimary.heading,
                fontFamily: "Inter",
                fontSize: getREMFromPX(16),
                fontWeight: 500,
              }}
            >
              Autopay on {formattedDate}
            </Typography>
          </Box>
        </Box>
      </Box>
      {status === "Paid" ? (
        <></>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            color: colors.notification.error.primary,
            fontFamily: "Inter",
            fontWeight: "bold",
            fontSize: getREMFromPX(20),
          }}
        >
          Expired
        </Box>
      )}
    </>
  );
};

export default PaymentMethodCard;
