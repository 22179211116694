import { Box, Card, Grid, Button, Typography } from "@mui/material";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { theme } from "../../../../../theme";
import { OverviewDataModel } from "../../../../../components/AllServices/AllServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useViewport from "../../../../../hooks/useViewport";
import { AttachMoney } from "@mui/icons-material";
import useGetThemePath from "../../../../../hooks/useGetThemePath";

const CardContent = ({
  modalOverviewData,
}: {
  modalOverviewData: OverviewDataModel;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { isMobile } = useViewport();
  return (
    <Box>
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(isMobile ? 14 : 20)}
        fontFamily="Inter"
        color={colors.textPrimary.heading}
        fontWeight="bold"
        paddingBottom="19px"
        mt="8px"
      >
        Service Confirmation
      </Typography>
      <Card
        sx={{
          justifyContent: "space-between",
          height: isMobile ? "350px" : "224px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          py: "30px",
          position: "relative",
          border: "1px solid #DBDBDB",
          boxShadow: "none",
          borderRadius: "8px",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              style={{
                padding: "0 20px ",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  aspectRatio: 207 / 63,
                  maxWidth: 300,
                }}
              >
                <img
                  role="presentation"
                  src={`${process.env.PUBLIC_URL}/assets/provider/${modalOverviewData.provider_id}.png`}
                  alt="provider logo"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
              <Box>
                <Typography
                  textAlign="left"
                  fontSize={getREMFromPX(16)}
                  fontFamily="Inter"
                  color={colors.textPrimary.heading}
                  fontWeight={600}
                >
                  {modalOverviewData.fields.Name}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {isMobile && <hr color="#ddd" style={{ width: "80%" }} />}
          <Grid item xs={12} sm={6}>
            <Grid
              container
              justifyContent="center"
              display="flex"
              columnSpacing={2}
              rowSpacing={1.5}
              style={{ margin: "20px" }}
            >
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <FontAwesomeIcon
                    icon={
                      modalOverviewData?.fields_icons?.[1]?.icon as IconProp
                    }
                    fill={colors.icons.default.fillColor}
                    style={{
                      marginRight: "8px",
                    }}
                  />
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Inter"
                    color={colors.textPrimary.heading}
                    fontWeight={600}
                  >
                    Link Speed:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Inter"
                    pl={2}
                    fontWeight={500}
                    color={colors.textPrimary.heading}
                  >
                    {modalOverviewData.fields.LinkSpeed}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <FontAwesomeIcon
                    icon={
                      modalOverviewData?.fields_icons?.[0]?.icon as IconProp
                    }
                    fill={colors.icons.default.fillColor}
                    style={{
                      marginRight: "8px",
                    }}
                  />
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Inter"
                    fontWeight={600}
                    color={colors.textPrimary.heading}
                  >
                    Up:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Inter"
                    pl={2}
                    fontWeight={600}
                    color={colors.textPrimary.heading}
                  >
                    {modalOverviewData.fields.Up}mb/s
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <FontAwesomeIcon
                    icon={
                      modalOverviewData?.fields_icons?.[2]?.icon as IconProp
                    }
                    fill={colors.icons.default.fillColor}
                    style={{
                      marginRight: "8px",
                    }}
                  />

                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontWeight={600}
                    fontFamily="Inter"
                    color={colors.textPrimary.heading}
                  >
                    Down:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontFamily="Inter"
                    pl={2}
                    fontWeight={600}
                    color={colors.textPrimary.heading}
                  >
                    {modalOverviewData.fields.Down}mb/s
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  {modalOverviewData?.fields_icons?.[4]?.icon ? (
                    <FontAwesomeIcon
                      icon={
                        modalOverviewData?.fields_icons?.[4]?.icon as IconProp
                      }
                      fill={colors.icons.default.fillColor}
                      style={{
                        marginRight: "12px",
                      }}
                    />
                  ) : (
                    <AttachMoney
                      sx={{
                        marginLeft: "-4px",
                        marginRight: "4px",
                      }}
                    />
                  )}

                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(16)}
                    fontWeight={700}
                    fontFamily="Inter"
                    color={colors.textPrimary.heading}
                  >
                    Price:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography
                    textAlign="left"
                    fontSize={getREMFromPX(14)}
                    fontFamily="Inter"
                    pl={2}
                    color={colors.textPrimary.heading}
                    fontWeight="bold"
                  >
                    ${modalOverviewData.fields.Price}/month
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {/* <Box sx={{ textAlign: "end", pr: "16px" }}>
        <Typography
          fontSize={getREMFromPX(16)}
          fontFamily="Inter"
          fontWeight="500"
          fontStyle="italic"
          color={"#6d6d6d"}
          py={1}
          pt="26px"
        >
          How much will this affect my billing?
        </Typography>
        <Typography
          fontSize={getREMFromPX(20)}
          fontFamily="Inter"
          fontWeight="600"
          color={"#253331"}
          py={1}
        >
          Estimated Total: $67.07/month
        </Typography>
        <Typography
          fontSize={getREMFromPX(12)}
          fontFamily="Inter"
          fontWeight="500"
          fontStyle="italic"
          color={"#6d6d6d"}
          py="14px"
        >
          *Your Network Operator and Service Provider Fees are billed separately
        </Typography>
      </Box> */}
    </Box>
  );
};

export default CardContent;
