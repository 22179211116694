import { Divider, Stack, Typography } from "@mui/material";
import Button from "../../../../components/Button";
import { Step2Type, Step3Type } from "../../SubscribeToISP";
import {
  headerStyle,
  buttonStyle,
  textStyle,
  agreementSectionStyles,
  iconStyle,
  linkStyle,
} from "./Styles";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { theme } from "../../../../theme";
import Label from "../../../../components/Label";
import Checkbox from "../../../../components/Checkbox";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExternalLink from "../../../../components/ExternalLink";

interface Step2Props {
  goBackOneStep: () => void;
  changeStep: (args: Step2Type) => void;
  previousValues: {
    step2: Step2Type;
    step3: Step3Type;
  };
}

const Step2 = ({ goBackOneStep, changeStep, previousValues }: Step2Props) => {
  const [checked, setChecked] = useState(previousValues.step2.checked);
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  return (
    <Stack spacing={getREMFromPX(theme.spacing * 8)}>
      <Typography component="h1" style={headerStyle}>
        Operator Disclosure
      </Typography>
      <Typography style={textStyle} data-testid="provider_port_warning">
        Continuing with this subscription will assign the service for
        {` ${previousValues.step3.selectedPlan?.provider.Provider?.name}’s `}
        to the port you selected. To gain access to this subscriber portal again
        please visit
        <ExternalLink
          style={linkStyle}
          size="medium"
          href={window.location.origin}
        >
          {window.location.origin}
        </ExternalLink>
        .
      </Typography>
      <Typography data-testid="additional_info_warning">
        Clicking next will connect you directly to
        {` ${previousValues.step3.selectedPlan?.provider.Provider?.name}’s `}
        network where you may be required to complete additional signup for this
        service on their network before access is granted. Any information you
        provide beyond this point will be stored on
        {` ${previousValues.step3.selectedPlan?.provider.Provider?.name}’s systems.`}
      </Typography>
      <Stack
        borderRadius="8px"
        padding={getREMFromPX(theme.spacing * 4)}
        style={agreementSectionStyles}
        spacing={getREMFromPX(theme.spacing * 4)}
      >
        <Typography
          fontWeight={theme.fonts.weights.bolder}
          color={theme[themePath].colors.textPrimary.primary}
        >
          By clicking “Continue” you acknowledge receipt of this disclosure and
          agree to the conditions described.
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          gap={getREMFromPX(theme.spacing * 2)}
        >
          <Checkbox
            id="agreement"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderRadius: "5px",
              },
            }}
          />
          <Label htmlFor="agreement">
            I agree with the operator disclosure
          </Label>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        <Button
          startIcon={
            <FontAwesomeIcon
              icon="angle-left"
              style={iconStyle}
              fill={colors.icons.default.fillColor}
            />
          }
          onClick={goBackOneStep}
          variant="text"
          text="Back"
          size="medium"
        />
        <Button
          data-testid="continue_button"
          onClick={() => changeStep({ checked })}
          text="Continue"
          disabled={!checked}
          size="medium"
          style={buttonStyle}
        />
      </Stack>
    </Stack>
  );
};
export default Step2;
