import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

interface BillingPrice {
  price: {
    active: boolean;
    amount: number;
    description: string;
  };
  quantity: number;
}

interface MyPlanOperatorFees {
  data: {
    billing: {
      subscription: {
        items: BillingPrice[];
        collection_method: string;
      };
    };
  };
  duration: number;
  messages: [];
}

const getMyPlanOperatorFees = async (accountId: string) => {
  if (!accountId) {
    return null;
  }

  // Operator fees
  const response = await fetcher(
    `${endpoints.account}/${accountId}/billing/subscription`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetOperatorFees = (
  accountId: string,
  options?: UseQueryOptions<
    MyPlanOperatorFees,
    Error,
    MyPlanOperatorFees,
    Array<string>
  >
): UseQueryResult<MyPlanOperatorFees, Error> =>
  useQuery(
    ["myPlanOperatorFees", accountId],
    () => getMyPlanOperatorFees(accountId),
    options
  );
