import { Grid } from "@mui/material";
import NavContainer from "../../components/NavContainer";
import FaqCard from "./components/Faq/FaqCard";
import BillingIssues from "./components/BillingIssues";
import DeviceGuide from "./components/DeviceGuide";
import ServiceProviders from "./components/ServiceProviders";
import { useGetConfig } from "../../hooks/useGetConfig";
import { HelpCenterCardStyles } from "./Styles";

export const HelpCenter = () => {
  const cardGridSettings = { item: true, md: 12, lg: 6, sx: { width: "100%" } };

  const { data: config } = useGetConfig();

  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  return (
    <NavContainer title="Help">
      <Grid container spacing={2}>
        <Grid {...cardGridSettings}>
          <ServiceProviders styles={HelpCenterCardStyles} />
        </Grid>
        <Grid {...cardGridSettings}>
          <DeviceGuide styles={HelpCenterCardStyles} />
        </Grid>
        {showBilling && (
          <Grid {...cardGridSettings}>
            <BillingIssues styles={HelpCenterCardStyles} />
          </Grid>
        )}
        <Grid {...cardGridSettings}>
          <FaqCard styles={HelpCenterCardStyles} />
        </Grid>
      </Grid>
    </NavContainer>
  );
};

export default HelpCenter;
