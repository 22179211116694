import { Box, Typography, Checkbox, Link } from "@mui/material";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { theme } from "../../../../../theme";
import sendErrorToast from "../../../../../utils/sendErrorToast";
import { useGetSystemVariables } from "../../../../../hooks/useGetSystemVariables";
import useViewport from "../../../../../hooks/useViewport";
import useGetThemePath from "../../../../../hooks/useGetThemePath";

const TermsContent = ({
  companyName,
  onReceivedDisclosure,
}: {
  companyName: string;
  onReceivedDisclosure: () => void;
  isReceived: boolean;
}) => {
  const { data } = useGetSystemVariables({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "There was an error getting the operator's portal link, please try again"
      ),
  });
  const { isMobile } = useViewport();
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  return (
    <Box>
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(20)}
        fontFamily="Inter"
        color={colors.textPrimary.heading}
        fontWeight="bold"
        py={isMobile ? getREMFromPX(3) : getREMFromPX(11)}
      >
        Operator Disclosure
      </Typography>
      <Typography
        fontSize={getREMFromPX(16)}
        color={"#000000"}
        fontWeight="600"
        py={isMobile ? getREMFromPX(3) : getREMFromPX(11)}
      >
        By signing up for internet service with {companyName}, you will be
        assigning their service to the specific port that you selected during
        the signup process. If you need to access your subscriber portal again,
        you can do so by visiting{"  "}
        <Link
          href={data?.data.system.subscriber_url}
          target="_blank"
          rel="noopener"
          sx={{
            fontSize: getREMFromPX(16),
            textDecorationColor: colors.links.teal,
            py: isMobile ? getREMFromPX(3) : getREMFromPX(12),
          }}
        >
          {data?.data.system.subscriber_url}
        </Link>
        <br />
        <br />
        When you click "Subscribe," you will be directly connected to{" "}
        {companyName}'s network. Depending on their requirements, you may be
        asked to complete additional sign-up steps before you can access the
        internet through their network.
        <br />
        <br />
        Please note that any information you provide from this point forward
        will be stored on {companyName}'s systems.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          id="acknowledge-checkbox"
          onChange={() => onReceivedDisclosure()}
          sx={{
            color: colors.links.teal,
            "&.Mui-checked": {
              color: colors.links.teal,
            },
          }}
        />{" "}
        <Typography
          htmlFor="acknowledge-checkbox"
          component="label"
          fontSize={getREMFromPX(16)}
          fontFamily="Inter"
          color={"#000000"}
          fontWeight="600"
          py={isMobile ? getREMFromPX(5) : getREMFromPX(21)}
        >
          By clicking "Subscribe" you acknowledge receipt of this disclosure and
          agree to the conditions described.
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsContent;
