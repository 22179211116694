import { Typography } from "@mui/material";
import { theme } from "../../theme";
import useGetThemePath from "../../hooks/useGetThemePath";

const ConfirmPasswordValidation = ({
  confirmPassword,
  passwordsMatch,
}: {
  confirmPassword: string;
  passwordsMatch: boolean;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const style = {
    fontFamily: "Inter",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
  };

  const getMessage = () => {
    if (!confirmPassword) {
      return (
        <Typography sx={{ ...style, color: colors.form.primary }}>
          Value must match new password
        </Typography>
      );
    } else if (!passwordsMatch) {
      return (
        <Typography
          sx={{ ...style, color: colors.notification.warning.accent }}
        >
          These passwords do not match
        </Typography>
      );
    } else {
      return (
        <Typography sx={{ ...style, color: colors.textPrimary.headingAlt }}>
          Passwords match!
        </Typography>
      );
    }
  };

  return (
    <>
      <label
        style={{
          width: 0,
          height: 0,
          position: "absolute",
          opacity: 0,
        }}
        htmlFor="confirmPasswordRequirements"
      >
        Confirm Password Requirements
      </label>
      {getMessage()}
    </>
  );
};

export default ConfirmPasswordValidation;
