import { KeyboardEvent, useState } from "react";

import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { SortFilterLabel } from "./interfaces";
import { Box, ClickAwayListener, SxProps, Theme } from "@mui/material";

import FiltersMenu, { menuOptions } from "./FiltersMenu";
import SearchIcon from "@mui/icons-material/Search";

import SelectButton from "../../components/SelectButton/SelectButton";
import sendErrorToast from "../../utils/sendErrorToast";
import fetcher from "../../utils/fetchWrapper";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

export const StyledFilterInput = styled("input")`
  width: 260px;
  height: 32px;
  padding: 10px;
  border-radius: 23.9px;
  border: solid 1px #c4c4c4;
  background-repeat: no-repeat;
  background-position: 10px center;
  font-family: "Inter";
  &::placeholder {
    color: #a9a9a9;
  }
  &:hover {
    outline: 1px solid #fbfbfb;
  }
  &:focus {
    outline: 2px solid #fbfbfb;
  }

  @media (max-width: 1300px) {
    width: 100%;
    marginright: 32px;
  }
`;

export interface TableFilterProps {
  options: Array<any>;
  handleChangeTypeOfView: (val: number) => void;
  setSortFilter: (v: SortFilterLabel | null) => void;
  sortFilter: SortFilterLabel | null;
  handleInputChange?: (val: string) => void;
}

export const TableFilters = ({
  options,
  handleChangeTypeOfView,
  setSortFilter,
  sortFilter,
  handleInputChange = () => {},
}: TableFilterProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const navigate = useNavigate();
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const [accessCode, setAccessCode] = useState("");

  const submit = async () => {
    const loading = "Servicetype,Provider.Contacts,Plans";
    const response = await fetcher(
      `${process.env.REACT_APP_BE_URL}/service/private/${accessCode}?loading=${loading}`
    );
    if (!response.ok) {
      sendErrorToast("There was an error fetching the private service");
    }
    const parsed = await response.json();
    const service = parsed?.data?.service;

    if (service?.servicetype_id) {
      navigate(`/services/${service.servicetype_id}/plans`, { state: service });
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  const [sortByOptions, setSortByOptions] = useState(menuOptions);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
      >
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: 1,
              right: 8,
              color: "#c4c4c4",
              borderLeft: "1px solid #c4c4c4",
              display: "flex",
              alignItems: "center",
              height: 30,
              padding: 0.4,
            }}
          >
            <SearchIcon
              sx={{
                color: "#c4c4c4",
              }}
              fill={colors.icons.default.fillColor}
            />
          </Box>
          <StyledFilterInput
            onChange={(ev) => handleInputChange(ev.target.value)}
            data-cy="services-search-input"
            placeholder="Search"
          />
        </Box>
        <ClickAwayListener onClickAway={() => setShowFiltersMenu(false)}>
          <Box height="30px" width="133px">
            <SelectButton
              content="Sort"
              mode="default"
              onClick={() => setShowFiltersMenu(!showFiltersMenu)}
              dataCy="services-select-sortby"
            />
            {!!showFiltersMenu && (
              <FiltersMenu
                setSortFilter={(arg) => {
                  setShowFiltersMenu(false);
                  setSortFilter(arg);
                }}
                sortFilter={sortFilter}
                sortByOptions={sortByOptions}
                setSortByOptions={(opts) => setSortByOptions(opts)}
              />
            )}
          </Box>
        </ClickAwayListener>
      </Box>
      <Box
        display="flex"
        gap={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledFilterInput
          placeholder="Private Code"
          id="privateAccess"
          onChange={(e) => setAccessCode(e.target.value)}
          value={accessCode}
          onKeyDown={handleKeyDown}
          data-cy="services-input-private-code"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #c4c4c4",
            borderRadius: 20,
            height: 32,
          }}
        >
          {options.map(({ active, label }, idx) => {
            let optionalStyles: SxProps<Theme> = {};
            if (idx === 0) {
              optionalStyles = {
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
              };
            }

            if (idx === options.length - 1) {
              optionalStyles = {
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
              };
            }
            return (
              <Box
                sx={{
                  backgroundColor: active ? colors.form.label.primary : "",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Inter",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: !active
                      ? colors.activeElementBackground
                      : undefined,
                  },
                  height: "100%",
                  width: "90px",
                  fontSize: "0.875rem",
                  ...optionalStyles,
                }}
                key={idx}
                onClick={() => handleChangeTypeOfView(idx)}
              >
                {label}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
