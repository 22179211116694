import { theme, ThemePaths } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const headerStyle = {
  fontSize: getREMFromPX(theme.spacing * 5),
  fontWeight: theme.fonts.weights.bolder,
};

export const getSubHeaderStyle = (themePath: ThemePaths = "default") => ({
  fontSize: getREMFromPX(theme.spacing * 4.5),
  fontWeight: theme.fonts.weights.mediumBold,
  color: theme[themePath].colors.textPrimary.secondary,
});

export const dividerStyle = {
  margin: `${getREMFromPX(theme.spacing * 8)} 0 ${getREMFromPX(
    theme.spacing * 8
  )} 0`,
};

export const iconStyle = {
  marginRight: getREMFromPX(theme.spacing * 2),
};
