import React from "react";
import { styled } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ReportIcon from "@mui/icons-material/Report";
import Tooltip from "@mui/material/Tooltip";

export enum StatusBadgeStatus {
  PAID = "Paid",
  FAILED = "Failed",
  PENDING = "Pending",
}

export interface StatusProps {
  status: StatusBadgeStatus;
  tooltip: string;
}

export const StatusBadge: React.FC<StatusProps> = ({
  status = StatusBadgeStatus.PAID,
  tooltip = "",
}) => {
  let icon: React.ElementType = ReportIcon;
  let label = StatusBadgeStatus.FAILED;
  let backgroundColor = "#ffd2d2";
  let textColor = "#ec0000";

  if (status === StatusBadgeStatus.PAID) {
    icon = DoneIcon;
    label = StatusBadgeStatus.PAID;
    backgroundColor = "#ECFDF3";
    textColor = "#027A48";
  } else {
    icon = ReportIcon;
    label = StatusBadgeStatus.FAILED;
    backgroundColor = "#ffd2d2";
    textColor = "#ec0000";
  }

  const StatusWrapper = styled("span")`
    height: 21px;
    padding: 2px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    vertical-align: middle;
    color: ${textColor};
    background-color: ${backgroundColor};
    border-radius: 16px;
  `;

  return (
    <Tooltip placement="top" aria-label={tooltip} title={tooltip}>
      <StatusWrapper>{label}</StatusWrapper>
    </Tooltip>
  );
};

export default StatusBadge;
