import { useMemo } from "react";
import VisaLogo from "../../assets/visa-logo.webp";
import MasterCardLogo from "../../assets/mastercard-logo.webp";
import AmexLogo from "../../assets/amex-logo.webp";
import { Box, Typography } from "@mui/material";
import { theme } from "../../theme";
import BackgroundImg from "../../assets/credit-card-bg-grey.png";
import { Fragment } from "react";
import formatExpirationDate from "../../utils/formatExpirationDate";
import { BankIcon } from "../../assets/icons";
import useViewport from "../../hooks/useViewport";
import getREMFromPX from "../../utils/getREMFromPX";
import useGetThemePath from "../../hooks/useGetThemePath";

interface PaymentMethodProps {
  type: string;
  last4: string;
  brand?: string;
  exp_month?: number;
  exp_year?: number;
}

const PaymentMethod = ({
  type: paymentType,
  last4,
  brand,
  exp_month,
  exp_year,
}: Partial<PaymentMethodProps>) => {
  const { isMobile } = useViewport();
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const getBrandLogo = useMemo(() => {
    switch (brand) {
      case "visa":
        return VisaLogo;
      case "mastercard":
        return MasterCardLogo;
      case "amex":
        return AmexLogo;
      default:
        return false;
    }
  }, [brand]);

  const logo = brand ? (
    getBrandLogo ? (
      <img
        src={getBrandLogo}
        alt="visa-logo"
        width={isMobile ? "28.3px" : "61.8px"}
        height={isMobile ? "14.4px" : "31.5px"}
      />
    ) : (
      <Typography
        sx={{
          color: colors.white,
          fontFamily: "Inter",
          fontSize: "0.813rem",
          fontWeight: 700,
        }}
      >
        {brand}
      </Typography>
    )
  ) : (
    <BankIcon fill={colors.icons.default.fillColor} />
  );

  return (
    <Box
      sx={{
        backgroundImage: `url(${BackgroundImg})`,
        backgroundColor: "gray",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        borderRadius: "6px",
        marginBottom: "8px",
        padding: isMobile ? getREMFromPX(12.5) : "1.25rem",
        objectFit: "contain",
        aspectRatio: "287/185",
        width: isMobile ? "200px" : "287px",
        height: isMobile ? "100%" : "185px",
        display: {
          sm: "block",
          xs: "none",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            color: colors.white,
            fontFamily: "Inter",
            fontSize: isMobile ? getREMFromPX(7.1) : "1rem",
            fontWeight: 600,
          }}
        >
          {paymentType === "card" ? "Credit Card" : "Bank"}
        </Typography>
        <Box sx={{ marginTop: "0.8rem", marginLeft: "0.625rem" }}>{logo}</Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: isMobile ? getREMFromPX(27.8) : "3.438rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {Array.from({ length: 12 }, (_, i) => i).map((v, idx) => (
            <Fragment key={v}>
              <div
                style={{
                  borderRadius: "6.25rem",
                  backgroundColor: "#fff",
                  width: isMobile ? "0.1rem" : "0.388rem",
                  height: isMobile ? "0.1rem" : "0.388rem",
                }}
              ></div>
              &nbsp;
              {(idx === 3 || idx === 7 || idx === 11) && !isMobile && (
                <div> &nbsp;&nbsp;</div>
              )}
            </Fragment>
          ))}
          <Typography
            sx={{
              color: colors.white,
              fontFamily: "Inter",
              fontSize: isMobile ? getREMFromPX(5.1) : "0.7rem",
              fontWeight: 500,
            }}
          >
            {last4}
          </Typography>
        </Box>
        {exp_month && exp_year && (
          <Typography
            sx={{
              color: colors.white,
              fontFamily: "Inter",
              fontSize: isMobile ? getREMFromPX(4.1) : "0.556rem",
              fontWeight: 600,
              marginTop: "1rem",
            }}
          >
            {formatExpirationDate(exp_month, exp_year)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PaymentMethod;
