import { Fragment } from "react";
import Step from "./Step";
import useViewport from "../../hooks/useViewport";
import styled from "@emotion/styled";
import { Box } from "@mui/material";

interface StepperProps {
  steps: Array<{
    label: string;
    icon: any;
    isActive: boolean;
    isCompleted: boolean;
  }>;
}

const MobileDivision = styled("div")({
  position: "relative",
  width: "20px",
  height: "50px",
  borderRadius: "50%",
  margin: "0 0.2rem",
  "&:not(:last-child)::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "3px",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    borderBottom: `dashed 2px #cbcbcb`,
  },
});

const Stepper = ({ steps = [] }: StepperProps) => {
  const { isMobile } = useViewport(1220);

  return (
    <Box
      sx={{
        height: "100%",
        display: isMobile ? "flex" : "",
        marginTop: isMobile ? "1.5rem" : "",
        justifyContent: isMobile ? "center" : "",
        alignItems: isMobile ? "center" : "",
        px: "28px",
      }}
    >
      {steps?.map(({ icon, label, isActive, isCompleted }, idx) => (
        <Fragment key={idx}>
          <Step
            icon={icon}
            label={label}
            isActive={isActive}
            isCompleted={isCompleted}
            key={idx}
          />
          {idx < steps.length - 1 && !isMobile ? (
            <div
              role="separator"
              style={{
                width: "3px",
                height: "15px",
                margin: "0 27px 0px 232px",
                transform: "rotate(-180deg)",
                borderRight: "dashed 3px #cbcbcb",
                marginLeft: "16.3rem",
              }}
            ></div>
          ) : null}
          {idx < steps.length - 1 && isMobile ? (
            <MobileDivision role="separator" />
          ) : null}
        </Fragment>
      ))}
    </Box>
  );
};

export default Stepper;
