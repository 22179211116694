import React, { createContext, useContext, useEffect, useState } from "react";
import fetcher from "../utils/fetchWrapper";
import { endpoints } from "../api/constants/endpoints";
import { Provider } from "../api/interfaces/Provider";

export type FAQ = { question: string; answer: string; display_order: number };
type SubscriberPortalConfigurationContextType = {
  faqs: FAQ[];
};

const SubscriberPortalConfigurationContext =
  createContext<SubscriberPortalConfigurationContextType>({
    faqs: [],
  });

export function useSubscriberConfig() {
  const context = useContext(SubscriberPortalConfigurationContext);

  if (context === undefined) {
    throw new Error(
      "useSubscriberConfig must be used within a SubscriberPortalConfigurationContextProvider"
    );
  }

  return context;
}

const getProviderConfig = async (): Promise<{
  data: {
    provider: Provider;
  };
}> => {
  const response = await fetcher(`${endpoints.getProvider}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export default function SubscriberPortalConfigurationContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [faqs, setFaqs] = useState<FAQ[]>([]);

  async function parseAndSaveConfig() {
    const data = await getProviderConfig();
    if (data) {
      if (data.data?.provider?.subscriber_portal_config?.faq) {
        const faqData = JSON.parse(
          data.data.provider.subscriber_portal_config.faq
        ) as FAQ[];

        if (faqData) {
          setFaqs(faqData.sort((a, b) => a.display_order - b.display_order));
        }
      }
    }
  }

  useEffect(() => {
    parseAndSaveConfig();
  }, []);

  return (
    <SubscriberPortalConfigurationContext.Provider value={{ faqs }}>
      {children}
    </SubscriberPortalConfigurationContext.Provider>
  );
}
