import { useState } from "react";
import getREMFromPX from "../../utils/getREMFromPX";
import { Stack, Typography } from "@mui/material";
import { Notification as NotificationType } from "../../api/interfaces/Notification";
import { useMarkNotificationReadStatus } from "../../hooks/useMarkNotificationReadStatus";
import styles from "./style.module.css";

const Notification = ({ notification }: { notification: NotificationType }) => {
  const [isNewMessage, setIsNewMessage] = useState(
    notification.read_status === "UNREAD"
  );
  const formattedDate = new Date(notification.created_at).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric",
    }
  );
  const formattedTime = new Date(notification.created_at).toLocaleTimeString(
    "en-US",
    {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }
  );

  const { mutate: markAsRead } = useMarkNotificationReadStatus(
    notification.id,
    {
      onSuccess: (val) => {
        setIsNewMessage(val?.data?.messageportaluser?.read_status === "UNREAD");
      },
    }
  );

  return (
    <Stack
      onClick={markAsRead}
      sx={{
        cursor: isNewMessage ? "pointer" : "auto",
        flexDirection: "row",
        marginBottom: getREMFromPX(25),
      }}
    >
      <Stack
        sx={{
          marginTop: getREMFromPX(7),
          maxWidth: getREMFromPX(30),
          minWidth: getREMFromPX(30),
        }}
      >
        {isNewMessage && (
          <Stack
            sx={{
              width: getREMFromPX(9),
              height: getREMFromPX(9),
              borderRadius: 9,
              backgroundColor: "#55857f",
            }}
          />
        )}
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: getREMFromPX(16),
            fontWeight: "500",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "left",
            color: "#000000",
            maxWidth: getREMFromPX(600),
          }}
        >
          <div
            className={styles.notification}
            dangerouslySetInnerHTML={{ __html: notification.message }}
          />
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: getREMFromPX(16),
            fontWeight: "500",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "left",
            color: "#9b9b9b",
          }}
        >
          {formattedDate} at {formattedTime}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default Notification;
