import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";
import { getThemePath } from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

interface NextButtonProps extends ButtonProps {
  children: React.ReactNode;
}

const themePath = getThemePath();
const colors = theme[themePath].colors;

const StyledButton = styled(Button)<any>(() => ({
  borderRadius: "0 0.3rem 0.3rem 0",
  fontFamily: "Inter",
  padding: "0.5rem 0.75rem",
  fontSize: "0.75rem",
  fontWeight: 500,
  color: colors.table.pagination.primary,
  backgroundColor: "transparent",
  textTransform: "capitalize",
  border: `1px solid ${colors.table.pagination.border.secondary}`,
  "&:hover": {
    backgroundColor: colors.table.pagination.bgHover,
  },
  "&:disabled": {
    color: colors.table.pagination.disabled,
    backgroundColor: colors.table.pagination.bgDisabled,
    cursor: "not-allowed",
  },
  "&:active": {
    backgroundColor: colors.table.pagination.bgActive,
    color: colors.table.pagination.primary,
    borderColor: colors.table.pagination.border.secondary,
  },
}));

const NextButton: React.FC<NextButtonProps> = ({
  children,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      data-cy="nextPageButton"
      aria-label="Go To, Next Page"
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default NextButton;
