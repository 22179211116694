const AaeonDeviceBack = ({
  ports,
  style,
  showCircle,
}: {
  ports: any[] | null | undefined;
  style?: React.CSSProperties;
  showCircle?: boolean;
}) => {
  const lanPorts = ports?.filter(
    (portinterface) =>
      (portinterface?.display === true ||
        portinterface?.data?.portinterface?.display === true) &&
      (portinterface?.type === "LAN" ||
        portinterface?.data?.portinterface?.type === "LAN")
  );
  const lanOnePorts = lanPorts?.filter(
    (portinterface) =>
      portinterface?.display_name === "LAN 1" ||
      portinterface?.data?.portinterface?.display_name === "LAN 1"
  );

  const lanOnePort = lanOnePorts ? lanOnePorts[0] : null;
  const displayLanOne =
    lanOnePort?.data?.portinterface?.live?.link_state === "up";

  const lanTwoPorts = lanPorts?.filter(
    (portinterface) =>
      portinterface?.display_name === "LAN 2" ||
      portinterface?.data?.portinterface?.display_name === "LAN 2"
  );

  const lanTwoPort = lanTwoPorts ? lanTwoPorts[0] : null;
  const displayLanTwo =
    lanTwoPort?.data?.portinterface?.live?.link_state === "up";

  const lanThreePorts = lanPorts?.filter(
    (portinterface) =>
      portinterface?.display_name === "LAN 3" ||
      portinterface?.data?.portinterface?.display_name === "LAN 3"
  );

  const lanThreePort = lanThreePorts ? lanThreePorts[0] : null;
  const displayLanThree =
    lanThreePort?.data?.portinterface?.live?.link_state === "up";

  const lanFourPorts = lanPorts?.filter(
    (portinterface) =>
      portinterface?.display_name === "LAN 4" ||
      portinterface?.data?.portinterface?.display_name === "LAN 4"
  );

  const lanFourPort = lanFourPorts ? lanFourPorts[0] : null;
  const displayLanFour =
    lanFourPort?.data?.portinterface?.live?.link_state === "up";

  const wanPorts = ports?.filter(
    (portinterface) =>
      (portinterface?.display === true ||
        portinterface?.data?.portinterface?.display === true) &&
      (portinterface?.type === "WAN" ||
        portinterface?.data?.portinterface?.type === "WAN")
  );

  const wanPort = wanPorts ? wanPorts[0] : null;

  const wanDisplay =
    wanPort?.data?.portinterface?.live?.link_state === "up" ? "block" : "none";

  const popoverEnter = (popUp: string) => {
    let popUpitem = document.getElementById(popUp + "_popup");
    let highlightItem = document.getElementById(popUp + "_highlight");

    if (popUpitem) {
      popUpitem.style.display = "block";
    }

    if (highlightItem) {
      highlightItem.style.display = "block";
    }
  };

  const popoverLeave = (popUp: string) => {
    let popUpitem = document.getElementById(popUp + "_popup");
    let highlightItem = document.getElementById(popUp + "_highlight");

    if (popUpitem) {
      popUpitem.style.display = "none";
    }

    if (highlightItem) {
      highlightItem.style.display = "none";
    }
  };

  return (
    <svg
      width="100%"
      height="233"
      viewBox={showCircle ? "-100 -120 650 480" : "0 0 550 233"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      {showCircle && <circle cx="160" cy="125" r="250" fill="#D7F1DA" />}
      <path d="M9 33L188 17.5L524.5 70.5L332.5 92L9 33Z" fill="#716F6F" />
      <g filter="url(#filter0_d_3877_17956)">
        <path
          d="M9 122.243V33L332.547 91.7568L524.5 70.5V169.425L332.547 196L9 122.243Z"
          fill="url(#paint0_linear_3877_17956)"
        />
        <path
          d="M332.396 92.5867L332.517 92.6087L332.64 92.5951L523.656 71.4421V168.691L332.584 195.143L9.84351 121.57V34.0105L332.396 92.5867Z"
          stroke="#999999"
          strokeWidth="1.68702"
        />
      </g>
      <path
        d="M113.849 86.6268V101.513L110.084 100.683V85.8839L113.849 86.6268Z"
        fill="#D9D9D9"
        stroke="#D9D9D9"
        strokeWidth="0.168702"
      />
      <path
        d="M132.895 111.756V85.4102L224.353 103.492V131.028L132.895 111.756Z"
        fill="#A9A9A9"
      />
      <ellipse
        cx="4.11965"
        cy="5.43454"
        rx="4.11965"
        ry="5.43454"
        transform="matrix(0.981137 -0.193315 0.194967 0.98081 294 128.593)"
        fill="#D9D9D9"
      />
      <ellipse
        className="WAN"
        style={{ display: wanDisplay }}
        cx="4.11965"
        cy="5.43454"
        rx="4.11965"
        ry="5.43454"
        transform="matrix(0.981137 -0.193315 0.194967 0.98081 294 128.593)"
        fill="#00A3FF"
      />
      <path
        d="M58.323 91.2963V76.2916L73.7204 79.361V94.4919L58.323 91.2963Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.337405"
      />
      <path
        d="M243.208 132.699V118.206L258.605 121.279V135.894L243.208 132.699Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.337405"
      />
      <path
        d="M266.809 137.604V122.895L282.207 125.976V140.793L266.809 137.604Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.337405"
      />
      <path
        d="M139.048 106.978V91.5039L154.277 94.5473V110.139L139.048 106.978Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.506107"
      />
      <path
        d="M160.683 111.47V95.8281L175.911 98.8677V114.633L160.683 111.47Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.506107"
      />
      <path
        d="M182.319 115.966V100.148L197.548 103.192V119.12L182.319 115.966Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.506107"
      />
      <path
        d="M203.954 120.458V104.473L219.183 107.516V123.619L203.954 120.458Z"
        fill="#414141"
        stroke="#D9D9D9"
        strokeWidth="0.506107"
      />
      <path
        d="M41.5952 73.0391L34.5527 74.0225L36.5196 88.7739L44.387 86.807C47.3373 82.8733 46.5123 74.0225 41.5952 73.0391Z"
        fill="#7E8281"
      />
      <ellipse
        cx="36.3791"
        cy="81.3554"
        rx="4.74517"
        ry="7.74012"
        transform="rotate(-4.19759 36.3791 81.3554)"
        fill="#D9D9D9"
      />
      <ellipse
        cx="36.2718"
        cy="81.4023"
        rx="3.73703"
        ry="6.69575"
        transform="rotate(-4.19759 36.2718 81.4023)"
        fill="#A4A0A0"
      />
      {displayLanOne && (
        <>
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M85.0967 85.6497L85.0967 81.0759L100.274 83.9621L100.274 88.5358L85.0967 85.6497Z"
            fill="#4485D1"
            stroke="#CAC5C5"
            strokeWidth="0.337405"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M85.0967 95.7551L85.0967 91.1814L100.274 94.0675L100.274 98.6413L85.0967 95.7551Z"
            fill="#4485D1"
            stroke="#CAC5C5"
            strokeWidth="0.337405"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M86.6146 107.499L84.1151 108.749C74.7548 112.368 78.7446 110.716 69.6113 114.866L72.5982 115.186L73.1201 117.572L75.0532 118.392L93.2846 110.352C92.5428 107.298 89.6754 104.777 86.6146 107.499Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M87.582 117.335C90.0503 116.282 91.6127 115.622 91.6127 115.622C93.366 114.46 93.7564 112.298 93.2838 110.352L75.0525 118.391L74.1632 120.106L74.6106 121.895L72.7422 124.11C72.7422 124.11 81.2241 120.047 87.582 117.335Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M91.6135 115.622C96.1185 112.637 91.6257 103.043 86.6147 107.499L84.1152 108.749C84.5142 106.55 85.9898 105.624 85.9898 105.624C86.7774 105.137 87.5312 104.838 88.2433 104.697C90.7974 104.189 92.8149 105.713 93.9298 107.892C95.7813 111.509 95.1438 116.933 90.3416 117.859C90.3416 117.859 88.6267 117.934 87.5828 117.335C90.0511 116.282 91.6135 115.622 91.6135 115.622Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M102.683 98.7516C106.753 97.2777 116.43 101.876 117.054 102.501L93.9287 107.892C92.8138 105.714 90.7963 104.19 88.2422 104.697L102.683 98.7516Z"
            fill="#51B0EF"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M117.582 116.607L90.3418 117.859C95.1439 116.933 95.7814 111.509 93.93 107.891L117.056 102.5C117.68 103.125 118.649 111.846 117.582 116.607Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M136.426 115.622C141.392 119.736 150.166 118.128 150.508 117.328C150.851 116.528 151.422 113.123 151.422 113.123L136.426 115.622Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M102.684 98.7514L111.208 94.08C116.725 93.4924 130.306 95.8895 130.802 97.5017L117.055 102.501C116.431 101.876 106.754 97.2775 102.684 98.7514Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M111.207 94.0799C111.207 94.0799 134.288 89.7803 136.344 90.0088C138.7 90.0088 152.215 92.6627 154.352 93.4942L130.801 97.5016C130.306 95.8894 116.724 93.4923 111.207 94.0799Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M130.789 116.839C129.304 117.182 117.581 116.608 117.581 116.608C118.648 111.847 117.679 103.126 117.055 102.501L130.801 97.502C131.131 98.5735 130.907 110.054 130.789 116.839Z"
            fill="#2075AD"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M154.352 93.4941C155.152 95.1101 155.346 111.538 154.546 112.469L151.421 113.123L136.425 115.622C134.257 116.471 132.242 116.678 131.295 116.776L131.295 116.776C131.049 116.801 130.875 116.819 130.789 116.839C130.907 110.054 131.131 98.573 130.801 97.5015L154.352 93.4941Z"
            fill="#195C88"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M86.6146 107.499L84.1151 108.749C74.7548 112.368 78.7446 110.716 69.6113 114.866L72.5982 115.186L73.1201 117.572L75.0532 118.392L93.2846 110.352C92.5428 107.298 89.6754 104.777 86.6146 107.499Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M87.582 117.335C90.0503 116.282 91.6127 115.622 91.6127 115.622C93.366 114.46 93.7564 112.298 93.2838 110.352L75.0525 118.391L74.1632 120.106L74.6106 121.895L72.7422 124.11C72.7422 124.11 81.2241 120.047 87.582 117.335Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M91.6135 115.622C96.1185 112.637 91.6257 103.043 86.6147 107.499L84.1152 108.749C84.5142 106.55 85.9898 105.624 85.9898 105.624C86.7774 105.137 87.5312 104.838 88.2433 104.697C90.7974 104.189 92.8149 105.713 93.9298 107.892C95.7813 111.509 95.1438 116.933 90.3416 117.859C90.3416 117.859 88.6267 117.934 87.5828 117.335C90.0511 116.282 91.6135 115.622 91.6135 115.622Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M102.683 98.7516C106.753 97.2777 116.43 101.876 117.054 102.501L93.9287 107.892C92.8138 105.714 90.7963 104.19 88.2422 104.697L102.683 98.7516Z"
            fill="#51B0EF"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M117.582 116.607L90.3418 117.859C95.1439 116.933 95.7814 111.509 93.93 107.891L117.056 102.5C117.68 103.125 118.649 111.846 117.582 116.607Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M136.424 115.622C141.39 119.736 150.164 118.128 150.507 117.328C150.849 116.528 151.42 113.123 151.42 113.123L136.424 115.622Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M102.684 98.7514L111.208 94.08C116.725 93.4924 130.306 95.8895 130.802 97.5017L117.055 102.501C116.431 101.876 106.754 97.2775 102.684 98.7514Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M111.207 94.0799C111.207 94.0799 134.288 89.7803 136.344 90.0088C138.7 90.0088 152.215 92.6627 154.352 93.4942L130.801 97.5016C130.306 95.8894 116.724 93.4923 111.207 94.0799Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M130.789 116.839C129.304 117.182 117.581 116.608 117.581 116.608C118.648 111.847 117.679 103.126 117.055 102.501L130.801 97.502C131.131 98.5735 130.907 110.054 130.789 116.839Z"
            fill="#2075AD"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_1")}
            className="LAN_1"
            d="M154.352 93.4941C155.152 95.1101 155.346 111.538 154.546 112.469L151.421 113.123L136.425 115.622C134.257 116.471 132.242 116.678 131.295 116.776L131.295 116.776C131.049 116.801 130.875 116.819 130.789 116.839C130.907 110.054 131.131 98.573 130.801 97.5015L154.352 93.4941Z"
            fill="#195C88"
          />
        </>
      )}
      <mask
        id="mask0_3877_17956"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="69"
        y="90"
        width="87"
        height="35"
      >
        <path
          d="M86.6146 107.499L84.1151 108.749C74.7548 112.368 78.7446 110.716 69.6113 114.866L72.5982 115.186L73.1201 117.572L75.0532 118.392L93.2846 110.352C92.5428 107.298 89.6754 104.777 86.6146 107.499Z"
          fill="#47A7E7"
        />
        <path
          d="M87.582 117.335C90.0503 116.282 91.6127 115.622 91.6127 115.622C93.366 114.46 93.7564 112.298 93.2838 110.352L75.0525 118.391L74.1632 120.106L74.6106 121.895L72.7422 124.11C72.7422 124.11 81.2241 120.047 87.582 117.335Z"
          fill="#2482C1"
        />
        <path
          d="M91.6135 115.622C96.1185 112.637 91.6257 103.043 86.6147 107.499L84.1152 108.749C84.5142 106.55 85.9898 105.624 85.9898 105.624C86.7774 105.137 87.5312 104.838 88.2433 104.697C90.7974 104.189 92.8149 105.713 93.9298 107.892C95.7813 111.509 95.1438 116.933 90.3416 117.859C90.3416 117.859 88.6267 117.934 87.5828 117.335C90.0511 116.282 91.6135 115.622 91.6135 115.622Z"
          fill="#2696E0"
        />
        <path
          d="M102.683 98.7516C106.753 97.2777 116.43 101.876 117.054 102.501L93.9287 107.892C92.8138 105.714 90.7963 104.19 88.2422 104.697L102.683 98.7516Z"
          fill="#51B0EF"
        />
        <path
          d="M117.582 116.607L90.3418 117.859C95.1439 116.933 95.7814 111.509 93.93 107.891L117.056 102.5C117.68 103.125 118.649 111.846 117.582 116.607Z"
          fill="#2482C1"
        />
        <path
          d="M136.424 115.622C141.39 119.736 150.164 118.128 150.507 117.328C150.849 116.528 151.42 113.123 151.42 113.123L136.424 115.622Z"
          fill="#2482C1"
        />
        <path
          d="M102.684 98.7514L111.208 94.08C116.725 93.4924 130.306 95.8895 130.802 97.5017L117.055 102.501C116.431 101.876 106.754 97.2775 102.684 98.7514Z"
          fill="#47A7E7"
        />
        <path
          d="M111.207 94.0799C111.207 94.0799 134.288 89.7803 136.344 90.0088C138.7 90.0088 152.215 92.6627 154.352 93.4942L130.801 97.5016C130.306 95.8894 116.724 93.4923 111.207 94.0799Z"
          fill="#2696E0"
        />
        <path
          d="M130.789 116.839C129.304 117.182 117.581 116.608 117.581 116.608C118.648 111.847 117.679 103.126 117.055 102.501L130.801 97.502C131.131 98.5735 130.907 110.054 130.789 116.839Z"
          fill="#2075AD"
        />
        <path
          d="M154.352 93.4941C155.152 95.1101 155.346 111.538 154.546 112.469L151.421 113.123L136.425 115.622C134.257 116.471 132.242 116.678 131.295 116.776L131.295 116.776C131.049 116.801 130.875 116.819 130.789 116.839C130.907 110.054 131.131 98.573 130.801 97.5015L154.352 93.4941Z"
          fill="#195C88"
        />
      </mask>
      <g
        id="LAN_1_highlight"
        onMouseLeave={() => popoverLeave("LAN_1")}
        style={{ display: "none" }}
        mask="url(#mask0_3877_17956)"
      >
        <g style={{ mixBlendMode: "hue" }} opacity="0.65">
          <rect
            x="55.9961"
            y="89.002"
            width="99.8533"
            height="40.9325"
            fill="#41CE3E"
          />
        </g>
      </g>
      {displayLanTwo && (
        <>
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M108.615 111.499L106.115 112.749C96.7548 116.368 100.745 114.716 91.6113 118.866L94.5982 119.186L95.1201 121.572L97.0532 122.392L115.285 114.352C114.543 111.298 111.675 108.777 108.615 111.499Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M109.582 121.335C112.05 120.282 113.613 119.622 113.613 119.622C115.366 118.46 115.756 116.298 115.284 114.352L97.0525 122.391L96.1632 124.106L96.6106 125.895L94.7422 128.11C94.7422 128.11 103.224 124.047 109.582 121.335Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M113.614 119.622C118.119 116.637 113.626 107.043 108.615 111.499L106.115 112.749C106.514 110.55 107.99 109.624 107.99 109.624C108.777 109.137 109.531 108.838 110.243 108.697C112.797 108.189 114.815 109.713 115.93 111.892C117.781 115.509 117.144 120.933 112.342 121.859C112.342 121.859 110.627 121.934 109.583 121.335C112.051 120.282 113.614 119.622 113.614 119.622Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M124.683 102.752C128.753 101.278 138.43 105.876 139.054 106.501L115.929 111.892C114.814 109.714 112.796 108.19 110.242 108.697L124.683 102.752Z"
            fill="#51B0EF"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M139.582 120.607L112.342 121.859C117.144 120.933 117.781 115.509 115.93 111.891L139.056 106.5C139.68 107.125 140.649 115.846 139.582 120.607Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M158.426 119.622C163.392 123.736 172.166 122.128 172.508 121.328C172.851 120.528 173.422 117.123 173.422 117.123L158.426 119.622Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M124.684 102.751L133.208 98.08C138.725 97.4924 152.306 99.8895 152.802 101.502L139.055 106.501C138.431 105.876 128.754 101.278 124.684 102.751Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M133.207 98.0799C133.207 98.0799 156.288 93.7803 158.344 94.0088C160.7 94.0088 174.215 96.6627 176.352 97.4942L152.801 101.502C152.306 99.8894 138.724 97.4923 133.207 98.0799Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M152.789 120.839C151.304 121.182 139.581 120.608 139.581 120.608C140.648 115.847 139.679 107.126 139.055 106.501L152.801 101.502C153.131 102.574 152.907 114.054 152.789 120.839Z"
            fill="#2075AD"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M176.352 97.4941C177.152 99.1101 177.346 115.538 176.546 116.469L173.421 117.123L158.425 119.622C156.257 120.471 154.242 120.678 153.295 120.776L153.295 120.776C153.049 120.801 152.875 120.819 152.789 120.839C152.907 114.054 153.131 102.573 152.801 101.501L176.352 97.4941Z"
            fill="#195C88"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M108.615 111.499L106.115 112.749C96.7548 116.368 100.745 114.716 91.6113 118.866L94.5982 119.186L95.1201 121.572L97.0532 122.392L115.285 114.352C114.543 111.298 111.675 108.777 108.615 111.499Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M109.582 121.335C112.05 120.282 113.613 119.622 113.613 119.622C115.366 118.46 115.756 116.298 115.284 114.352L97.0525 122.391L96.1632 124.106L96.6106 125.895L94.7422 128.11C94.7422 128.11 103.224 124.047 109.582 121.335Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M113.614 119.622C118.119 116.637 113.626 107.043 108.615 111.499L106.115 112.749C106.514 110.55 107.99 109.624 107.99 109.624C108.777 109.137 109.531 108.838 110.243 108.697C112.797 108.189 114.815 109.713 115.93 111.892C117.781 115.509 117.144 120.933 112.342 121.859C112.342 121.859 110.627 121.934 109.583 121.335C112.051 120.282 113.614 119.622 113.614 119.622Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M124.683 102.752C128.753 101.278 138.43 105.876 139.054 106.501L115.929 111.892C114.814 109.714 112.796 108.19 110.242 108.697L124.683 102.752Z"
            fill="#51B0EF"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M139.582 120.607L112.342 121.859C117.144 120.933 117.781 115.509 115.93 111.891L139.056 106.5C139.68 107.125 140.649 115.846 139.582 120.607Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M158.424 119.622C163.39 123.736 172.164 122.128 172.507 121.328C172.849 120.528 173.42 117.123 173.42 117.123L158.424 119.622Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M124.684 102.751L133.208 98.08C138.725 97.4924 152.306 99.8895 152.802 101.502L139.055 106.501C138.431 105.876 128.754 101.278 124.684 102.751Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M133.207 98.0799C133.207 98.0799 156.288 93.7803 158.344 94.0088C160.7 94.0088 174.215 96.6627 176.352 97.4942L152.801 101.502C152.306 99.8894 138.724 97.4923 133.207 98.0799Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M152.789 120.839C151.304 121.182 139.581 120.608 139.581 120.608C140.648 115.847 139.679 107.126 139.055 106.501L152.801 101.502C153.131 102.574 152.907 114.054 152.789 120.839Z"
            fill="#2075AD"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_2")}
            className="LAN_2"
            d="M176.352 97.4941C177.152 99.1101 177.346 115.538 176.546 116.469L173.421 117.123L158.425 119.622C156.257 120.471 154.242 120.678 153.295 120.776L153.295 120.776C153.049 120.801 152.875 120.819 152.789 120.839C152.907 114.054 153.131 102.573 152.801 101.501L176.352 97.4941Z"
            fill="#195C88"
          />
        </>
      )}
      <mask
        id="mask1_3877_17956"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="91"
        y="94"
        width="87"
        height="35"
      >
        <path
          d="M108.615 111.499L106.115 112.749C96.7548 116.368 100.745 114.716 91.6113 118.866L94.5982 119.186L95.1201 121.572L97.0532 122.392L115.285 114.352C114.543 111.298 111.675 108.777 108.615 111.499Z"
          fill="#47A7E7"
        />
        <path
          d="M109.582 121.335C112.05 120.282 113.613 119.622 113.613 119.622C115.366 118.46 115.756 116.298 115.284 114.352L97.0525 122.391L96.1632 124.106L96.6106 125.895L94.7422 128.11C94.7422 128.11 103.224 124.047 109.582 121.335Z"
          fill="#2482C1"
        />
        <path
          d="M113.614 119.622C118.119 116.637 113.626 107.043 108.615 111.499L106.115 112.749C106.514 110.55 107.99 109.624 107.99 109.624C108.777 109.137 109.531 108.838 110.243 108.697C112.797 108.189 114.815 109.713 115.93 111.892C117.781 115.509 117.144 120.933 112.342 121.859C112.342 121.859 110.627 121.934 109.583 121.335C112.051 120.282 113.614 119.622 113.614 119.622Z"
          fill="#2696E0"
        />
        <path
          d="M124.683 102.752C128.753 101.278 138.43 105.876 139.054 106.501L115.929 111.892C114.814 109.714 112.796 108.19 110.242 108.697L124.683 102.752Z"
          fill="#51B0EF"
        />
        <path
          d="M139.582 120.607L112.342 121.859C117.144 120.933 117.781 115.509 115.93 111.891L139.056 106.5C139.68 107.125 140.649 115.846 139.582 120.607Z"
          fill="#2482C1"
        />
        <path
          d="M158.424 119.622C163.39 123.736 172.164 122.128 172.507 121.328C172.849 120.528 173.42 117.123 173.42 117.123L158.424 119.622Z"
          fill="#2482C1"
        />
        <path
          d="M124.684 102.751L133.208 98.08C138.725 97.4924 152.306 99.8895 152.802 101.502L139.055 106.501C138.431 105.876 128.754 101.278 124.684 102.751Z"
          fill="#47A7E7"
        />
        <path
          d="M133.207 98.0799C133.207 98.0799 156.288 93.7803 158.344 94.0088C160.7 94.0088 174.215 96.6627 176.352 97.4942L152.801 101.502C152.306 99.8894 138.724 97.4923 133.207 98.0799Z"
          fill="#2696E0"
        />
        <path
          d="M152.789 120.839C151.304 121.182 139.581 120.608 139.581 120.608C140.648 115.847 139.679 107.126 139.055 106.501L152.801 101.502C153.131 102.574 152.907 114.054 152.789 120.839Z"
          fill="#2075AD"
        />
        <path
          d="M176.352 97.4941C177.152 99.1101 177.346 115.538 176.546 116.469L173.421 117.123L158.425 119.622C156.257 120.471 154.242 120.678 153.295 120.776L153.295 120.776C153.049 120.801 152.875 120.819 152.789 120.839C152.907 114.054 153.131 102.573 152.801 101.501L176.352 97.4941Z"
          fill="#195C88"
        />
      </mask>
      <g
        id="LAN_2_highlight"
        onMouseLeave={() => popoverLeave("LAN_2")}
        style={{ display: "none" }}
        mask="url(#mask1_3877_17956)"
      >
        <g style={{ mixBlendMode: "hue" }} opacity="0.65">
          <rect
            x="77.9961"
            y="93.002"
            width="99.8533"
            height="40.9325"
            fill="#41CE3E"
          />
        </g>
      </g>
      {displayLanThree && (
        <>
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M130.615 115.499L128.115 116.749C118.755 120.368 122.745 118.716 113.611 122.866L116.598 123.186L117.12 125.572L119.053 126.392L137.285 118.352C136.543 115.298 133.675 112.777 130.615 115.499Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M131.582 125.335C134.05 124.282 135.613 123.622 135.613 123.622C137.366 122.46 137.756 120.298 137.284 118.352L119.052 126.391L118.163 128.106L118.611 129.895L116.742 132.11C116.742 132.11 125.224 128.047 131.582 125.335Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M135.614 123.622C140.119 120.637 135.626 111.043 130.615 115.499L128.115 116.749C128.514 114.55 129.99 113.624 129.99 113.624C130.777 113.137 131.531 112.838 132.243 112.697C134.797 112.189 136.815 113.713 137.93 115.892C139.781 119.509 139.144 124.933 134.342 125.859C134.342 125.859 132.627 125.934 131.583 125.335C134.051 124.282 135.614 123.622 135.614 123.622Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M146.683 106.752C150.753 105.278 160.43 109.876 161.054 110.501L137.929 115.892C136.814 113.714 134.796 112.19 132.242 112.697L146.683 106.752Z"
            fill="#51B0EF"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M161.582 124.607L134.342 125.859C139.144 124.933 139.781 119.509 137.93 115.891L161.056 110.5C161.68 111.125 162.649 119.846 161.582 124.607Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M180.426 123.622C185.392 127.736 194.166 126.128 194.508 125.328C194.851 124.528 195.422 121.123 195.422 121.123L180.426 123.622Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M146.684 106.751L155.208 102.08C160.725 101.492 174.306 103.889 174.802 105.502L161.055 110.501C160.431 109.876 150.754 105.278 146.684 106.751Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M155.207 102.08C155.207 102.08 178.288 97.7803 180.344 98.0088C182.7 98.0088 196.215 100.663 198.352 101.494L174.801 105.502C174.306 103.889 160.724 101.492 155.207 102.08Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M174.789 124.839C173.304 125.182 161.581 124.608 161.581 124.608C162.648 119.847 161.679 111.126 161.055 110.501L174.801 105.502C175.131 106.574 174.907 118.054 174.789 124.839Z"
            fill="#2075AD"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M198.352 101.494C199.152 103.11 199.346 119.538 198.546 120.469L195.421 121.123L180.425 123.622C178.257 124.471 176.242 124.678 175.295 124.776L175.295 124.776C175.049 124.801 174.875 124.819 174.789 124.839C174.907 118.054 175.131 106.573 174.801 105.501L198.352 101.494Z"
            fill="#195C88"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M130.615 115.499L128.115 116.749C118.755 120.368 122.745 118.716 113.611 122.866L116.598 123.186L117.12 125.572L119.053 126.392L137.285 118.352C136.543 115.298 133.675 112.777 130.615 115.499Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M131.582 125.335C134.05 124.282 135.613 123.622 135.613 123.622C137.366 122.46 137.756 120.298 137.284 118.352L119.052 126.391L118.163 128.106L118.611 129.895L116.742 132.11C116.742 132.11 125.224 128.047 131.582 125.335Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M135.614 123.622C140.119 120.637 135.626 111.043 130.615 115.499L128.115 116.749C128.514 114.55 129.99 113.624 129.99 113.624C130.777 113.137 131.531 112.838 132.243 112.697C134.797 112.189 136.815 113.713 137.93 115.892C139.781 119.509 139.144 124.933 134.342 125.859C134.342 125.859 132.627 125.934 131.583 125.335C134.051 124.282 135.614 123.622 135.614 123.622Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M146.683 106.752C150.753 105.278 160.43 109.876 161.054 110.501L137.929 115.892C136.814 113.714 134.796 112.19 132.242 112.697L146.683 106.752Z"
            fill="#51B0EF"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M161.582 124.607L134.342 125.859C139.144 124.933 139.781 119.509 137.93 115.891L161.056 110.5C161.68 111.125 162.649 119.846 161.582 124.607Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M180.424 123.622C185.39 127.736 194.164 126.128 194.507 125.328C194.849 124.528 195.42 121.123 195.42 121.123L180.424 123.622Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M146.684 106.751L155.208 102.08C160.725 101.492 174.306 103.889 174.802 105.502L161.055 110.501C160.431 109.876 150.754 105.278 146.684 106.751Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M155.207 102.08C155.207 102.08 178.288 97.7803 180.344 98.0088C182.7 98.0088 196.215 100.663 198.352 101.494L174.801 105.502C174.306 103.889 160.724 101.492 155.207 102.08Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M174.789 124.839C173.304 125.182 161.581 124.608 161.581 124.608C162.648 119.847 161.679 111.126 161.055 110.501L174.801 105.502C175.131 106.574 174.907 118.054 174.789 124.839Z"
            fill="#2075AD"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_3")}
            className="LAN_3"
            d="M198.352 101.494C199.152 103.11 199.346 119.538 198.546 120.469L195.421 121.123L180.425 123.622C178.257 124.471 176.242 124.678 175.295 124.776L175.295 124.776C175.049 124.801 174.875 124.819 174.789 124.839C174.907 118.054 175.131 106.573 174.801 105.501L198.352 101.494Z"
            fill="#195C88"
          />
        </>
      )}
      <mask
        id="mask2_3877_17956"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="113"
        y="98"
        width="87"
        height="35"
      >
        <path
          d="M130.615 115.499L128.115 116.749C118.755 120.368 122.745 118.716 113.611 122.866L116.598 123.186L117.12 125.572L119.053 126.392L137.285 118.352C136.543 115.298 133.675 112.777 130.615 115.499Z"
          fill="#47A7E7"
        />
        <path
          d="M131.582 125.335C134.05 124.282 135.613 123.622 135.613 123.622C137.366 122.46 137.756 120.298 137.284 118.352L119.052 126.391L118.163 128.106L118.611 129.895L116.742 132.11C116.742 132.11 125.224 128.047 131.582 125.335Z"
          fill="#2482C1"
        />
        <path
          d="M135.614 123.622C140.119 120.637 135.626 111.043 130.615 115.499L128.115 116.749C128.514 114.55 129.99 113.624 129.99 113.624C130.777 113.137 131.531 112.838 132.243 112.697C134.797 112.189 136.815 113.713 137.93 115.892C139.781 119.509 139.144 124.933 134.342 125.859C134.342 125.859 132.627 125.934 131.583 125.335C134.051 124.282 135.614 123.622 135.614 123.622Z"
          fill="#2696E0"
        />
        <path
          d="M146.683 106.752C150.753 105.278 160.43 109.876 161.054 110.501L137.929 115.892C136.814 113.714 134.796 112.19 132.242 112.697L146.683 106.752Z"
          fill="#51B0EF"
        />
        <path
          d="M161.582 124.607L134.342 125.859C139.144 124.933 139.781 119.509 137.93 115.891L161.056 110.5C161.68 111.125 162.649 119.846 161.582 124.607Z"
          fill="#2482C1"
        />
        <path
          d="M180.424 123.622C185.39 127.736 194.164 126.128 194.507 125.328C194.849 124.528 195.42 121.123 195.42 121.123L180.424 123.622Z"
          fill="#2482C1"
        />
        <path
          d="M146.684 106.751L155.208 102.08C160.725 101.492 174.306 103.889 174.802 105.502L161.055 110.501C160.431 109.876 150.754 105.278 146.684 106.751Z"
          fill="#47A7E7"
        />
        <path
          d="M155.207 102.08C155.207 102.08 178.288 97.7803 180.344 98.0088C182.7 98.0088 196.215 100.663 198.352 101.494L174.801 105.502C174.306 103.889 160.724 101.492 155.207 102.08Z"
          fill="#2696E0"
        />
        <path
          d="M174.789 124.839C173.304 125.182 161.581 124.608 161.581 124.608C162.648 119.847 161.679 111.126 161.055 110.501L174.801 105.502C175.131 106.574 174.907 118.054 174.789 124.839Z"
          fill="#2075AD"
        />
        <path
          d="M198.352 101.494C199.152 103.11 199.346 119.538 198.546 120.469L195.421 121.123L180.425 123.622C178.257 124.471 176.242 124.678 175.295 124.776L175.295 124.776C175.049 124.801 174.875 124.819 174.789 124.839C174.907 118.054 175.131 106.573 174.801 105.501L198.352 101.494Z"
          fill="#195C88"
        />
      </mask>
      <g
        id="LAN_3_highlight"
        onMouseLeave={() => popoverLeave("LAN_3")}
        style={{ display: "none" }}
        mask="url(#mask2_3877_17956)"
      >
        <g style={{ mixBlendMode: "hue" }} opacity="0.65">
          <rect
            x="99.9961"
            y="97.002"
            width="99.8533"
            height="40.9325"
            fill="#41CE3E"
          />
        </g>
      </g>
      {displayLanFour && (
        <>
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M153.615 119.499L151.115 120.749C141.755 124.368 145.745 122.716 136.611 126.866L139.598 127.186L140.12 129.572L142.053 130.392L160.285 122.352C159.543 119.298 156.675 116.777 153.615 119.499Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M154.582 129.335C157.05 128.282 158.613 127.622 158.613 127.622C160.366 126.46 160.756 124.298 160.284 122.352L142.052 130.391L141.163 132.106L141.611 133.895L139.742 136.11C139.742 136.11 148.224 132.047 154.582 129.335Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M158.614 127.622C163.119 124.637 158.626 115.043 153.615 119.499L151.115 120.749C151.514 118.55 152.99 117.624 152.99 117.624C153.777 117.137 154.531 116.838 155.243 116.697C157.797 116.189 159.815 117.713 160.93 119.892C162.781 123.509 162.144 128.933 157.342 129.859C157.342 129.859 155.627 129.934 154.583 129.335C157.051 128.282 158.614 127.622 158.614 127.622Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M169.683 110.752C173.753 109.278 183.43 113.876 184.054 114.501L160.929 119.892C159.814 117.714 157.796 116.19 155.242 116.697L169.683 110.752Z"
            fill="#51B0EF"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M184.582 128.607L157.342 129.859C162.144 128.933 162.781 123.509 160.93 119.891L184.056 114.5C184.68 115.125 185.649 123.846 184.582 128.607Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M203.424 127.622C208.39 131.736 217.164 130.128 217.507 129.328C217.849 128.528 218.42 125.123 218.42 125.123L203.424 127.622Z"
            fill="#2482C1"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M169.684 110.751L178.208 106.08C183.725 105.492 197.306 107.889 197.802 109.502L184.055 114.501C183.431 113.876 173.754 109.278 169.684 110.751Z"
            fill="#47A7E7"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M178.207 106.08C178.207 106.08 201.288 101.78 203.344 102.009C205.7 102.009 219.215 104.663 221.352 105.494L197.801 109.502C197.306 107.889 183.724 105.492 178.207 106.08Z"
            fill="#2696E0"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M197.789 128.839C196.304 129.182 184.581 128.608 184.581 128.608C185.648 123.847 184.679 115.126 184.055 114.501L197.801 109.502C198.131 110.574 197.907 122.054 197.789 128.839Z"
            fill="#2075AD"
          />
          <path
            onMouseEnter={() => popoverEnter("LAN_4")}
            className="LAN_4"
            d="M221.352 105.494C222.152 107.11 222.346 123.538 221.546 124.469L218.421 125.123L203.425 127.622C201.257 128.471 199.242 128.678 198.295 128.776L198.295 128.776C198.049 128.801 197.875 128.819 197.789 128.839C197.907 122.054 198.131 110.573 197.801 109.501L221.352 105.494Z"
            fill="#195C88"
          />
        </>
      )}
      <mask
        id="mask3_3877_17956"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="136"
        y="102"
        width="87"
        height="35"
      >
        <path
          d="M153.615 119.499L151.115 120.749C141.755 124.368 145.745 122.716 136.611 126.866L139.598 127.186L140.12 129.572L142.053 130.392L160.285 122.352C159.543 119.298 156.675 116.777 153.615 119.499Z"
          fill="#47A7E7"
        />
        <path
          d="M154.582 129.335C157.05 128.282 158.613 127.622 158.613 127.622C160.366 126.46 160.756 124.298 160.284 122.352L142.052 130.391L141.163 132.106L141.611 133.895L139.742 136.11C139.742 136.11 148.224 132.047 154.582 129.335Z"
          fill="#2482C1"
        />
        <path
          d="M158.614 127.622C163.119 124.637 158.626 115.043 153.615 119.499L151.115 120.749C151.514 118.55 152.99 117.624 152.99 117.624C153.777 117.137 154.531 116.838 155.243 116.697C157.797 116.189 159.815 117.713 160.93 119.892C162.781 123.509 162.144 128.933 157.342 129.859C157.342 129.859 155.627 129.934 154.583 129.335C157.051 128.282 158.614 127.622 158.614 127.622Z"
          fill="#2696E0"
        />
        <path
          d="M169.683 110.752C173.753 109.278 183.43 113.876 184.054 114.501L160.929 119.892C159.814 117.714 157.796 116.19 155.242 116.697L169.683 110.752Z"
          fill="#51B0EF"
        />
        <path
          d="M184.582 128.607L157.342 129.859C162.144 128.933 162.781 123.509 160.93 119.891L184.056 114.5C184.68 115.125 185.649 123.846 184.582 128.607Z"
          fill="#2482C1"
        />
        <path
          d="M203.424 127.622C208.39 131.736 217.164 130.128 217.507 129.328C217.849 128.528 218.42 125.123 218.42 125.123L203.424 127.622Z"
          fill="#2482C1"
        />
        <path
          d="M169.684 110.751L178.208 106.08C183.725 105.492 197.306 107.889 197.802 109.502L184.055 114.501C183.431 113.876 173.754 109.278 169.684 110.751Z"
          fill="#47A7E7"
        />
        <path
          d="M178.207 106.08C178.207 106.08 201.288 101.78 203.344 102.009C205.7 102.009 219.215 104.663 221.352 105.494L197.801 109.502C197.306 107.889 183.724 105.492 178.207 106.08Z"
          fill="#2696E0"
        />
        <path
          d="M197.789 128.839C196.304 129.182 184.581 128.608 184.581 128.608C185.648 123.847 184.679 115.126 184.055 114.501L197.801 109.502C198.131 110.574 197.907 122.054 197.789 128.839Z"
          fill="#2075AD"
        />
        <path
          d="M221.352 105.494C222.152 107.11 222.346 123.538 221.546 124.469L218.421 125.123L203.425 127.622C201.257 128.471 199.242 128.678 198.295 128.776L198.295 128.776C198.049 128.801 197.875 128.819 197.789 128.839C197.907 122.054 198.131 110.573 197.801 109.501L221.352 105.494Z"
          fill="#195C88"
        />
      </mask>
      <g
        id="LAN_4_highlight"
        onMouseLeave={() => popoverLeave("LAN_4")}
        style={{ display: "none" }}
        mask="url(#mask3_3877_17956)"
      >
        <g style={{ mixBlendMode: "hue" }} opacity="0.65">
          <rect
            x="122.996"
            y="101.002"
            width="99.8533"
            height="40.9325"
            fill="#41CE3E"
          />
        </g>
      </g>
      <path
        d="M331.391 89.7715L331.39 193.017"
        stroke="#3C3636"
        strokeWidth="1.68702"
      />
      <g
        style={{ display: "none" }}
        id="LAN_1_popup"
        filter="url(#filter1_d_3877_17956)"
      >
        <path
          d="M145.319 83.4248L161.729 63.8843H128.91L145.319 83.4248Z"
          fill="white"
        />
        <rect
          x="79"
          y="8.58008"
          width="135.007"
          height="57.3169"
          rx="18.9484"
          fill="white"
        />
        <path
          d="M100.878 45.2383V28.5609H103.26V43.1655H112.29V45.2383H100.878ZM112.498 45.2383L120.051 28.5609H122.409L129.986 45.2383H127.484L120.742 29.8951H121.695L114.952 45.2383H112.498ZM115.715 41.0689L116.358 39.163H125.745L126.436 41.0689H115.715ZM132.473 45.2383V28.5609H134.427L145.458 42.2602H144.433V28.5609H146.816V45.2383H144.862L133.831 31.539H134.856V45.2383H132.473ZM159.659 45.2383V29.5139L160.684 30.6337H155.895V28.5609H162.018V45.2383H159.659Z"
          fill="#253331"
        />
        <ellipse
          cx="186.768"
          cy="37.0028"
          rx="8.28992"
          ry="8.28992"
          fill="#EC0000"
        />
        <ellipse
          cx="186.768"
          cy="37.0028"
          rx="8.28992"
          ry="8.28992"
          fill="#84E88E"
        />
      </g>
      <g
        style={{ display: "none" }}
        id="LAN_2_popup"
        filter="url(#filter2_d_3877_17956)"
      >
        <path
          d="M166.319 87.4248L182.729 67.8843H149.91L166.319 87.4248Z"
          fill="white"
        />
        <rect
          x="98.8164"
          y="12.5801"
          width="137.376"
          height="57.3169"
          rx="18.9484"
          fill="white"
        />
        <path
          d="M120.763 49.2383V32.5609H123.145V47.1655H132.175V49.2383H120.763ZM132.383 49.2383L139.936 32.5609H142.294L149.871 49.2383H147.369L140.627 33.8951H141.58L134.837 49.2383H132.383ZM135.6 45.0689L136.243 43.163H145.63L146.321 45.0689H135.6ZM152.358 49.2383V32.5609H154.312L165.343 46.2602H164.319V32.5609H166.701V49.2383H164.747L153.716 35.539H154.741V49.2383H152.358ZM176.471 49.2383V47.6182L183.261 41.0664C183.865 40.4946 184.309 39.9943 184.595 39.5654C184.897 39.1207 185.096 38.7157 185.191 38.3503C185.302 37.9691 185.358 37.6038 185.358 37.2544C185.358 36.3967 185.056 35.7217 184.452 35.2293C183.849 34.7369 182.967 34.4907 181.808 34.4907C180.918 34.4907 180.116 34.6416 179.402 34.9434C178.687 35.2293 178.067 35.682 177.543 36.3014L175.923 34.8957C176.558 34.0857 177.408 33.4662 178.472 33.0374C179.552 32.5927 180.736 32.3703 182.022 32.3703C183.182 32.3703 184.19 32.5609 185.048 32.9421C185.906 33.3074 186.565 33.8395 187.025 34.5384C187.502 35.2372 187.74 36.0632 187.74 37.0161C187.74 37.5562 187.669 38.0883 187.526 38.6124C187.383 39.1366 187.113 39.6925 186.716 40.2801C186.319 40.8678 185.747 41.527 185 42.2576L178.949 48.0947L178.377 47.1655H188.455V49.2383H176.471Z"
          fill="#253331"
        />
        <ellipse
          cx="208.954"
          cy="41.0028"
          rx="8.28992"
          ry="8.28992"
          fill="#EC0000"
        />
        <ellipse
          cx="208.954"
          cy="41.0028"
          rx="8.28992"
          ry="8.28992"
          fill="#84E88E"
        />
      </g>
      <g
        style={{ display: "none" }}
        id="LAN_3_popup"
        filter="url(#filter3_d_3877_17956)"
      >
        <path
          d="M188.319 91.4248L204.729 71.8843H171.91L188.319 91.4248Z"
          fill="white"
        />
        <rect
          x="120.816"
          y="16.5801"
          width="137.376"
          height="57.3169"
          rx="18.9484"
          fill="white"
        />
        <path
          d="M142.479 53.2383V36.6584H144.847V51.1776H153.824V53.2383H142.479ZM154.031 53.2383L161.54 36.6584H163.885L171.417 53.2383H168.93L162.227 37.9848H163.174L156.471 53.2383H154.031ZM157.229 49.0933L157.868 47.1985H167.201L167.887 49.0933H157.229ZM173.89 53.2383V36.6584H175.832L186.798 50.2776H185.78V36.6584H188.149V53.2383H186.206L175.24 39.6191H176.258V53.2383H173.89ZM203.191 53.4278C202.022 53.4278 200.885 53.2462 199.78 52.883C198.69 52.5198 197.798 52.0303 197.103 51.4145L198.193 49.5433C198.746 50.0644 199.464 50.4908 200.348 50.8224C201.233 51.154 202.18 51.3198 203.191 51.3198C204.422 51.3198 205.378 51.0592 206.057 50.5381C206.751 50.0013 207.099 49.2828 207.099 48.3828C207.099 47.5143 206.767 46.8195 206.104 46.2984C205.457 45.7616 204.422 45.4931 203.001 45.4931H201.675V43.8351L206.601 37.7717L206.933 38.7191H197.861V36.6584H208.804V38.269L203.901 44.3088L202.67 43.5746H203.451C205.457 43.5746 206.957 44.0246 207.951 44.9247C208.962 45.8247 209.467 46.9695 209.467 48.3591C209.467 49.2907 209.238 50.1434 208.78 50.9171C208.323 51.6908 207.628 52.3067 206.696 52.7646C205.78 53.2067 204.612 53.4278 203.191 53.4278Z"
          fill="#253331"
        />
        <ellipse
          cx="230.954"
          cy="45.0028"
          rx="8.28992"
          ry="8.28992"
          fill="#EC0000"
        />
        <ellipse
          cx="230.954"
          cy="45.0028"
          rx="8.28992"
          ry="8.28992"
          fill="#84E88E"
        />
      </g>
      <g
        style={{ display: "none" }}
        id="LAN_4_popup"
        filter="url(#filter4_d_3877_17956)"
      >
        <path
          d="M212.319 96.4248L228.729 76.8843H195.91L212.319 96.4248Z"
          fill="white"
        />
        <rect
          x="144.816"
          y="21.5801"
          width="137.376"
          height="57.3169"
          rx="18.9484"
          fill="white"
        />
        <path
          d="M166.334 58.2383V41.6584H168.702V56.1776H177.679V58.2383H166.334ZM177.886 58.2383L185.395 41.6584H187.74L195.272 58.2383H192.785L186.082 42.9848H187.029L180.326 58.2383H177.886ZM181.084 54.0933L181.723 52.1985H191.056L191.742 54.0933H181.084ZM197.745 58.2383V41.6584H199.687L210.653 55.2776H209.635V41.6584H212.004V58.2383H210.061L199.095 44.6191H200.113V58.2383H197.745ZM221.74 54.1644V52.5064L230.22 41.6584H232.778L224.369 52.5064L223.161 52.1274H236.401V54.1644H221.74ZM230.954 58.2383V54.1644L231.025 52.1274V48.5272H233.251V58.2383H230.954Z"
          fill="#253331"
        />
        <ellipse
          cx="254.954"
          cy="50.0028"
          rx="8.28992"
          ry="8.28992"
          fill="#EC0000"
        />
        <ellipse
          cx="254.954"
          cy="50.0028"
          rx="8.28992"
          ry="8.28992"
          fill="#84E88E"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3877_17956"
          x="0.564885"
          y="33"
          width="549.24"
          height="196.74"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="8.43511" dy="16.8702" />
          <feGaussianBlur stdDeviation="8.43511" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.493507 0 0 0 0 0.508333 0 0 0 0 0.50648 0 0 0 0.39 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_17956"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_17956"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3877_17956"
          x="69"
          y="0.580078"
          width="155.008"
          height="94.8447"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_17956"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_17956"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3877_17956"
          x="88.8164"
          y="4.58008"
          width="157.375"
          height="94.8447"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_17956"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_17956"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_3877_17956"
          x="110.816"
          y="8.58008"
          width="157.375"
          height="94.8447"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_17956"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_17956"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_3877_17956"
          x="134.816"
          y="13.5801"
          width="157.375"
          height="94.8447"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_17956"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_17956"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3877_17956"
          x1="95.5414"
          y1="141.912"
          x2="551.851"
          y2="164.53"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#525151" />
          <stop offset="0.44313" stopColor="#7E7E7E" />
          <stop offset="1" stopColor="#ABABAB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AaeonDeviceBack;
