import { useContext, useMemo } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import CardWrapper from "../../../../components/CardWrapper";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NavContainer";
import { BackStyle } from "../../Styles";
import CardWrapperFilled from "../../../../components/CardWrapper/CardWrapperFilled";
import { EmailIcon } from "../../../../assets/icons";
import { EmailStyle, Message } from "./Styles";
import {
  BillingContext,
  BillingType,
} from "../../../../components/BillingProvider";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";

const billingAccordianItems = [
  {
    question: "How do I pay for my internet?",
    answer:
      "Your internet bill is separate from our operations and maintenance services. For details or queries about your bill and payment options, please contact your Internet Service Provider (ISP)",
  },
  {
    question:
      "Are there any penalties or late fees for overdue payments of the operations and maintenance service fees?",
    answer:
      "Please keep your payment method current to avoid a $5.00 late fee.",
  },
  {
    question: "Can I view and download my invoice?",
    answer:
      "Yes, view and download invoices from the Payment History in your Billing dashboard by clicking 'View Invoice' on your chosen statement.",
  },
  {
    question: "Can I receive physical bills?",
    answer:
      "We provide e-bills and e-statements for convenience and eco-friendliness. Visit your billing dashboard to view and download invoices anytime. This method ensures quick access and reduces paper waste.",
  },
  {
    question: "__placeholder__",
    answer: "__placeholder__",
  },
  {
    question: "Where can I pay my bill or setup AutoPay?",
    answer:
      "Our automated payment system charges your provided method on the 1st of each month, eliminating the need for manual payments.",
  },
];

const BillingIssues = () => {
  const { prices } = useContext(BillingContext) as BillingType;
  const { provider } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const operatorEmail = useMemo(() => {
    const billingEmail = provider?.data?.provider?.Contacts?.find(
      (e) => e.type === "BILLING"
    );
    return (
      billingEmail?.email ??
      provider?.data?.provider?.Contacts?.[0]?.email ??
      ""
    );
  }, [provider]);

  const placeholderQuestion = `What is the extra $${
    prices?.cc_initial_processing_fee?.amount?.toFixed(2) ?? 1.5
  } fee on my bill?`;

  const placeholderAnswer = `The extra $${
    prices?.cc_initial_processing_fee?.amount?.toFixed(2) ?? 1.5
  } fee on your bill covers credit card processing. To avoid this, switch to ACH payment via your billing dashboard—it's quick and easy!`;

  const backAction = (
    <BackStyle aria-label="Back to Help" to={routes.help.path}>
      Back to Help
    </BackStyle>
  );

  return (
    <NavContainer title="Help">
      <CardWrapper
        cardTitleSxProps={{
          gap: 2,
          flexDirection: {
            sm: "row",
            xs: "column-reverse",
          },
          alignItems: {
            sm: "start",
          },
        }}
        title="Billing Issues"
        actions={backAction}
      >
        <Box sx={{ marginTop: "64px" }}>
          {billingAccordianItems.map((item, index) => {
            const question =
              item.question === "__placeholder__"
                ? placeholderQuestion
                : item.question;
            const answer =
              item.answer === "__placeholder__"
                ? placeholderAnswer
                : item.answer;
            return (
              <Accordion key={index} defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon fill={colors.icons.default.fillColor} />
                  }
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  {question}
                </AccordionSummary>
                <AccordionDetails>{answer}</AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
        <CardWrapperFilled
          title="Need further assistance?"
          sx={{
            marginTop: "3.9rem",
            zIndex: 2,
            borderRadius: "8px",
          }}
        >
          <Message
            sx={{
              marginTop: "1rem",
            }}
          >
            Our customer service is available to assist you with any further
            needs or inquiries you may have.
          </Message>
          <Message>
            Having trouble with your service? Please contact your service
            provider.
          </Message>
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "18px",
            }}
            to={`mailto:${operatorEmail}`}
            referrerPolicy="no-referrer"
            target="_blank"
          >
            <EmailIcon fill={colors.icons.default.fillColor} />
            <EmailStyle>{operatorEmail}</EmailStyle>
          </Link>
        </CardWrapperFilled>
      </CardWrapper>
    </NavContainer>
  );
};
export default BillingIssues;
