import { useState, useCallback, useContext, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Stack, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileSettingsModal } from "../../../components/CustomModal";
import useGetUserId from "../../../hooks/useGetUserId";
import Button from "../../../components/Button";
import Label from "../../../components/Label";
import Input from "../../../components/Input/Input";
import ErrorMessage from "../../../components/ErrorMessage";
import getREMFromPX from "../../../utils/getREMFromPX";
import sendErrorToast from "../../../utils/sendErrorToast";
import { theme } from "../../../theme";
import { OnboardingBubble } from "../../../assets/icons";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../components/AddressAndAccountProvider";
import { useUpdateUsername } from "../../../hooks/useUpdateUsername";
import { useValidateUser } from "../../../hooks/useValidateUser";
import { SingleUser } from "../../../api/interfaces/User";
import isValidEmail from "../../../utils/isValidEmail";
import useGetThemePath from "../../../hooks/useGetThemePath";

export type UsernameType = {
  email: string;
};

const EditEmail = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const userId = useGetUserId();
  const [content, setContent] = useState("edit");
  const [isEditing, setIsEditing] = useState(false);

  const { userInfo, setUserInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const contactId = useMemo(
    () => userInfo?.data?.user?.Contact?.id ?? "",
    [userInfo]
  );

  const username = useMemo(() => userInfo?.data?.user?.username, [userInfo]);

  const {
    watch,
    register,
    handleSubmit,
    setError,
    formState: { isValid, errors },
  } = useForm<UsernameType>({
    mode: "onChange",
    defaultValues: useMemo(() => ({ email: username ?? "" }), [username]),
  });

  const email = watch("email").trim();

  const {
    mutateAsync: updateUsername,
    data: usernameData,
    isLoading: usernameLoading,
  } = useUpdateUsername(userId, contactId, {
    onSuccess: () => {
      setContent("success");
    },
    onError: () =>
      sendErrorToast(
        "There was a problem validating the Username, please try again"
      ),
  });

  const {
    refetch: validateUserName,
    data: userIsValid,
    isLoading: validateLoading,
  } = useValidateUser(email, {
    cacheTime: 0,
    onError: () =>
      sendErrorToast(
        "There was a problem updating the Username, please try again"
      ),
    enabled: false,
  });

  useEffect(() => {
    if (usernameData) {
      setUserInfo((info: SingleUser) => {
        return {
          ...info,
          ...usernameData,
          data: {
            ...info?.data,
            user: {
              ...info?.data?.user,
              ...usernameData?.data?.user,
              Contact: {
                ...info?.data?.user?.Contact,
                email: usernameData?.data?.user?.username,
              },
            },
          },
        };
      });
      setContent("success");
    }
  }, [usernameData, setUserInfo]);

  useEffect(() => {
    if (userIsValid?.data.user.available === false) {
      setError("email", {
        message: "That username is already taken. Please choose a new one",
      });
    } else if (userIsValid?.data.user.available === true) {
      updateUsername({ email: email });
    }
  }, [userIsValid, setError, email, updateUsername]);

  const onSubmitHandler = useCallback(
    async (e: UsernameType) => {
      if (email === username) {
        setIsEditing(false);
      } else {
        await validateUserName();
      }
    },
    [email, username, validateUserName]
  );

  const isLoading = usernameLoading || validateLoading;

  const disableButton =
    !isValid || !!Object.values(errors).length || validateLoading;

  const disableFields = validateLoading;

  const closeModal = () => {
    setIsEditing(false);
    setContent("edit");
  };

  const getContent = () => {
    switch (content) {
      case "success":
        return (
          <Stack sx={{ alignItems: "center" }}>
            <OnboardingBubble />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: getREMFromPX(16),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "center",
                color: "#000000",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              Your email has been updated
            </Typography>
            <Button
              text="OK"
              mode="default"
              disabled={!isValid}
              sx={{ width: "218px" }}
              onClick={() => {
                closeModal();
              }}
            />
          </Stack>
        );
      default:
        return (
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            data-testid="edit-username"
          >
            <Stack
              spacing={getREMFromPX(theme.spacing * 2)}
              mt={{
                sm: 0,
                xs: 5,
              }}
            >
              <Label hidden htmlFor="userName">
                Email
              </Label>
              <Input
                placeholder="Eg: example@example.com"
                autoComplete="username"
                disabled={disableFields}
                error={!!errors.email && !!email.length}
                id="userName"
                {...register("email", {
                  required: true,
                  validate: (value) => isValidEmail(value),
                })}
              />
            </Stack>
            {errors.email && email && (
              <ErrorMessage fontSize={getREMFromPX(theme.spacing * 2.5)}>
                {errors.email?.message ||
                  "Invalid Username. Please enter a new one."}
              </ErrorMessage>
            )}
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              paddingTop={getREMFromPX(theme.spacing * 8)}
            >
              <Button
                data-testid="saveButton"
                mode="default"
                text="Save"
                type="submit"
                size="large"
                disabled={disableButton}
                isLoading={isLoading}
                sx={{ width: getREMFromPX(220) }}
              />
            </Stack>
          </form>
        );
    }
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
      <ProfileSettingsModal onClose={closeModal} open={isEditing}>
        {content !== "success" && (
          <>
            <Typography
              sx={{
                position: "absolute",
                top: {
                  md: 30,
                  xs: 14,
                },
                fontFamily: "Inter",
                fontSize: getREMFromPX(20),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: colors.textPrimary.heading,
                height: 40,
                width: "100%",
                backgroundColor: colors.white || "#ffffff",
              }}
            >
              Update Email
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                top: {
                  md: 15,
                  xs: 6,
                },
                right: 15,
                color: colors.links.teal,
              }}
              onClick={() => closeModal()}
              data-cy="editmail-close"
            >
              <CloseIcon
                sx={{ fontWeight: "bolder" }}
                fill={colors.icons.default.fillColor}
                fontSize="large"
              />
            </IconButton>
          </>
        )}
        {getContent()}
      </ProfileSettingsModal>
      <Typography
        sx={{
          flex: 1,
          fontFamily: "Inter",
          fontSize: {
            md: getREMFromPX(16),
            xs: getREMFromPX(14),
          },
          fontWeight: "600",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.textPrimary.heading,
        }}
      >
        Email
      </Typography>
      <Typography
        sx={{
          flex: 1.5,
          fontFamily: "Inter",
          fontSize: getREMFromPX(16),
          fontWeight: "500",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.textPrimary.heading,
        }}
      >
        {userInfo?.data?.user?.username}
      </Typography>
      <Typography
        onClick={() => setIsEditing(true)}
        sx={{
          cursor: "pointer",
          fontFamily: "Inter",
          fontSize: getREMFromPX(16),
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          color: colors.links.teal,
        }}
        data-cy="editmail-edit"
      >
        Edit
      </Typography>
    </Stack>
  );
};

export default EditEmail;
