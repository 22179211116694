import { ThemePaths } from "../theme";

export function getThemePath() {
  const theme = process.env.REACT_APP_THEME || "default";
  return theme as ThemePaths;
}

const useGetThemePath = () => {
  return getThemePath();
};
export default useGetThemePath;
