import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

const EmptyMyServicesMessage = () => {
  const navigate = useNavigate();
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  return (
    <Box display="flex" flexDirection="column" gap="0.5rem">
      <Typography
        sx={{
          fontWeight: "bold",
        }}
        component="p"
      >
        Select the provider that suits you best.
      </Typography>
      <Typography
        sx={{
          color: colors.form.primary,
          fontWeight: 500,
        }}
        component="p"
      >
        Search plans between the different available providers.
      </Typography>
      <Button text="Find a Plan" onClick={() => navigate(`/services/`)} />
    </Box>
  );
};

export default EmptyMyServicesMessage;
