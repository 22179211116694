import React from "react";
import { theme, ThemePaths } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const headerStyle = {
  fontSize: getREMFromPX(theme.spacing * 5),
  fontWeight: theme.fonts.weights.bolder,
};

export const dividerStyles = {
  margin: `0 ${getREMFromPX(theme.spacing * 4)}`,
};

export const normalisedButtonStyles = {
  border: 0,
  appearance: "none",
  background: "none",
  fontFamily: "inherit",
  padding: 0,
  cursor: "pointer",
} as React.CSSProperties;

export const getBorderRadiusStyles = (isLeft?: boolean, isRight?: boolean) => {
  if (isLeft) {
    return {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    };
  }
  if (isRight) {
    return {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    };
  }
  return {};
};

export const valueIconStyle = {
  margin: `${getREMFromPX(theme.spacing * 2)} 0`,
};

export const buttonStyle = {
  marginLeft: "auto",
};

export const iconStyle = {
  marginRight: getREMFromPX(theme.spacing * 2),
};

export const getSelectedItemStyles = (themePath: ThemePaths = "default") => ({
  backgroundColor: theme[themePath].colors.secondary[500],
});

export const wifiIconStyle = {
  marginRight: getREMFromPX(theme.spacing * 6),
};

export const phantomFormStyles = {
  display: "none",
};
