import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import getREMFromPX from "../../../../utils/getREMFromPX";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";

interface StepProps {
  label: string;
  icon: any;
  isActive: boolean;
  isCompleted: boolean;
  customAlignment?: boolean;
}

const Step = ({ icon, isActive, isCompleted }: StepProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  console.log(colors.stepper.activeBg);
  return (
    <>
      <Box
        sx={{
          backgroundColor: `${
            !!isCompleted
              ? colors.stepper.completeBg
              : isActive
              ? colors.stepper.activeBg
              : colors.stepper.defaultBg
          }`,
          color: `${
            isActive ? colors.stepper.activeColor : colors.stepper.defaultColor
          }`,
          fill: "#fff",
          width: "40px",
          height: "40px",
          fontSize: getREMFromPX(14), // Adjust the font size here to resize the icon
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4rem",
        }}
      >
        {!isCompleted ? (
          icon
        ) : (
          <CheckIcon fill={colors.icons.default.fillColor} />
        )}
      </Box>
    </>
  );
};

export default Step;
