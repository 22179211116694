import { theme, ThemePaths } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const portSectionStyles = {
  background: theme.backgrounds.primarySelection,
};

export const headerStyle = {
  fontSize: getREMFromPX(theme.spacing * 5),
  fontWeight: theme.fonts.weights.bolder,
};

export const getNormalisedButtonStyles = (themePath: ThemePaths = "default") =>
  ({
    border: 0,
    appearance: "none",
    background: "none",
    fontFamily: "inherit",
    padding: 0,
    cursor: "pointer",
    color: theme[themePath].colors.primary[500],
    fontSize: getREMFromPX(theme.spacing * 3),
    fontWeight: theme.fonts.weights.mediumBold,
  } as React.CSSProperties);

export const iconStyle = {
  marginRight: getREMFromPX(theme.spacing * 2),
};
