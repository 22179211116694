import { Box, Fade, IconButton, Modal } from "@mui/material";
import React, { FC } from "react";
import { theme } from "../../theme";
import CloseIcon from "@mui/icons-material/Close";
import useViewport from "../../hooks/useViewport";
import { getThemePath } from "../../hooks/useGetThemePath";

const themePath = getThemePath();
const colors = theme[themePath].colors;

export const modalStyles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 15.5px",
  },
  paper: {
    backgroundColor: colors.white,
    width: "750px",
    flexGrow: 0,
    objectFit: "contain",
    borderRadius: "20px",
    outline: "none",
    boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.25)",
    position: "relative",
    overflow: "auto",
    maxHeight: "90%",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: colors.links.teal,
  },
};

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const CustomModal: FC<CustomModalProps> = ({ open, onClose, children }) => {
  const { isMobile } = useViewport();
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={modalStyles.modal}
      data-cy="custom-modal"
    >
      <Fade in={open}>
        <Box
          data-cy="custom-modal-content"
          sx={{
            ...modalStyles.paper,
            padding: !isMobile ? "50px 75px 50px" : "16px 16px",
          }}
        >
          <IconButton sx={modalStyles.closeButton} onClick={onClose}>
            <CloseIcon
              sx={{ fontWeight: "bolder" }}
              fontSize="large"
              fill={colors.icons.default.fillColor}
            />
          </IconButton>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
